import {useTranslation} from 'react-i18next'
import FormSelectAsyncPagination, {FormSelectAsyncConfig} from "../../inputs/form-select-async.pagination"
import inventoriesService from "src/services/inventory/inventory.service"
import {InventoryModel} from "src/models/manager/inventory/inventory.model"
import {components, GroupBase, OptionProps} from "react-select"
import Icon from "../material-icon"
import {ImageField} from "../../image-loader/image"


type RemovalKeys = 'getValue' | 'getOptionLabel' | 'getOptionValue' | 'listOptions'

interface SelectConfig extends Omit<FormSelectAsyncConfig<InventoryModel>, RemovalKeys> {
    query_params?: any
}

function SelectOption(props: OptionProps<InventoryModel, boolean, GroupBase<InventoryModel>>) {
    const inventory: InventoryModel = props.data
    return (
        <components.Option {...props}>
            <span className="flex items-center justify-between gap-2" style={{minHeight: 20}}>
                <div className="flex gap-3 items-center cursor-pointer">
                    <ImageField src={inventory.image} className="w-12 h-12 rounded-md" />
                    <div className="flex flex-col gap-1">
                        <div className="font-medium text-[15px]">{inventory.name}</div>
                        <div className="text-[13px]">{inventory.unique_id}</div>
                    </div>
                </div>
                {props.isSelected && <Icon icon="radio_button_checked" className="color-white" />}
            </span>
        </components.Option>
    )
}

export function SharedInventorySelect(config: SelectConfig) {
    const {t} = useTranslation()
    return <FormSelectAsyncPagination<InventoryModel>
        placeholder={t('common.select.placeholder.inventory')}
        {...config}
        listOptions={params => inventoriesService.list({...params, ...config.query_params})}
        components={{Option: (params: OptionProps<InventoryModel, boolean, GroupBase<InventoryModel>>) => <SelectOption {...params} />}}
        getValue={id => inventoriesService.get(+id)}
        getOptionLabel={(val: InventoryModel) => val ? val.name : undefined}
        getOptionValue={(val: InventoryModel) => val ? String(val.id) : undefined}
    />
}