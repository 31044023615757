import {InventoryGroupModel} from 'src/models/manager/inventory/inventory-group.model'
import inventoryGroupService from 'src/services/inventory/inventory-group.service'
import toDateTime from 'src/utils/date-time'
import {ColumnsType} from "antd/es/table/interface"
import {useTranslation} from 'react-i18next'
import {FormProvider, useForm} from "react-hook-form"
import {useContext, useEffect, useState} from "react"
import {EMPTY_LIST, ListModel} from "src/models/common"
import rentalPointService from "src/components/modal/global/rental-point.service"
import {ImageField} from "src/components/shared/image-loader/image"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import Table from "src/components/shared/antd-custom/table"
import InventoryGroupListFilterComponent from "src/components/shared/components/group/filter"
import {ConfigContext} from "src"


export default function SaleInventoryGroupList({onSelect}: {onSelect: (group: InventoryGroupModel) => void}) {
    const [list, setList] = useState<ListModel<InventoryGroupModel>>(EMPTY_LIST)
    const form = useForm<any>({
        defaultValues: {
            page: 1,
            pageSize: 10,
            rental_point: rentalPointService.filterPoint,
            search: '',
            type: 1
        },
    })
    const {setValue, watch} = form
    const {settings, constantsMap} = useContext(ConfigContext)
    const values = watch()
    const {t} = useTranslation()

    const columns: ColumnsType<InventoryGroupModel> = settings ? [
        {
            width: 240,
            title: t('groups.table.name'),
            dataIndex: 'name',
            key: 'name',
            render: (name, group) => (
                <div className="flex gap-2 items-center">
                    <ImageField
                        src={group.image}
                        style={{width: 48, height: 48, borderRadius: 8}}
                    />
                    <span className="font-medium">{name}</span>
                </div>
            ),
            sorter: true,
        },
        {
            width: 96,
            title: t('groups.table.unique_id'),
            dataIndex: 'unique_id',
            key: 'unique_id',
            render: unique_id => unique_id,
            sorter: true,
        },
        {
            width: 144,
            title: t('groups.table.category'),
            dataIndex: 'category',
            key: 'category',
            render: category => constantsMap.INVENTORY_CATEGORIES[category]?.name,
            sorter: true,
        },
        {
            width: 160,
            title: t('groups.table.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: created_at => toDateTime(created_at),
            sorter: true,
        },
        {
            width: 160,
            title: t('groups.table.availability.column'),
            dataIndex: 'inventories_count',
            key: 'inventories_count',
            render: (_, group) => (
                <div className="btn p-1 btn-icon text-start text-nowrap">
                    {t('groups.table.availability.available', {count: group.inventories_count - group.inventories_occupied - group.inventories_disabled - group.inventories_overdued})}
                </div>
            ),
            sorter: true,
        },
        ...settings.custom_fields.inventory_group.filter(field => field.table).map(field => (
            {
                width: 96,
                title: field.label,
                key: field.name,
                render: (_, group) => group && group.extra && field.type !== 'boolean' ? group.extra[field.name] : t((Boolean(group.extra[field.name]) ? "common.boolean.true" : "common.boolean.false")),
            }
        ))
    ] : []

    const listGroups = async (params: any) => inventoryGroupService.list(params).then(setList).catch(e => e.response.status === 404 && setValue('page', 1))

    useEffect(() => {
        listGroups(values)
        const sub = watch((params, {name}) => {
            if (name !== 'search') listGroups(params)
        })

        return () => {
            sub.unsubscribe()
            setList(EMPTY_LIST)
        }
    }, [])

    return (
        <FormProvider {...form}>
            <div className="text-2xl font-semibold mb-4">{t('sale.edit.inventories.choose_product')}</div>

            <InventoryGroupListFilterComponent />

            <Table
                rowKey={obj => obj.id}
                columns={columns}
                onRow={group => ({onClick: () => onSelect(group)})}
                dataSource={list.results}
            />

            <PaginationFormComponent count={list.count} />
        </FormProvider>
    )
}
