import axios from 'axios'
import {ApiAbstract} from '../../abstract/api.abstract'
import {InventorySetItemModel} from '../../models/manager/inventory/inventory-set.model'

export class ManageInventorySetItemService extends ApiAbstract {
  constructor() {
    super('v1/crm/inventories/sets')
  }
  public async delete(setId: number, id: number): Promise<InventorySetItemModel> {
    const response = await axios.delete(this.getUrl(`${setId}/items/${id}`))
    return response.data
  }
}

export default new ManageInventorySetItemService()
