import React, {CSSProperties} from 'react'
import {RegisterOptions} from 'react-hook-form'

interface FormOptionConfig<T> {
    value: T,
    onChange: (val: T) => void
    params?: RegisterOptions<any, any>
    className?: string
    style?: CSSProperties
    options: Array<{label: string, value: T, className?: string, style?: CSSProperties,hint?:string}>
    textClassName?:string
    hintClassName?:string
}

const selected = {
    active: {border: "1px solid var(--color-primary)"},
    default: {border: "1px solid var(--color-gray-100)"},
    toggle: {
        active: {width: 20, height: 20, borderRadius: 10, border: "6px solid var(--color-primary)"},
        default: {width: 20, height: 20, borderRadius: 10, border: "1px solid var(--color-gray-100)"}
    }
}

export function OptionChoose<T = number>(config: FormOptionConfig<T>) {
    const {onChange,options,value,textClassName,hintClassName}=config
    return (
        <div className={'flex flex-col gap-4'}>
            {options.map((option, index) => (
                <div
                    key={index}
                    className="flex gap-2 border-none cursor-pointer"
                    onClick={() => onChange(option.value)}
                    style={option.value === value ? selected.active : selected.default}
                >
                    {option.value === value ?
                        <span style={selected.toggle.active}/>
                        :
                        <span style={selected.toggle.default}/>
                    }
                    <div className={'flex gap-2'}>
                        <span className={`text-nowrap ${textClassName}`}>{option.label}</span>
                        <span className={`text-nowrap ${hintClassName}`}>{option.hint}</span>
                    </div>
                </div>
            ))}
        </div>
    )
}
