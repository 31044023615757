import axios from 'axios'
import {CrudInterface} from '../../interfaces/crud.interface'
import {ListModel} from '../../models/common'
import {ApiAbstract} from '../../abstract/api.abstract'
import {InventorySetItemTarifModel, InventorySetModel} from '../../models/manager/inventory/inventory-set.model'

export class ManageInventorySetService extends ApiAbstract implements CrudInterface<InventorySetModel> {
  constructor() {
    super('v1/crm/inventories/sets')
  }

  public async list(params?: any): Promise<ListModel<InventorySetModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async get(id: number): Promise<InventorySetModel> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
  public async post(payload: any): Promise<InventorySetModel> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async patch(id: number, payload: any): Promise<InventorySetModel> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }
  public async delete(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
  public async archive(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${id}/archive`))
    return response.data
  }
  public async listPrices(id: number): Promise<Array<{id: number, name: string, period: number}>> {
    const response = await axios.get(this.getUrl(`${id}/prices`))
    return response.data
  }
  public async createPrice(id: number, payload: any): Promise<{id: number, name: string, period: number}> {
    const response = await axios.post(this.getUrl(`${id}/prices`), payload)
    return response.data
  }
  public async patchPrice(id: number, priceId: number, payload: any): Promise<{id: number, name: string, period: number}> {
    const response = await axios.patch(this.getUrl(`${id}/prices/${priceId}`), payload)
    return response.data
  }
  public async listItems(id: number): Promise<Array<{id: number, group: number, count: number}>> {
    const response = await axios.get(this.getUrl(`${id}/items`))
    return response.data
  }
  public async createItem(id: number, payload: any): Promise<{id: number, group: number, count: number}> {
    const response = await axios.post(this.getUrl(`${id}/items`), payload)
    return response.data
  }
  public async patchItem(id: number, itemId: number, payload: any): Promise<{id: number, group: number, count: number}> {
    const response = await axios.patch(this.getUrl(`${id}/items/${itemId}`), payload)
    return response.data
  }
  public async listTarifs(id: number): Promise<InventorySetItemTarifModel[]> {
    const response = await axios.get(this.getUrl(`${id}/tarifs`))
    return response.data
  }
  public async bulkCreateTarifs(id: number, payload: any): Promise<InventorySetItemTarifModel[]> {
    const response = await axios.post(this.getUrl(`${id}/tarifs/bulk_create`), payload)
    return response.data
  }
  public async bulkUpdateTarifs(id: number, payload: any): Promise<InventorySetItemTarifModel[]> {
    const response = await axios.post(this.getUrl(`${id}/tarifs/bulk_update`), payload)
    return response.data
  }
}

export default new ManageInventorySetService()
