import {ApiAbstract} from '../../abstract/api.abstract'
import {DiscountModel} from '../../models/manager/discount/discount.model'
import {BehaviorSubject, Observable, map, shareReplay, switchMap} from 'rxjs'
import axios from 'axios'
import {getAPI} from '../../interceptor'

class ManageDiscountService extends ApiAbstract {
  constructor() {
    super('v1/crm/discounts')
  }

  private loadDiscounts$: BehaviorSubject<void> = new BehaviorSubject(null)
  loadDiscounts = () => this.loadDiscounts$.next()

  discounts$: Observable<DiscountModel[]> = this.loadDiscounts$.pipe(
    switchMap(() => getAPI<DiscountModel[]>(axios.get(this.getUrl()))),
    shareReplay(1),
  )

  discounts = {
    discount: this.discounts$.pipe(map(discounts => discounts.filter(obj => obj.type === 0 && obj.deleted === false))),
    promocode: this.discounts$.pipe(map(discounts => discounts.filter(obj => obj.type === 1 && obj.deleted === false))),
  }

  get(id: number): Observable<DiscountModel> {
    return this.discounts$.pipe(map(discounts => discounts.find(discount => discount.id === id)))
  }

  post(payload: any): Observable<DiscountModel> {
    return getAPI(axios.post(this.getUrl(), payload))
  }

  patch(id: number, payload: any): Observable<DiscountModel> {
    return getAPI(axios.patch(this.getUrl(id), payload))
  }

  delete(id: number): Observable<DiscountModel> {
    return getAPI(axios.delete(this.getUrl(id)))
  }
}

const discountService = new ManageDiscountService()
export default discountService
