import {ColumnsType} from 'antd/es/table'
import {useContext, useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import rentalPointService from "src/components/modal/global/rental-point.service"
import {InventoryModel} from 'src/models/manager/inventory/inventory.model'
import {InventoryFilterForm} from "src/pages/inventories/list"
import InventoryListFilter from "src/pages/inventories/list/filter"
import InventoryListComponent from "src/pages/inventories/list/list"
import {useTranslation} from 'react-i18next'
import {ConfigContext} from "src"
import {Segmented} from "src/components/shared/antd-custom"

interface InventoryListParams {
    filter?: any
    onSelect?: (inventory: InventoryModel) => any
}

export default function OrderDetailInventoryListComponent({filter = {}, onSelect}: InventoryListParams) {
    const form = useForm<InventoryFilterForm>({
        defaultValues: {page: 1, pageSize: 10, rental_point: rentalPointService.filterPoint, type: 0, search: '', ...filter}
    })
    const {watch, setValue, reset} = form
    const {settings} = useContext(ConfigContext)
    const {id} = useParams()
    const {t} = useTranslation()
    const values = watch()

    const columns: ColumnsType<InventoryModel> = settings ? settings.custom_fields.inventory.filter(field => field.table).map(field => (
        {
            width: 48,
            title: field.label,
            key: field.name,
            render: (_, inventory) => inventory.extra[field.name],
        }
    )) : []

    useEffect(() => reset({...values, ...filter}), [filter, reset])

    return (
        <FormProvider {...form}>
            <InventoryListFilter customFieldFilter={!onSelect && {group_id: id}} />

            <InventoryListComponent
                additionalColumns={columns}
                onRow={inventory => ({onClick: () => onSelect(inventory)})}
                title={() => (
                    <Segmented
                        value={String(watch('type'))}
                        onChange={option => setValue('type', +option)}
                        className="bg-gray-50 self-center rounded-md"
                        style={{background: 'var(--color-bg-light)', padding: 4, borderRadius: 6}}
                        options={[
                            {label: t('orders.type.rent'), value: '0', className: "p-1 font-medium text-black"},
                            {label: t('orders.type.sale'), value: '1', className: "p-1 font-medium text-black"},
                        ]}
                    />
                )}
            />
        </FormProvider>
    )
}
