import {useContext, useEffect, useRef} from 'react'
import {useFormContext} from 'react-hook-form'
import rentalPointService from "src/components/modal/global/rental-point.service"
import useOnScreen from 'src/hooks/useOnScreen'
import {EMPTY_LIST, ListModel} from 'src/models/common'
import {OrderRequestListModel} from 'src/models/manager/order/order-request-list.model'
import {OrderCard} from './order-card'
import {ConfigContext} from "../../../index";
import './orders.scss'

export default function OrderListTabGridComponent({list = EMPTY_LIST}: {list: ListModel<OrderRequestListModel>}) {
  const form = useFormContext()
  const {watch, reset} = form
  const values = watch()
  const {isMobile}=useContext(ConfigContext)
  const elementRef = useRef<HTMLDivElement>()
  const isOnScreen = useOnScreen(elementRef)

  useEffect(() => {
    if (isOnScreen && list.next !== false && !!list.next) {
      reset({
        ...values,
        rental_point: rentalPointService.filterPoint,
        page: values.page + 1
      })
    }
  }, [isOnScreen])

  return (
    <>
      <div className={`orders-grid-holder ${isMobile && 'px-4 pt-4'}`}>
        {list.results.map(order => <OrderCard key={order.id} order={order} />)}
      </div>

      <div ref={elementRef}></div>
    </>
  )
}
