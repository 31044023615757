import {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useForm} from "react-hook-form"
import {useNavigate} from "react-router"
import Icon from "src/components/shared/components/material-icon"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import useOnScreen from "src/hooks/useOnScreen"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {DeliveryStatus, OrderRequestDeliveryModel} from "src/models/manager/order/order-request-delivery.model"
import deliveryService from "src/services/delivery.service"
import toDateTime from "src/utils/date-time"

const statusMap = {
    'pending': DeliveryStatus.PENDING,
    'inprocess': DeliveryStatus.IN_PROGRESS
}

export default function DeliveryListComponent() {
    const activeRoute = useCurrentRoute()
    const status: number = useMemo(() => statusMap[activeRoute], [activeRoute])

    const [list, setList] = useState<ListModel<OrderRequestDeliveryModel>>(EMPTY_LIST)
    const form = useForm({defaultValues: {page: 1, pageSize: 10, status: status}})
    const {watch, setValue, reset} = form
    const values = useMemo(() => watch(), [watch])
    const elementRef = useRef<HTMLDivElement>()
    const isOnScreen = useOnScreen(elementRef)
    const navigate = useNavigate()

    const onNavigate = useCallback((delivery: OrderRequestDeliveryModel) => {
        navigate(`/orders/${delivery.request}/deliveries/${delivery.id}`)
    }, [navigate])

    const onLoad = useCallback(async (params: any): Promise<void> => (
        deliveryService.list(params)
            .then(res => setList(prevList => (params.page === 1 ? res : {...res, results: [...prevList.results, ...res.results]})))
            .catch(e => e.response.status === 404 && setValue('page', 1))
    ), [setValue])

    useEffect(() => {
        if (isOnScreen && list.next !== false && !!list.next) {
            reset({...values, page: values.page + 1})
        }
    }, [isOnScreen])

    useEffect(() => {
        reset({...values, page: 1, status})
    }, [status])

    useEffect(() => {
        onLoad(values)
        const sub = watch(onLoad)
        return () => sub.unsubscribe()
    }, [onLoad, watch, values])

    return <>
        <div className="flex flex-col gap-3">
            {list.results.map(delivery => (
                <div key={delivery.id} className="flex flex-col card shadow p-4 rounded-xl gap-2 cursor-pointer" onClick={() => onNavigate(delivery)}>
                    <h3 className="flex gap-2 items-center text-lg md:text-2xl">
                        <span className="font-semibold">Заказ №{delivery.id}</span>
                        <Icon icon="chevron_right" />
                    </h3>
                    <div className="flex gap-2 -mx-4 w-[calc(100%_+_32px)] pr-4 overflow-x-scroll scrollbar-hide">
                        <div className="flex gap-2 font-medium items-center bg-gray-test-70 p-2 rounded-lg ml-4 text-nowrap">
                            <Icon icon="calendar_clock" />
                            <span className="text-nowrap">{toDateTime(delivery.pickup_date, 'D MMM, dd, HH:mm')}</span>
                        </div>
                        {delivery.delivery_place && (
                            <div className="flex gap-2 font-medium items-center bg-gray-test-70 p-2 rounded-lg text-nowrap">
                                <Icon icon="location_on" />
                                <span className="text-nowrap">{delivery.delivery_place.name}</span>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
        <div ref={elementRef}></div>
    </>
}
