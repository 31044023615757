import {useContext, useEffect, useState} from "react"
import {Outlet, useParams} from "react-router"
import {ConfigContext} from "src"
import {OrderRequestDeliveryDetailModel} from "src/models/manager/order/order-request-delivery.model"
import deliveryService from "src/services/delivery.service"

export function DeliveryDetailComponent() {
    const {orderId, id} = useParams()
    const [delivery, setDelivery] = useState<OrderRequestDeliveryDetailModel>()
    const {showMobileMenu, hideMobileMenu} = useContext(ConfigContext)

    const getDelivery = () => deliveryService.get(+orderId, +id).then(setDelivery)

    useEffect(() => {
        deliveryService.get(+orderId, +id).then(setDelivery)
        return () => setDelivery(undefined)
    }, [orderId, id])

    useEffect(() => {
        hideMobileMenu()
        return () => showMobileMenu()
    }, [hideMobileMenu, showMobileMenu])

    return delivery && <Outlet context={{delivery, getDelivery}} />
}