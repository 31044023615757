import {AxiosResponse} from "axios"

export default function exportExcel(filename: string, response: AxiosResponse): void {
    const blob = new Blob([response.data], {type: response.headers['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;']})
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `${filename}.xlsx`
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
}