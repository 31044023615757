import React, {useContext, useMemo} from 'react'
import {ConfigContext} from "../../../../index"

interface InventoryItem {
  label: string
  value: any
  className?: string
}

interface InventorySection {
  values: InventoryItem[]
  title?: string
  className?: string
}

const Card: React.FC<InventorySection> = ({title, values, className}) => {
  const {isMobile} = useContext(ConfigContext)
  return (

    <div className={`${isMobile && 'w-full'} ${className}`} >
      {title && <label className="overflow line-clamp-1 text-sm color-gray-400 mb-2">{title}</label>}
      <div className={`${!isMobile ? 'card-bio mb-3' : 'grid grid-cols-2 md:w-auto w-full px-4 my-4 rounded-lg border border-gray-100 shadow'} bg-white flex flex-row`}>
        {values.map((value, index) => {
          if (isMobile) {
            return (<div className={`flex flex-col w-full md:py-0 py-4  ${value?.className}`} key={index}>
              <label className="text-sm color-gray-400 mb-2">{value.label}</label>
              <label className="font-bold text-lg">{value.value}</label>
            </div>)
          }
          return (
            <div className={`flex flex-col w-auto mx-1 ${value?.className}`} key={index}>
              <label className="font-bold text-lg mb-2">{value.value}</label>
              <label className="text-sm color-gray-400">{value.label}</label>
            </div>
          )

        })}
      </div>
    </div>
  )
}

const CardWrapper = ({cardSectionData = [], className = 'mb-3'}: {cardSectionData: InventorySection[], className?: string}) => {
  const inventorySections = useMemo(
    () => cardSectionData.map((data, index) => <Card key={index} title={data.title} values={data.values} className={data?.className} />),
    [cardSectionData],
  )

  return <div className={`flex flex-wrap md:justify-start justify-between items-stretch md:w-auto w-full ${className}`} style={{columnGap: 24, rowGap: 8}}>{inventorySections}</div>
}

export default CardWrapper
