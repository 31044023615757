import {CSSProperties, forwardRef, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Aside} from './components/aside/aside'
import Header from './components/header'
import HeaderPeriodBanner from "./components/header-banner"
import rentalPointService from "./components/modal/global/rental-point.service"
import {Spinner} from './components/shared/spinner/spinner'
import authService from "./services/auth.service"
import loadingService from "./services/loading.service"
import TourWrapper from "./components/onboarding"
import clsx from "clsx"
import {ConfigContext} from "src"
import {MobileMenu} from "./components/mobile-menu/mobile-menu"
import {use100vh} from "react-div-100vh"
import './App.scss'

const noPaddingRoutes = ['/profile', '/config', '/settings', '/documents', '/geolocation', '/wazzup']

const Children = forwardRef(({children}: {children: JSX.Element}, ref) => {
  const scrollToRef = (_ref: any) => _ref.current?.scroll({top: 0, behavior: 'smooth'})
  const location = useLocation()
  const height = use100vh()
  const {isMobile, headerHeight} = useContext(ConfigContext)
  const contentRef = useRef(null)
  const noPadding = useMemo(() => noPaddingRoutes.some(link => location.pathname.startsWith(link)), [location.pathname])
  const scroll = () => scrollToRef(contentRef)

  const style = useMemo(() => {
    let ret: CSSProperties = {padding: 16}
    if (noPadding) ret = {...ret, padding: 0}
    if (isMobile) ret = {...ret, minHeight: height, paddingTop: headerHeight + 16}
    if (!isMobile) ret = {...ret, height: height - headerHeight}

    return ret
  }, [height, noPadding, isMobile, headerHeight])

  useEffect(() => scroll(), [location.pathname])

  return (
    <div
      ref={contentRef}
      className={clsx("relative w-full bg-bg-light overflow-x-scroll pb-40 md:pb-4")}
      style={style}
    >
      {children}
    </div>
  )
})

export default function Main({children}: {children: JSX.Element}) {
  const navigate = useNavigate()
  const {constants, isMobile} = useContext(ConfigContext)
  const [loading, setLoading] = useState<string[]>([])
  const [smallMenu, setSmallMenu] = useState(() => {
    if (localStorage.getItem('aside-menu')) return localStorage.getItem('aside-menu') === 'true'
    return false
  })

  const onAside = () => setSmallMenu(p => {
    localStorage.setItem('aside-menu', String(!p))
    return !p
  })

  useEffect(() => {
    const loadingSub = loadingService.loading$.subscribe(setLoading)
    return () => loadingSub.unsubscribe()
  }, [])

  useEffect(() => {
    if (!constants) return
    const rentalPoint = authService.profile$.subscribe((profile) => {
      if (!profile) return
      if (profile.points.length > 0) rentalPointService.setPoint(constants.RENTAL_POINTS[profile.points[0]])
    })
    return () => rentalPoint.unsubscribe()
  }, [constants])

  useEffect(() => {
    const sub = authService.logged$.subscribe(logged => {
      if (!logged) navigate('/auth')
    })
    return () => sub.unsubscribe()
  }, [navigate])


  return (
    constants && (
      <>
        {isMobile ? (
          <Header
            id="vhContent"
            className="relative right-side w-full overflow-y-scroll"
            topchildren={<HeaderPeriodBanner />}
          >
            <>
              <Children>{children}</Children>
              <MobileMenu />
            </>
          </Header>
        ) : (
          <div
            id="vhContent"
            className={clsx('wrapper big-menu overflow-y-scroll md:overflow-auto', smallMenu && 'small-menu')}
          >
            <Aside collapse={smallMenu} onCollapse={onAside} />
            <div className="relative wrapper-content md:overflow-y-scroll h-screen">
              <Header className="relative right-side" topchildren={<HeaderPeriodBanner />}>
                <Children>{children}</Children>
              </Header>
              <TourWrapper />
            </div>
          </div>
        )}

        {loading.length > 0 && <Spinner />}
      </>
    )
  )
}
