import egov1 from 'src/assets/images/integrations/egov_1.png'
import egov2 from 'src/assets/images/integrations/egov_2.png'
import egov3 from 'src/assets/images/integrations/egov_3.png'
import {useContext} from "react"
import {ConfigContext} from "src"
import {useTranslation} from 'react-i18next'
import HeaderContent from "src/components/header/header-content"
import {Link} from "react-router-dom"
import {IntegrationLandingWrapper} from "../landing"
import Icon from "src/components/shared/components/material-icon"
import {Image} from 'antd'

export function EgovIntegrationLanding() {
    const {integrationMap} = useContext(ConfigContext)
    const {t} = useTranslation()
    const integration = integrationMap["egov"]

    return integration && <>
        <HeaderContent>
            <div className="flex gap-2 items-center header-content">
                <Link to="/integrations" className="text-2xl font-semibold link">{t('integration.header')}</Link>
                <Icon icon="chevron_right" />
                <div className="text-2xl font-semibold">{integration.name}</div>
            </div>
        </HeaderContent>

        <IntegrationLandingWrapper integration={integration}>
            <div className="flex flex-row gap-3 mb-3 flex-nowrap">
                <Image.PreviewGroup>
                    <Image src={egov1} className="h-64 object-contain rounded-lg shadow border border-gray-100 bg-white" draggable={false} />
                    <Image src={egov2} className="h-64 object-contain rounded-lg shadow border border-gray-100 bg-white" draggable={false} />
                    <Image src={egov3} className="h-64 object-contain rounded-lg shadow border border-gray-100 bg-white" draggable={false} />
                </Image.PreviewGroup>
            </div>

            <p className="text-base">Интеграция с Egov Mobile: Подпись документов через QR-код</p>
            <p className="text-base">В нашей CRM-системе реализована интеграция с Egov Mobile, позволяющая подписывать сгенерированные документы с помощью электронной цифровой подписи (ЭЦП) через мобильное приложение Egov Mobile</p>

            <div className="flex flex-col gap-2">
                <h3 className="text-[18px] font-semibold mb-0">Как работает интеграция:</h3>
                <ol className="flex flex-col gap-1 list-decimal text-base">
                    <li className="ml-4"><p><strong>QR-код:</strong> Система генерирует QR-код, который пользователь может отсканировать через приложение Egov Mobile для подписи</p></li>
                    <li className="ml-4"><p><strong>Ссылка на документ:</strong> Также можно отправить ссылку на документ любым удобным способом. Пользователь может открыть ссылку на мобильном устройстве и завершить процесс подписания через Egov Mobile</p></li>
                    <li className="ml-4"><p><strong>Подтверждение данных:</strong> Пользователь вводит PIN-код или подтверждает личность с использованием биометрических данных (отпечаток пальца, распознавание лица) для подписи документа</p></li>
                    <li className="ml-4"><p><strong>Использование ключа ЭЦП:</strong> Процесс подписи осуществляется с использованием ключа ЭЦП, который выдается Национальным удостоверяющим центром Республики Казахстан при первичном входе в Egov Mobile</p></li>
                </ol>
            </div>

            <div className="flex flex-col gap-2">
                <h3 className="text-[18px] font-semibold mb-0">Преимущества интеграции:</h3>
                <ol className="flex flex-col gap-1 list-disc text-base">
                    <li className="ml-4"><p>Поддержка подписания документов как для физических, так и для юридических лиц через приложения Egov Mobile и eGov Business</p></li>
                    <li className="ml-4"><p>Безопасная и быстрая авторизация с использованием ЭЦП или биометрии</p></li>
                    <li className="ml-4"><p>Полная интеграция с нашей CRM для автоматизированного и удобного управления документами</p></li>
                </ol>
            </div>

        </IntegrationLandingWrapper >
    </>
}