import modalService from "../modal/global/modal.service"
import workshiftService, {WorkshiftModel} from "src/services/workshift.service"
import {ConfirmModal} from "../modal/global/confirmModal"
import {useEffect, useState} from "react"
import {WorkshiftReceipt} from "./workshift-receipt"
import onReceiptPrint from "src/services/printer"
import {createRoot} from "react-dom/client"
import html2canvas from "html2canvas"

export default function WorkshiftDetail() {
  const [workshift, setWorkshift] = useState<WorkshiftModel>()

  const onEnd = () => {
    const onConfirm = async () => {
      const element = createRoot(document.getElementById('receipt-wrapper'))
      element.render(<WorkshiftReceipt type="download" workshift={workshift} />)

      setTimeout(() => {
        html2canvas(document.getElementById('capture'), {scale: 2}).then(async (canvas) => {
          onReceiptPrint(canvas).then(() => workshiftService.end())
          element.unmount()
        })
      }, 100)
    }
    modalService.closeModal()
    modalService.open({
      component: <ConfirmModal
        message="Вы уверены, что хотите завершить текущую смену."
        delete={true}
        confirm_text="Завершить"
        onConfirm={onConfirm}
      />
    })
  }

  useEffect(() => {
    const sub = workshiftService.workshift$.subscribe(setWorkshift)
    return () => sub.unsubscribe()
  }, [])

  return workshift && (
    <div className="flex flex-col">
      <div className="flex w-full justify-center mb-3">
        <WorkshiftReceipt type="download" workshift={workshift} paddingWidth={8} className="shadow" />
      </div>

      <div className="btn btn-primary btn-color-white" onClick={onEnd}>Завершить смену</div>
    </div>
  )
}
