import {useTranslation} from 'react-i18next'
import {Outlet, useNavigate} from 'react-router-dom'
import {Segmented} from "src/components/shared/antd-custom"
import {useCurrentRoute} from 'src/hooks/useCurrentRoute'

export default function ReportPayback() {
  const navigate = useNavigate()
  const page = useCurrentRoute(4)
  const {t} = useTranslation()

  return (
    <>
      <Segmented
        value={page}
        className="bg-gray-100 mb-4 self-center rounded-md mr-auto"
        onChange={option => navigate(option)}
        style={{background: 'var(--color-bg-light)', padding: 4, borderRadius: 6}}
        options={[
          {label: t('metrics.inventory.pages.efficency'), value: 'efficency', className: "p-1 font-medium text-black"},
          {label: t('metrics.inventory.pages.payback'), value: 'payback', className: "p-1 font-medium text-black"},
        ]}
      />

      <Outlet />
    </>
  )
}
