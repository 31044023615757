import axios from 'axios'
import {ListModel} from '../../models/common'
import {ApiAbstract} from '../../abstract/api.abstract'
import {SmsModel} from "src/models/integration/sms"

export class SmsService extends ApiAbstract {
  constructor() {
    super('v1/crm/sms')
  }

  public async connect(): Promise<undefined> {
    const response = await axios.post(this.getUrl("connect"))
    return response.data
  }

  public async getAccount(): Promise<{balance: number}> {
    const response = await axios.get(this.getUrl("account"))
    return response.data
  }

  public async listMessages(params?: any): Promise<ListModel<SmsModel>> {
    const response = await axios.get(this.getUrl("messages"), {params})
    return response.data
  }
}

const smsService = new SmsService()
export default smsService
