import {FormProvider, useForm} from "react-hook-form"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormControlDate} from "src/components/shared/inputs/form-control-date"
import {useEffect, useState} from "react"
import authService, {ProfileModel} from "src/services/auth.service"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import {useParams} from "react-router-dom"
import {useNavigate} from "react-router"
import {BulkInventoryForm} from "."
import {CustomFieldChoiceList} from "../../settings/custom-field/field-choice"
import payloadFilter from "src/utils/payload.filter"
import InventoryEditSubleaseComponent from "../../inventories/edit/inventory-sublease-edit"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from 'react-i18next'
import {SharedRentalPointSelect} from "src/components/shared/components/select/rental-point"


export const InventoryGroupInventoriesBulkCreate = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const onBack = () => navigate(-1)
  const [profile, setProfile] = useState<ProfileModel>()
  const {t} = useTranslation()
  const form = useForm<BulkInventoryForm>()

  const {formState: {isValid}} = form

  const onSubmit = async () => {
    const payload = {...payloadFilter(form.getValues(), {}, 'post'), group: +id}
    inventoryGroupService.bulkCreateInventories(+id, payload).then(() => navigate(-1))
  }

  useEffect(() => {
    const sub = authService.profile$.subscribe(profile => {
      setProfile(profile)
      if (profile.points.length > 0) form.setValue('rental_point', profile.points[0])
    })
    return () => {
      sub.unsubscribe()
      setProfile(undefined)
    }
  }, [])

  return <FormProvider {...form}>
    <div className="flex flex-col">
      <div className="flex gap-2 items-center mb-4">
        <Icon onClick={onBack} className="cursor-pointer text-2xl" icon="chevron_left" />
        <div className='text-2xl font-semibold'>{t('groups.form.title.inventory_add')}</div>
      </div>

      <div className="text-xl font-semibold mb-3">{t('groups.form.title.main')}</div>

      <div className="flex flex-1 gap-2 mb-3">
        <FormControl
          rootclassname="col"
          className="form-control"
          name="count"
          label={t('groups.form.inventory.count')}
          placeholder={t('groups.form.inventory.placeholder.count')}
          number_only={+true}
          number_max={1000}
          required={true}
        />
        <SharedRentalPointSelect
          className="col"
          label={t('common.select.rental_point')}
          name="rental_point"
          params={{required: true}}
          required={true}
          disabled={profile && !profile.is_superuser}
          isClearable={true}
          isSearchable={false}
        />
        <FormControl
          name="buy_price"
          rootclassname="col"
          className="form-control"
          label={t('groups.form.inventory.buy_price')}
          placeholder={t('groups.form.inventory.placeholder.buy_price')}
          number_only={+true}
          number_decimal={+true}
        />
        <FormControlDate
          label={t('groups.form.inventory.buy_date')}
          type="date"
          name="buy_date"
          rootclassname="col"
          className="form-control"
        />
      </div>

      <CustomFieldChoiceList className="mb-3" type="inventory" />

      <InventoryEditSubleaseComponent initial={false} />

      <div className="flex justify-end">
        <button onClick={onSubmit} disabled={!isValid} className="btn btn-primary btn-color-white">{t('common.save_and_add')}</button>
      </div>
    </div>
  </FormProvider>
}