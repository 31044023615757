import {useEffect, useState} from "react"
import {map} from "rxjs"
import {CustomField, CustomFieldModel, CustomFieldOption} from "src/models/setting-config"
import settingService from "src/services/settings.service"
import {chunkArray} from "src/utils/chunk-array"
import {isNil} from "src/utils/isNil"
import {CustomFieldFilterChoice} from "./field-filter-choice"

interface CustomFieldProps {
    type: keyof CustomFieldModel
    prefix: string
    manualOptions?: CustomFieldOption
    clearable?: boolean
    header?: JSX.Element
    onChange?: (val: any, key: string) => void,
    className?: string
    filter?: any
}

export function CustomFieldFilterList(props: CustomFieldProps) {
    const [customFields, setCustomFields] = useState<CustomField[]>([])
    const [manualOptions, setManualOptions] = useState<CustomFieldOption>(props.manualOptions)
    const fields = chunkArray(customFields, 4)

    useEffect(() => {
        const sub = settingService.config$
            .pipe(map(config => config.custom_fields[props.type].filter(field => field.filter)))
            .subscribe(fields => {
                const keys = fields.map(obj => obj.name)
                setCustomFields(fields)

                if (fields.length > 0 && props.type === 'inventory' && isNil(manualOptions)) {
                    settingService
                        .getInventoryOptions(props.filter)
                        .then(options => setManualOptions(Object.fromEntries(keys.map(key => [key, options[key]]))))
                }
            })
        return () => sub.unsubscribe()
    }, [props.type])

    const filterAvailable = customFields.length > 0 && manualOptions && customFields.filter(field => manualOptions[field.name]?.filter(v => !isNil(v[`extra__${field.name}`])).length > 0).length > 0

    return filterAvailable && (
        <div className={props.className || ''}>
            {props.header}
            <div className="flex flex-col gap-3">
                {fields.map((chunk: CustomField[], index: number) => (
                    <div className="flex flex-wrap gap-3" key={index}>
                        {chunk.map((field: CustomField) =>
                            <CustomFieldFilterChoice
                                key={field.name}
                                type={field.type}
                                field={`extra__${field.name}`}
                                onChange={props.onChange ? (val) => props.onChange(val, field.name) : undefined}
                                params={{
                                    name: `${props.prefix}${field.name}`,
                                    placeholder: field.label,
                                    required: field.required,
                                    params: {required: field.required},
                                    rootclassname: "col",
                                    options: manualOptions[field.name].filter(v => !isNil(v[`extra__${field.name}`]))
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}