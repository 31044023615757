import {MouseEvent, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import type {ColumnsType} from "antd/es/table"
import {AsyncRentalPoint} from "../../abstract/async-rental-point"
import {FormProvider, useForm} from "react-hook-form"
import {EMPTY_LIST, ListModel, ListParams} from "../../models/common"
import {useDebounce} from "../../hooks/useDebounce"
import modalService from "../../components/modal/global/modal.service"
import {TableRowSelection} from "antd/es/table/interface"
import {FormControl} from "../../components/shared/inputs/form-control"
import {ConfigContext} from 'src/index'
import {ControlCheckbox} from "../../components/shared/inputs/control-checkbox"
import {useCurrentRoute} from "../../hooks/useCurrentRoute"
import inventoryGroupService from "../../services/inventory/inventory-group.service"
import {SharedInventoryGroupSelect} from "../../components/shared/components/select/inventory-group"
import {colourStyles} from "../../components/shared/inputs/form-select-color-style"
import {SharedCategorySelect} from "../../components/shared/components/select/category"
import Icon from "../../components/shared/components/material-icon"
import Table from "../../components/shared/antd-custom/table"
import {ImageField} from "../../components/shared/image-loader/image"
import {hexToRgbA} from "../../utils/color/hex-to-rgba"
import {invertColor} from "../../utils/color/color-invert"
import CustomSelect from "../../components/shared/inputs/select/select"
import {components} from 'react-select'
import maintenanceService from "../../services/maintenance/maintenance.service"
import {InventoryMaintenanceModel, MaintenanceModel} from "../../models/manager/maintenance/maintenance.model"
import PaginationFormComponent from "../../components/shared/pagination/pagination-form"
import MaintenanceModal from "./modal-maintenance"
import {tiresLabels, washedLabels} from "./const"
import moment from "moment-timezone"
import {Popover} from "antd"
import OneMaintenanceHistory from "./one-maintenance-history"
import {colorPaletes} from "../../components/shared/constants"
import {SharedInventoryStateSelect} from "../../components/shared/components/select/inventory-state"

interface MaintenanceParams extends ListParams {
    search: string
    state: string
    rental_point: number
    group: number | string
    client__signed: boolean
    group__category: number
    category: string
}

export default function MaintenanceList() {
    const [list, setList] = useState<ListModel<InventoryMaintenanceModel>>(EMPTY_LIST)
    const {t} = useTranslation()
    const defaultConfig = {page: 1, pageSize: 10}
    const form = useForm<MaintenanceParams>({defaultValues: defaultConfig})
    const {reset, setValue, watch} = form
    const values = watch()
    const activeRoute = useCurrentRoute()
    const searchDebounce = useDebounce(watch('search', ''), 500)
    const {settings, constantsMap} = useContext(ConfigContext)
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
    const onLoad = async (params: any): Promise<void> =>
        maintenanceService.list({...params}).then(res => {
            setList(res)
        }).catch(e => e.response.status === 404 && setValue('page', 1))

    const stateStatus = (status: number) => {
        const state = constantsMap.INVENTORY_STATE_STATUS[status]
        return state && (
            <div className="btn font-semibold" style={{backgroundColor: state.color, color: invertColor(state.color)}}>
                {state.name}
            </div>
        )
    }

    const newMaintenance = (inventory: InventoryMaintenanceModel, finish?: boolean) => (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        modalService.open({
            props: {backdrop: true, dialogClassName: 'max-w-fit'},
            component: <MaintenanceModal
                inventory={inventory}
                onSubmit={data => maintenanceService.postForInventory(inventory.id, data).then(() => setValue('page', 1))}
                finish={finish}
            />
        })
    }

    const editMaintenance = (inventory: InventoryMaintenanceModel, finish?: boolean) => (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        modalService.open({
            props: {backdrop: true, dialogClassName: 'max-w-fit'},
            component: <MaintenanceModal
                inventory={inventory}
                onSubmit={data => maintenanceService.patch(inventory.id, inventory.last_maintenance.id, data).then(() => setValue('page', 1))}
                finish={finish}
            />
        })
    }

    const finishMaintenance = (inventory: InventoryMaintenanceModel, finish?: boolean) => (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        modalService.open({
            props: {backdrop: true, dialogClassName: 'max-w-fit'},
            component: <MaintenanceModal
                inventory={inventory}
                onSubmit={data => maintenanceService.finish(inventory.id, inventory.last_maintenance.id, data).then(() => setValue('page', 1))}
                finish={finish}
            />
        })
    }

    const openMaintanenceHistory = (inventory: InventoryMaintenanceModel) => (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        modalService.open({
            props: {size: 'xl', backdrop: true},
            component: <OneMaintenanceHistory
                inventory={inventory}
            />
        })
    }

    const rowSelection: TableRowSelection<MaintenanceModel> = {
        selectedRowKeys,
        onChange: (keys: number[]) => setSelectedRowKeys(keys),
        columnWidth: 48,
        columnTitle: <ControlCheckbox
            className="form-check-input"
            checked={selectedRowKeys.length !== 0 && selectedRowKeys.length === list.results.length}
            indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length !== list.results.length}
            onChange={() => setSelectedRowKeys(prev => prev.length === 0 ? list.results.map(obj => obj.id) : [])}
        />,
        renderCell: (val: boolean, inventory: MaintenanceModel) => <ControlCheckbox
            className="form-check-input"
            checked={val}
            onClick={(e) => e.stopPropagation()}
            onChange={() => setSelectedRowKeys(prev => prev.includes(inventory.id) ? prev.filter(k => k !== inventory.id) : [...prev, inventory.id])}
        />
    }

    const changeTiresForAll = (tiresType: any) => {
        selectedRowKeys.map(val => maintenanceService.extra_update(val, {tires: tiresType.value}).then(() => {
            setSelectedRowKeys([])
            onLoad(values)
        }))
    }

    const changeWashedForAll = (washedType: any) => {
        selectedRowKeys.map(val => maintenanceService.extra_update(val, {washed: washedType.value}).then(() => {
            setSelectedRowKeys([])
            onLoad(values)
        }))
    }
    useEffect(() => reset({...values, page: 1, search: searchDebounce}), [searchDebounce])

    useEffect(() => {
        onLoad(values)
        const sub = watch((params, {name}) => {
            if (name !== 'search') onLoad(params)
        })
        return () => {
            sub.unsubscribe()
            setList(EMPTY_LIST)
        }
    }, [activeRoute])


    const columns: ColumnsType<InventoryMaintenanceModel> = [
        {
            width: 71,
            title: '№',
            dataIndex: 'id',
            key: 'id',
            render: id => id
        },
        {
            width: 240,
            title: t('maintenance.automobile'),
            dataIndex: 'inventory',
            key: 'inventory',
            render: (_, {category, image, name, unique_id}) => (
                <div className="flex gap-3 items-center">
                    <ImageField src={image} className="w-10 h-10 rounded-lg" />

                    <div className="flex flex-col gap-1">
                        <span className="fw-500 link">{name}</span>
                        <span>{constantsMap.INVENTORY_CATEGORIES[category].name} / {unique_id}</span>
                    </div>
                </div>
            ),
            sorter: true,
        },
        {
            width: 144,
            title: t('maintenance.state'),
            dataIndex: 'state',
            key: 'state',
            render: state => state ? (
                <span
                    style={{
                        backgroundColor: hexToRgbA(constantsMap.INVENTORY_STATE_STATUS[+state].color, 0.2),
                        color: constantsMap.INVENTORY_STATE_STATUS[+state].color,
                    }}
                    className={'flex justify-center items-center h-8 w-full rounded-lg p-2 text-[13px] font-medium'}
                >
                    {constantsMap.INVENTORY_STATE_STATUS[+state].name}
                </span>
            ) : (
                <span
                    style={{backgroundColor: '#EDEEF7', color: '#8997A1'}}
                    className={'flex justify-center items-center h-8 w-full rounded-lg p-2 text-[13px] font-medium'}
                >
                    {t('maintenance.unchecked')}
                </span>
            ),
            sorter: true,
        }, {
            width: 161,
            title: t('maintenance.status'),
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <span
                    className="flex justify-center items-center h-8 w-full rounded-lg p-2 text-[13px] font-medium"
                    style={{backgroundColor: hexToRgbA(colorPaletes[status], 0.2), color: colorPaletes[status]}}
                >
                    {constantsMap.INVENTORY_STATUS[status]?.label}
                </span>
            ),
            sorter: true,
        }, {
            width: 192,
            title: t('maintenance.washing'),
            dataIndex: 'extra__washed',
            key: 'washed',
            render: (_, {washed, id}, index) => {
                return <CustomSelect
                    components={{
                        Menu: (params) =>
                            <components.Menu {...params} className={'w-fit'}>
                                {params.children}
                                <span
                                    className="flex items-center justify-center h-11 w-[168px] cursor-pointer rounded-md bg-primary text-white font-medium text-[13px] m-3"
                                    onClick={() => maintenanceService.extra_update(id, {washed: washed})}
                                >
                                    {t('maintenance.save_changes')}
                                </span>
                            </components.Menu>
                    }}
                    styles={{
                        ...colourStyles(false),
                        control: (styles, props) => ({
                            ...styles,
                            border: 'none',
                            background: 'transparent',
                            boxShadow: "none",
                            flexWrap: 'nowrap'
                        }),
                        singleValue: (styles, props) => ({
                            ...styles,
                            textOverflow: 'ellipsis',
                            overflow: "visible",
                            color: (tiresLabels[washed]) ? 'var(--color-primary)' : 'var(--color-gray-test-370)',
                            fontWeight: 500,
                        }),
                        dropdownIndicator: (styles, props) => ({
                            ...styles,
                            color: (tiresLabels[washed]) ? 'var(--color-primary)' : 'var(--color-gray-test-370)',
                        }),
                        option: (styles, props) => ({
                            ...styles,
                            background: "transparent",
                            fontWeight: 400,
                            borderRadius: 0,
                            padding: '12px 16px',
                            transition: '0.25s all',
                            backgroundColor: 'transparent',
                            color: props.isSelected ? 'var(--color-primary)' : 'var(--color-black)',
                            borderBottom: '1px solid var(--color-gray-100)',
                            cursor: 'pointer',
                            ':active': {
                                backgroundColor: 'transparent'
                            },
                            ':first-of-type': {
                                marginTop: 0,
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8
                            },
                            ':last-of-type': {
                                borderBottomLeftRadius: 8,
                                borderBottomRightRadius: 8
                            },
                            '&:focus': {
                                backgroundColor: props.isSelected ? 'var(--color-primary)' : 'var(--color-primary-8)',
                                color: props.isSelected ? 'var(--color-white)' : 'var(--color-primary)'
                            },
                            '&:hover': {
                                backgroundColor: 'var(--color-primary-8)',
                                color: 'var(--color-primary)'
                            },

                        }),
                        valueContainer: (styles, props) => ({
                            ...styles,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'nowrap',
                            gap: '4px',
                            textWrap: "nowrap",
                            overflow: 'visible',
                        }),
                    }}
                    className="col"
                    menuPlacement="bottom"
                    isClearable={false}
                    isSearchable={false}
                    options={washedLabels}
                    value={washedLabels[washed]}
                    onChange={(val: {value: number, label: string}) => {
                        const updatedResults = [...list.results]
                        updatedResults[index].washed = val.value
                        setList({...list, results: updatedResults})
                    }}
                    selectedIconStyles={{color: "var(--color-primary)"}}
                    iconPlaceholderNeed={true}
                    placeholder={t('maintenance.not_chosen')}
                    closeMenuOnSelect={false}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                />
            },
            sorter: true,
        }, {
            width: 136,
            title: t('maintenance.tires'),
            dataIndex: 'extra__tires',
            key: 'tires',
            render: (_, {tires, id}, index) => {
                return <CustomSelect
                    components={{
                        Menu: (params) =>
                            <components.Menu {...params} className={'w-fit'}>
                                {params.children}
                                <span
                                    className="flex items-center justify-center h-11 w-[168px] cursor-pointer rounded-md bg-primary text-white font-medium text-[13px] m-3"
                                    onClick={() => maintenanceService.extra_update(id, {tires: tires})}
                                >
                                    {t('maintenance.save_changes')}
                                </span>
                            </components.Menu>
                    }}
                    styles={{
                        ...colourStyles(false),
                        control: (styles, props) => ({
                            ...styles,
                            border: 'none',
                            background: 'transparent',
                            boxShadow: "none",
                            flexWrap: 'nowrap'
                        }),
                        singleValue: (styles, props) => ({
                            ...styles,
                            textOverflow: 'ellipsis',
                            overflow: "visible",
                            color: (tiresLabels[tires - 1]) ? 'var(--color-primary)' : 'var(--color-gray-test-370)',
                            fontWeight: 500,
                        }),
                        dropdownIndicator: (styles, props) => ({
                            ...styles,
                            color: (tiresLabels[tires - 1]) ? 'var(--color-primary)' : 'var(--color-gray-test-370)',
                        }),
                        option: (styles, props) => ({
                            ...styles,
                            background: "transparent",
                            fontWeight: 400,
                            borderRadius: 0,
                            padding: '12px 16px',
                            transition: '0.25s all',
                            backgroundColor: 'transparent',
                            color: props.isSelected ? 'var(--color-primary)' : 'var(--color-black)',
                            borderBottom: '1px solid var(--color-gray-100)',
                            cursor: 'pointer',
                            ':active': {
                                backgroundColor: 'transparent'
                            },
                            ':first-of-type': {
                                marginTop: 0,
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8
                            },
                            ':last-of-type': {
                                borderBottomLeftRadius: 8,
                                borderBottomRightRadius: 8
                            },
                            '&:focus': {
                                backgroundColor: props.isSelected ? 'var(--color-primary)' : 'var(--color-primary-8)',
                                color: props.isSelected ? 'var(--color-white)' : 'var(--color-primary)'
                            },
                            '&:hover': {
                                backgroundColor: 'var(--color-primary-8)',
                                color: 'var(--color-primary)'
                            },

                        }),
                        valueContainer: (styles, props) => ({
                            ...styles,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'nowrap',
                            gap: '4px',
                            textWrap: "nowrap",
                            overflow: 'visible',
                        }),
                    }}
                    className="col"
                    menuPlacement="bottom"
                    isClearable={false}
                    isSearchable={false}
                    options={tiresLabels}
                    value={tiresLabels[tires - 1]}
                    onChange={(val: {value: number, label: string}) => {
                        const updatedResults = [...list.results]
                        updatedResults[index].tires = val.value
                        setList({...list, results: updatedResults})
                    }}
                    selectedIconStyles={{color: "var(--color-primary)"}}
                    iconPlaceholderNeed={true}
                    placeholder={t('maintenance.not_chosen')}
                    closeMenuOnSelect={false}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                />
            },
            sorter: true,
        }, {
            width: 176,
            title: t('maintenance.scheduled_maintenance'),
            dataIndex: 'scheduled_maintenance',
            key: 'scheduled_maintenance',
            render: (_, inventory, index) => {
                if (!inventory.last_maintenance) return (
                    <button
                        className="text-primary flex items-center gap-2 font-normal cursor-pointer"
                        onClick={newMaintenance(inventory)}
                    >
                        <Icon icon="add" />
                        <span className="hover:underline hover:decoration-primary w-full h-full">{t('maintenance.new_maintenance')}</span>
                    </button>
                )
                const targetDateMoment = moment(inventory.last_maintenance.maintenance_date)
                const targetDate = targetDateMoment.format('DD-MM-YYYY')
                const currentDate = moment()

                const daysRemaining = targetDateMoment.diff(currentDate, 'days')
                return <Popover
                    trigger="hover"
                    className="flex gap-2 items-center"
                    overlayInnerStyle={{padding: '0'}}
                    placement={'bottom'}
                    content={(
                        <div className="flex gap-1 flex-col">
                            <button
                                className={'flex gap-2 p-4 items-center min-w-[192px] border-b border-gray-100'}
                                onClick={finishMaintenance(inventory, true)}
                            >
                                <Icon icon={'add'} />
                                <span>{t('maintenance.do_maintenance')}</span>
                            </button>

                            <button
                                className={'flex gap-2 p-4 items-center min-w-[192px]'}
                                onClick={editMaintenance(inventory)}
                            >
                                <Icon icon={'edit'} />
                                <span>{t('common.edit')}</span>
                            </button>
                        </div>
                    )}
                >
                    <div className={'flex gap-2 items-center cursor-pointer'}>
                        <Icon icon={'warning'} className={'text-primary'} />
                        <div className={'flex flex-col hover:underline hover:decoration-primary w-full h-full'}>
                            <span className={'text-primary '}>{targetDate}</span>
                            {daysRemaining === 0 ?
                                <span className={'text-primary '}>{t('common.times_count.today')}</span> : (daysRemaining < 20 && daysRemaining > 0) &&
                                <span className={'text-primary '}>{`(${t('common.after')} ${daysRemaining} ${t('common.times_count.days')})`}</span>
                            }
                        </div>
                    </div>
                </Popover>
            },
            sorter: true,
        }
    ]

    return (
        <FormProvider {...form}>
            <div className="d-flex gap-2 mt-4 mb-8 ">
                <FormControl
                    name="search"
                    className="form-control"
                    icon="search"
                    placeholder={t('common.input.search')}
                />
                <SharedInventoryStateSelect
                    name={'state'}
                    placeholder={t('maintenance.selects.state_select.all')}
                    styles={{
                        ...colourStyles(false),
                        placeholder: (styles, props) => ({
                            ...styles,
                            color: "black"
                        }),
                        container: (styles, props) => ({
                            ...styles,
                            minWidth: "192px"
                        })
                    }}
                    isClearable={true}
                />
                <AsyncRentalPoint
                    name={'rental_point'}
                    placeholder={t('maintenance.all_rental_point')}
                    className={'flex-initial'}
                    styles={{
                        ...colourStyles(false),
                        placeholder: (styles, props) => ({
                            ...styles,
                            color: "black"
                        }),
                        container: (styles, props) => ({
                            ...styles,
                            minWidth: "192px"
                        })
                    }}
                />
                <SharedInventoryGroupSelect
                    name="group"
                    placeholder={t('maintenance.all_products')}
                    listOptions={params => inventoryGroupService.list({...params, type: 0})}
                    isSearchable={true}
                    isClearable={true}
                    styles={{
                        ...colourStyles(false),
                        placeholder: (styles, props) => ({
                            ...styles,
                            color: "black"
                        }),
                    }}
                />
                <SharedCategorySelect
                    name="group__category"
                    placeholder={t('maintenance.all_categories')}
                    isClearable={true}
                    isSearchable={false}
                    styles={{
                        ...colourStyles(false),
                        placeholder: (styles, props) => ({
                            ...styles,
                            color: "black"
                        }),
                    }}
                />
            </div>
            <Table
                title={selectedRowKeys.length > 0 && (() => (
                    <div className="flex justify-start items-center">
                        <div className="flex gap-3">
                            <CustomSelect
                                components={{
                                    Menu: params =>
                                        <components.Menu {...params} className={'w-fit'}>
                                            {params.children}
                                            <button
                                                className={`flex items-center justify-center h-11 w-[226px] cursor-pointer rounded-md ${params.selectProps.value === null ? 'bg-gray-100 text-gray-test-350' : 'bg-primary text-white'} font-medium text-[13px] m-3`}
                                                disabled={params.selectProps.value === null}
                                                onClick={() => changeTiresForAll(params.selectProps.value)}
                                            >
                                                {t('maintenance.do_for_chosen')}
                                            </button>
                                        </components.Menu>


                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                    ...colourStyles(false),
                                    control: (styles, props) => ({
                                        ...styles,
                                        background: 'var(--color-gray-test-700)',
                                        borderRadius: '6px',
                                        border: 'none',
                                        boxShadow: "none"
                                    }),
                                    singleValue: (styles, props) => ({
                                        ...styles,
                                        textOverflow: 'ellipsis',
                                        overflow: "visible",
                                        // color: 'var(--color-primary)',
                                        fontWeight: 500,

                                    }),
                                    dropdownIndicator: (styles, props) => ({
                                        ...styles,
                                        color: 'var(--color-test-black)',
                                        cursor: 'pointer'
                                    }),
                                    option: (styles, props) => ({
                                        ...styles,
                                        background: "transparent",
                                        fontWeight: 400,
                                        borderRadius: 0,
                                        padding: '12px 16px',
                                        transition: '0.25s all',
                                        backgroundColor: 'transparent',
                                        color: props.isSelected ? 'var(--color-primary)' : 'var(--color-black)',
                                        borderBottom: '1px solid var(--color-gray-100)',
                                        cursor: 'pointer',
                                        ':active': {
                                            backgroundColor: 'transparent'
                                        },
                                        ':first-of-type': {
                                            marginTop: 0,
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8
                                        },
                                        ':last-of-type': {
                                            borderBottomLeftRadius: 8,
                                            borderBottomRightRadius: 8
                                        },
                                        '&:focus': {
                                            backgroundColor: props.isSelected ? 'var(--color-primary)' : 'var(--color-primary-8)',
                                            color: props.isSelected ? 'var(--color-white)' : 'var(--color-primary)'
                                        },
                                        '&:hover': {
                                            backgroundColor: 'var(--color-primary-8)',
                                            color: 'var(--color-primary)'
                                        },

                                    }),
                                    valueContainer: (styles, props) => ({
                                        ...styles,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexWrap: 'nowrap',
                                        gap: '4px'
                                    }),
                                    placeholder: (styles, props) => ({
                                        ...styles,
                                        color: "black",
                                        fontWeight: 500
                                    }),
                                }}
                                className="col"
                                menuPlacement="bottom"
                                isClearable={false}
                                isSearchable={false}
                                placeholder={t('maintenance.tires')}
                                options={tiresLabels}
                                onChange={(val: {value: number, label: string}) => { }}
                                selectedIconStyles={{color: "var(--color-primary)"}}
                                iconPlaceholderNeed={true}
                                closeMenuOnSelect={false}
                            />
                            <CustomSelect
                                components={{
                                    Menu: params =>
                                        <components.Menu {...params} className={'w-fit'}>
                                            {params.children}
                                            <button
                                                className={`flex items-center justify-center h-11 w-[226px] cursor-pointer rounded-md ${params.selectProps.value === null ? 'bg-gray-100 text-gray-test-350' : 'bg-primary text-white'} font-medium text-[13px] m-3`}
                                                disabled={params.selectProps.value === null}
                                                onClick={() => changeWashedForAll(params.selectProps.value)}
                                            >
                                                {t('maintenance.do_for_chosen')}
                                            </button>
                                        </components.Menu>

                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                    ...colourStyles(false),
                                    control: (styles, props) => ({
                                        ...styles,
                                        background: 'var(--color-gray-test-700)',
                                        borderRadius: '6px',
                                        border: 'none',
                                        boxShadow: "none"
                                    }),
                                    singleValue: (styles, props) => ({
                                        ...styles,
                                        textOverflow: 'ellipsis',
                                        overflow: "visible",
                                        fontWeight: 500,

                                    }),
                                    dropdownIndicator: (styles, props) => ({
                                        ...styles,
                                        color: 'var(--color-test-black)',
                                        cursor: 'pointer'
                                    }),
                                    option: (styles, props) => ({
                                        ...styles,
                                        background: "transparent",
                                        fontWeight: 400,
                                        borderRadius: 0,
                                        padding: '12px 16px',
                                        transition: '0.25s all',
                                        backgroundColor: 'transparent',
                                        color: props.isSelected ? 'var(--color-primary)' : 'var(--color-black)',
                                        borderBottom: '1px solid var(--color-gray-100)',
                                        cursor: 'pointer',
                                        ':active': {
                                            backgroundColor: 'transparent'
                                        },
                                        ':first-of-type': {
                                            marginTop: 0,
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8
                                        },
                                        ':last-of-type': {
                                            borderBottomLeftRadius: 8,
                                            borderBottomRightRadius: 8
                                        },
                                        '&:focus': {
                                            backgroundColor: props.isSelected ? 'var(--color-primary)' : 'var(--color-primary-8)',
                                            color: props.isSelected ? 'var(--color-white)' : 'var(--color-primary)'
                                        },
                                        '&:hover': {
                                            backgroundColor: 'var(--color-primary-8)',
                                            color: 'var(--color-primary)'
                                        },

                                    }),
                                    valueContainer: (styles, props) => ({
                                        ...styles,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexWrap: 'nowrap',
                                        gap: '4px'
                                    }),
                                    placeholder: (styles, props) => ({
                                        ...styles,
                                        color: "black",
                                        fontWeight: 500
                                    }),
                                }}
                                className="col"
                                menuPlacement="bottom"
                                isClearable={false}
                                isSearchable={false}
                                placeholder={t('maintenance.washing')}
                                options={washedLabels}
                                onChange={(val: {value: number, label: string}) => { }}
                                selectedIconStyles={{color: "var(--color-primary)"}}
                                iconPlaceholderNeed={true}
                                closeMenuOnSelect={false}
                            />
                            {/*<span className={'flex items-center justify-center h-10 w-28 rounded-md gap-2 bg-gray-test-700 font-medium'}>*/}
                            {/*<Icon icon={'add'}/>*/}
                            {/*Новое ТО*/}
                            {/*</span>*/}
                        </div>
                    </div>
                ))}
                rowSelection={rowSelection}
                rowKey={obj => obj.id}
                onRow={group => ({
                    onClick: () => {
                        if (selectedRowKeys.length > 0) {
                            setSelectedRowKeys(prev => prev.includes(group.id) ? prev.filter(k => k !== group.id) : [...prev, group.id])
                            return
                        }
                    }
                })}
                dataSource={list.results}
                columns={columns}
                onHistory={(group) => openMaintanenceHistory(group)}
            />

            <PaginationFormComponent count={list.count} />
        </FormProvider>
    )
};

