import {BehaviorSubject} from 'rxjs'

export enum SubscriptionModalType {
  SALE = "sale",
  STAFF = "staff",
  METRICS = "metrics",
  RENTAL_POINT = "rental_point",
  INVENTORY_SET = "inventory_set",
  INVENTORY_GROUP = "inventory_group",
  CUSTOM_FIELDS = "custom_fields",
  DOCUMENT = "document",
  BONUS = "bonus"
}

export interface SubscriptionModalConfig {
  show: boolean,
  type: SubscriptionModalType | null
}

export class SubscriptionModalService {
  show$: BehaviorSubject<SubscriptionModalConfig> = new BehaviorSubject<SubscriptionModalConfig>({show: false, type: null})
  setShow = (payload: SubscriptionModalConfig) => this.show$.next(payload)
}

const subscriptionModalService = new SubscriptionModalService()
export default subscriptionModalService
