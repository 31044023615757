import {BehaviorSubject} from "rxjs"
import {TokenModel} from './auth.service'

class TokenService {
  token$: BehaviorSubject<TokenModel> = new BehaviorSubject<TokenModel>(this.getToken)

  get hasToken() {
    return !!localStorage.getItem('token')
  }

  get getToken(): TokenModel {
    return JSON.parse(localStorage.getItem('token'))
  }

  get getAccess() {
    const auth: TokenModel = JSON.parse(localStorage.getItem('token'))
    return auth?.access
  }

  get getRefresh() {
    const auth: TokenModel = JSON.parse(localStorage.getItem('token'))
    return auth?.refresh
  }

  updateAccess(token: string) {
    let auth: TokenModel = JSON.parse(localStorage.getItem('token'))
    auth.access = token
    this.setToken(auth)
  }

  setToken(auth: TokenModel) {
    this.token$.next(auth)
    localStorage.setItem('token', JSON.stringify(auth))
  }

  removeToken() {
    this.token$.next(null)
    localStorage.removeItem('token')
  }
}

const tokenService = new TokenService()
export default tokenService
