import {useTranslation} from 'react-i18next'
import {ImageField} from "src/components/shared/image-loader/image"
import {IntegrationModel} from "src/models/integration"
import {IntegrationConnection} from "../connection.modal"
import modalService from "src/components/modal/global/modal.service"
import {Tooltip} from "antd"
import Icon from "src/components/shared/components/material-icon"
import integrationService from "src/services/integration"
import {ConfirmModal} from "src/components/modal/global/confirmModal"

interface IntegrationLandingParams {
    integration: IntegrationModel
    children: JSX.Element | JSX.Element[]
    company_content?: JSX.Element | JSX.Element[]
}

export function IntegrationLandingWrapper({integration, children, company_content}: IntegrationLandingParams) {
    const {t} = useTranslation()

    const onConnect = () => {
        modalService.open({component: <IntegrationConnection integration={integration} />})
    }

    const onDisconnect = () => {
        const onConfirm = () => integrationService.disconnect(integration.code).then(() => { })

        modalService.open({
            component: <ConfirmModal
                message={t('common.disconnect')}
                onConfirm={onConfirm}
                delete={true}
            />
        })
    }

    return integration && (
        <div className="flex gap-4 items-start">
            <div className="flex gap-4 flex-col flex-1">
                <div className="font-bold text-2xl">{integration.name}</div>
                {children}
            </div>
            <div className="flex flex-col gap-3 items-start card-bio bg-white w-80">
                <ImageField
                    src={integration.icon}
                    className="w-full h-full rounded-xl object-contain"
                />

                {integration.connected ? (
                    <div className="btn-group w-full">
                        <div className="btn btn-green-8 gap-1 text-[13px] btn-color-green fw-semibold w-full">
                            <Icon icon="check" />
                            {t('common.connected')}
                        </div>
                        <Tooltip placement="top" title="Отключить">
                            <button className="btn btn-red-8 btn-color-red" onClick={onDisconnect}>
                                <Icon icon="link_off" />
                            </button>
                        </Tooltip>
                    </div>
                ) : (
                    <button
                        onClick={onConnect}
                        disabled={integration.connected}
                        className="btn btn-primary btn-color-white fw-semibold w-full"
                    >
                        {t('common.connect')}
                    </button>
                )}

                {company_content}
            </div>
        </div>
    )
}