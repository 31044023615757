import {lazy} from 'react'
import {Navigate, RouteObject} from 'react-router-dom'
import CustomSuspense from "src/components/custom-suspense"
import WebsiteArticles from "src/pages/website/artices/list"
import WebsiteEvents from "src/pages/website/events/list"
import WebsiteProductPublished from "src/pages/website/product-published/list"
import WebsiteProductOrder from "src/pages/website/product-order"
const WebsiteWrapper = lazy(() => import("src/pages/website"))

export const websiteRoutes: RouteObject[] = [
  {
    path: 'website',
    element: <CustomSuspense><WebsiteWrapper /></CustomSuspense>,
    children: [
      {path: '', element: <Navigate to="articles" replace />},
      {path: 'articles', element: <WebsiteArticles />},
      {path: 'product-published', element: <WebsiteProductPublished />},
      {path: 'events', element: <WebsiteEvents />},
      {path: 'product-order', element: <WebsiteProductOrder />}
    ]
  }
]
