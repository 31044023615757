import {useContext, useEffect, useMemo, useState} from 'react'
import {OrderContext} from '../../context/order-context'
import OrderDetailInventoryListComponent from "./inventory-add"
import {useTranslation} from 'react-i18next'
import SharedInventoryGroupListComponent from "src/components/shared/components/group"
import settingService from "src/services/settings.service"
import {Segmented} from "src/components/shared/antd-custom"
import {map} from "rxjs"
import {ConfigContext} from "../../../../../index"
import Icon from "../../../../../components/shared/components/material-icon"
import {useNavigate} from "react-router-dom"
import HeaderContent from "../../../../../components/header/header-content"

type Section = 'inventory' | 'group' | 'sale'

export default function OrderInventoryAddList() {
  const [page, setPage] = useState<Section>(localStorage.getItem('main_section') as Section || 'group')
  const [allowedPages, setAllowedPages] = useState<Section[]>([])
  const {order, orderForm, inventoriesIds, onSelect, onGroup} = useContext(OrderContext)
  const {t} = useTranslation()
  const {isMobile, hideMobileMenu, showMobileMenu} = useContext(ConfigContext)
  const navigate = useNavigate()

  const pages: Record<Section, string> = {
    group: t('orders.sections.group'),
    sale: t('orders.sections.sales'),
    inventory: t('orders.sections.inventory')
  }

  const params = useMemo(() => ({
    rental_point: order.rental_point,
    start_at: orderForm.watch('rent_start'),
    end_at: orderForm.watch('rent_end'),
    exclude_ids: inventoriesIds.join(','),

  }), [inventoriesIds, order.rental_point, orderForm])

  useEffect(() => {
    const sub = settingService.config$.pipe(map(obj => obj.rent_product_types)).subscribe(setAllowedPages)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])

  return <div className={isMobile && '-m-4'}>
    {isMobile &&
      <HeaderContent>
        <div className={' gap-1 py-5 header-content border-0'}>
          <Icon icon={'chevron_left'} className={'text-2xl'} onClick={() => navigate(-1)} />
          <p className={'font-bold text-xl text-test-black'}>Продукты</p>
        </div>
      </HeaderContent>
    }
    <Segmented
      value={page}
      onChange={(option: Section) => {
        setPage(option)
        localStorage.setItem('main_section', option)
      }}
      className={`self-center rounded-md ${isMobile ? 'bg-white h-full w-full px-4 py-3 overflow-x-visible' : 'bg-gray-100 mb-3 first-of-type:'}`}
      style={{background: 'var(--color-bg-light)', padding: 4, borderRadius: 6}}
      options={(allowedPages.length === 0 ? ['group', 'sale', 'inventory'] : allowedPages).map((id: Section) => ({
        value: id,
        label: pages[id],
        className: isMobile ?
          `${id === page ? 'bg-test-black text-white ' : 'bg-gray-test-70 text-test-black'} flex justify-center items-center first-of-type:mr-2  last-of-type:ml-2  min-h-10 text-[13px] font-medium rounded-[32px]`
          :
          "p-1 font-medium text-black"
      }))}
    />

    {page === 'group' && <SharedInventoryGroupListComponent params={{...params, type: 0}} onSelect={onGroup} />}
    {page === 'sale' && <SharedInventoryGroupListComponent params={{...params, type: 1}} onSelect={onGroup} />}
    {page === 'inventory' && <OrderDetailInventoryListComponent filter={params} onSelect={onSelect} />}

  </div>
}
