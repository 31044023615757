import {plugins} from "chart.js/dist/core"
import {EditorConfig} from "ckeditor5-custom-build/build/ckeditor"
import {CustomFieldModel} from "src/models/setting-config"
import i18n from "i18next";

const colors = [
    {color: "#000000", label: "Black"},
    {color: "#4d4d4d", label: "Dim grey"},
    {color: "#999999", label: "Grey"},
    {color: "#e6e6e6", label: "Light grey"},
    {color: "#ffffff", label: "White", hasBorder: true},
    {color: "#e64c4c", label: "Red"},
    {color: "#e6994c", label: "Orange"},
    {color: "#e6e64c", label: "Yellow"},
    {color: "#99e64c", label: "Light green"},
    {color: "#4ce64c", label: "Green"},
    {color: "#4ce699", label: "Aquamarine"},
    {color: "#4ce6e6", label: "Turquoise"},
    {color: "#4c99e6", label: "Light blue"},
    {color: "#4c4ce6", label: "Blue"},
    {color: "#994ce6", label: "Purple"},
]

export const pageSizes = {
    "297x420": {name: "A3", margin: "2em"},
    "210x297": {name: "A4", margin: "2em"},
    "148x210": {name: "A5", margin: "2em"},
    "105x148": {name: "A6", margin: "1em"},
    "80x120": {name: i18n.t('configuration.document.check')+" (lg)", margin: "0em"},
    "60x120": {name: i18n.t('configuration.document.check')+" (md)", margin: "0em"},
}

export const placeholderRegex = /<span class="placeholder" data="([^"]+)">([^<]+)<\/span>/g

export const placeholderKeys = {};

i18n.on('loaded', () => {
    Object.assign(placeholderKeys, {
        "date": i18n.t('configuration.document.general.date'),
        "datetime": i18n.t('configuration.document.general.datetime'),
        "time": i18n.t('configuration.document.general.time'),
        "user": i18n.t('configuration.document.general.user'),
        "proxy_user": i18n.t('configuration.document.general.proxy_user'),
        "proxy_num": i18n.t('configuration.document.general.proxy_num'),
        "proxy_date": i18n.t('configuration.document.general.proxy_date'),
        "rent_id": i18n.t('configuration.document.rent.rent_id'),
        "rent_unique_id": i18n.t('configuration.document.rent.rent_unique_id'),
        "rent_start": i18n.t('configuration.document.rent.rent_start'),
        "rent_start_fact": i18n.t('configuration.document.rent.rent_start_fact'),
        "rent_end": i18n.t('configuration.document.rent.rent_end'),
        "rent_end_fact": i18n.t('configuration.document.rent.rent_end_fact'),
        "rent_price": i18n.t('configuration.document.rent.rent_price'),
        "rent_price_str": i18n.t('configuration.document.rent.rent_price_str'),
        "rent_price_discount":i18n.t('configuration.document.rent.rent_price_discount'),
        "rent_price_discount_str": i18n.t('configuration.document.rent.rent_price_discount_str'),
        "rent_paid_amount": i18n.t('configuration.document.rent.rent_paid_amount'),
        "rent_paid_amount_str": i18n.t('configuration.document.rent.rent_paid_amount_str'),
        "rent_price_inv": i18n.t('configuration.document.rent.rent_price_inv'),
        "rent_price_inv_str": i18n.t('configuration.document.rent.rent_price_inv_str'),
        "rent_price_service": i18n.t('configuration.document.rent.rent_price_service'),
        "rent_price_service_str": i18n.t('configuration.document.rent.rent_price_service_str'),
        "rent_price_delivery": i18n.t('configuration.document.rent.rent_price_delivery'),
        "rent_price_delivery_str": i18n.t('configuration.document.rent.rent_price_delivery_str'),
        "rent_price_daily": i18n.t('configuration.document.rent.rent_price_daily'),
        "rent_price_daily_str": i18n.t('configuration.document.rent.rent_price_daily_str'),
        "rent_discount": i18n.t('configuration.document.rent.rent_discount'),
        "rent_discount_str": i18n.t('configuration.document.rent.rent_discount_str'),
        "rent_discount_inv": i18n.t('configuration.document.rent.rent_discount_inv'),
        "rent_discount_inv_str": i18n.t('configuration.document.rent.rent_discount_inv_str'),
        "rent_discount_service": i18n.t('configuration.document.rent.rent_discount_service'),
        "rent_discount_service_str": i18n.t('configuration.document.rent.rent_discount_service_str'),
        "rent_penalty": i18n.t('configuration.document.rent.rent_penalty'),
        "rent_penalty_str": i18n.t('configuration.document.rent.rent_penalty_str'),
        "rent_created_by": i18n.t('configuration.document.rent.rent_created_by'),
        "rent_created_at": i18n.t('configuration.document.rent.rent_created_at'),
        "rent_reserved_at": i18n.t('configuration.document.rent.rent_reserved_at'),
        "cnt_inventories": i18n.t('configuration.document.rent.cnt_inventories'),
        "cnt_services": i18n.t('configuration.document.rent.cnt_services'),
        "sum_inventory_buy_brice": i18n.t('configuration.document.rent.sum_inventory_buy_brice'),
        "sum_inventory_buy_brice_str": i18n.t('configuration.document.rent.sum_inventory_buy_brice_str'),
        "client_id": i18n.t('configuration.document.client.client_id'),
        "client_name": i18n.t('configuration.document.client.client_name'),
        "client_phone": i18n.t('configuration.document.client.client_phone'),
        "client_email": i18n.t('configuration.document.client.client_email'),
        "client_agreement_id": i18n.t('configuration.document.client.client_agreement_id'),
        "client_sign_date": i18n.t('configuration.document.client.client_sign_date'),
        "client_discount": i18n.t('configuration.document.client.client_discount'),
        "client_type": i18n.t('configuration.document.client.client_type'),
        "client_ind_iin": i18n.t('configuration.document.client.ind.client_ind_iin'),
        "client_ind_num": i18n.t('configuration.document.client.ind.client_ind_num'),
        "client_ind_date": i18n.t('configuration.document.client.ind.client_ind_date'),
        "client_ind_date_end": i18n.t('configuration.document.client.ind.client_ind_date_end'),
        "client_ind_birth_date": i18n.t('configuration.document.client.ind.client_ind_birth_date'),
        "client_ind_issuer": i18n.t('configuration.document.client.ind.client_ind_issuer'),
        "client_leg_bin": i18n.t('configuration.document.client.leg.client_leg_bin'),
        "client_leg_address": i18n.t('configuration.document.client.leg.client_leg_address'),
        "client_leg_director": i18n.t('configuration.document.client.leg.client_leg_director'),
        "client_leg_iban": i18n.t('configuration.document.client.leg.client_leg_iban'),
        "client_leg_bik": i18n.t('configuration.document.client.leg.client_leg_bik'),
        "client_leg_bank": i18n.t('configuration.document.client.leg.client_leg_bank'),
        "inv_index": i18n.t('configuration.document.inventory.inv_index'),
        "inv_id": i18n.t('configuration.document.inventory.inv_id'),
        "inv_name": i18n.t('configuration.document.inventory.inv_name'),
        "inv_unique_id": i18n.t('configuration.document.inventory.inv_unique_id'),
        "inv_duration": i18n.t('configuration.document.inventory.inv_duration'),
        "inv_duration_fact": i18n.t('configuration.document.inventory.inv_duration_fact'),
        "inv_discount": i18n.t('configuration.document.inventory.inv_discount'),
        "inv_discount_str": i18n.t('configuration.document.inventory.inv_discount_str'),
        "inv_start": i18n.t('configuration.document.inventory.inv_start'),
        "inv_start_fact": i18n.t('configuration.document.inventory.inv_start_fact'),
        "inv_end": i18n.t('configuration.document.inventory.inv_end'),
        "inv_end_fact": i18n.t('configuration.document.inventory.inv_end_fact'),
        "inv_price": i18n.t('configuration.document.inventory.inv_price'),
        "inv_price_str": i18n.t('configuration.document.inventory.inv_price_str'),
        "inv_price_discount": i18n.t('configuration.document.inventory.inv_price_discount'),
        "inv_price_discount_str": i18n.t('configuration.document.inventory.inv_price_discount_str'),
        "inv_penalty": i18n.t('configuration.document.inventory.inv_penalty'),
        "inv_penalty_str": i18n.t('configuration.document.inventory.inv_penalty_str'),
        "inv_inventory_category": i18n.t('configuration.document.inventory.inv_inventory_category'),
        "inv_inventory_buy_price": i18n.t('configuration.document.inventory.inv_inventory_buy_price'),
        "inv_inventory_buy_price_str": i18n.t('configuration.document.inventory.inv_inventory_buy_price_str'),
        "inv_inventory_buy_date": i18n.t('configuration.document.inventory.inv_inventory_buy_date'),
        "inv_inventory_point_name": i18n.t('configuration.document.inventory.inv_inventory_point_name'),
        "inv_inventory_point_address": i18n.t('configuration.document.inventory.inv_inventory_point_address'),
        "service_index": i18n.t('configuration.document.services.service_index'),
        "service_id": i18n.t('configuration.document.services.service_id'),
        "service_name": i18n.t('configuration.document.services.service_name'),
        "service_price": i18n.t('configuration.document.services.service_price'),
        "service_price_str": i18n.t('configuration.document.services.service_price_str'),
        "service_price_discount": i18n.t('configuration.document.services.service_price_discount'),
        "service_price_discount_str": i18n.t('configuration.document.services.service_price_discount_str'),
        "service_extra_delivery_address": i18n.t('configuration.document.services.service_extra_delivery_address'),
        "service_info": i18n.t('configuration.document.services.service_info'),
        "service_tarif_price": i18n.t('configuration.document.services.service_tarif_price'),
        "service_tarif_price_str": i18n.t('configuration.document.services.service_tarif_price_str'),
        "service_tarif_duration":i18n.t('configuration.document.services.service_tarif_duration'),
        "service_worker": i18n.t('configuration.document.services.service_worker'),
    });
});

export const editorPlaceholder = (config: CustomFieldModel) => {
    return [
        {
            key: 'user',
            label: i18n.t('configuration.document.labels.general'),
            type: 'group',
            children: [
                {label: placeholderKeys['user'], key: 'user'},
                {label: placeholderKeys['proxy_user'], key: 'proxy_user'},
                {label: placeholderKeys['proxy_num'], key: 'proxy_num'},
                {label: placeholderKeys['proxy_date'], key: 'proxy_date'},
                {label: placeholderKeys['date'], key: 'date'},
                {label: placeholderKeys['datetime'], key: 'datetime'},
                {label: placeholderKeys['time'], key: 'time'},
            ],
        },
        {
            key: 'rent',
            label: i18n.t('configuration.document.labels.rent'),
            children: [
                {label: placeholderKeys['rent_id'], key: 'rent_id'},
                {label: placeholderKeys['rent_unique_id'], key: 'rent_unique_id'},
                {label: placeholderKeys['rent_start'], key: 'rent_start'},
                {label: placeholderKeys['rent_start_fact'], key: 'rent_start_fact'},
                {label: placeholderKeys['rent_end'], key: 'rent_end'},
                {label: placeholderKeys['rent_end_fact'], key: 'rent_end_fact'},
                {label: placeholderKeys['rent_price'], key: 'rent_price'},
                {label: placeholderKeys['rent_price_str'], key: 'rent_price_str'},
                {label: placeholderKeys['rent_price_discount'], key: 'rent_price_discount'},
                {label: placeholderKeys['rent_price_discount_str'], key: 'rent_price_discount_str'},
                {label: placeholderKeys['rent_price_inv'], key: 'rent_price_inv'},
                {label: placeholderKeys['rent_price_inv_str'], key: 'rent_price_inv_str'},
                {label: placeholderKeys['rent_price_service'], key: 'rent_price_service'},
                {label: placeholderKeys['rent_price_service_str'], key: 'rent_price_service_str'},
                {label: placeholderKeys['rent_price_delivery'], key: 'rent_price_delivery'},
                {label: placeholderKeys['rent_price_delivery_str'], key: 'rent_price_delivery_str'},
                {label: placeholderKeys['rent_price_daily'], key: 'rent_price_daily'},
                {label: placeholderKeys['rent_price_daily_str'], key: 'rent_price_daily_str'},
                {label: placeholderKeys['rent_paid_amount'], key: 'rent_paid_amount'},
                {label: placeholderKeys['rent_paid_amount_str'], key: 'rent_paid_amount_str'},
                {label: placeholderKeys['rent_discount'], key: 'rent_discount'},
                {label: placeholderKeys['rent_discount_str'], key: 'rent_discount_str'},
                {label: placeholderKeys['rent_discount_inv'], key: 'rent_discount_inv'},
                {label: placeholderKeys['rent_discount_inv_str'], key: 'rent_discount_inv_str'},
                {label: placeholderKeys['rent_discount_service'], key: 'rent_discount_service'},
                {label: placeholderKeys['rent_discount_service_str'], key: 'rent_discount_service_str'},
                {label: placeholderKeys['rent_penalty'], key: 'rent_penalty'},
                {label: placeholderKeys['rent_penalty_str'], key: 'rent_penalty_str'},
                {label: placeholderKeys['rent_created_by'], key: 'rent_created_by'},
                {label: placeholderKeys['rent_created_at'], key: 'rent_created_at'},
                {label: placeholderKeys['rent_reserved_at'], key: 'rent_reserved_at'},
                {label: placeholderKeys['cnt_inventories'], key: 'cnt_inventories'},
                {label: placeholderKeys['cnt_services'], key: 'cnt_services'},
                {label: placeholderKeys['sum_inventory_buy_brice'], key: 'sum_inventory_buy_brice'},
                {label: placeholderKeys['sum_inventory_buy_brice_str'], key: 'sum_inventory_buy_brice_str'},

                ...config.order.map(field => ({label: field.label, key: field.name}))
            ],
        },
        {
            key: 'client',
            label: i18n.t('configuration.document.labels.client'),
            children: [
                {label: placeholderKeys['client_id'], key: 'client_id'},
                {label: placeholderKeys['client_name'], key: 'client_name'},
                {label: placeholderKeys['client_phone'], key: 'client_phone'},
                {label: placeholderKeys['client_email'], key: 'client_email'},
                {label: placeholderKeys['client_agreement_id'], key: 'client_agreement_id'},
                {label: placeholderKeys['client_sign_date'], key: 'client_sign_date'},
                {label: placeholderKeys['client_discount'], key: 'client_discount'},
                {label: placeholderKeys['client_type'], key: 'client_type'},
                ...config.client.map(field => ({label: field.label, key: field.name})),
                {
                    key: 'ind',
                    label: i18n.t('configuration.document.client.ind.title'),
                    children: [
                        {label: placeholderKeys['client_ind_iin'], key: 'client_ind_iin'},
                        {label: placeholderKeys['client_ind_num'], key: 'client_ind_num'},
                        {label: placeholderKeys['client_ind_date'], key: 'client_ind_date'},
                        {label: placeholderKeys['client_ind_date_end'], key: 'client_ind_date_end'},
                        {label: placeholderKeys['client_ind_birth_date'], key: 'client_ind_birth_date'},
                        {label: placeholderKeys['client_ind_issuer'], key: 'client_ind_issuer'},
                    ],
                },
                {
                    key: 'leg',
                    label: i18n.t('configuration.document.client.leg.title'),
                    children: [
                        {label: placeholderKeys['client_leg_bin'], key: 'client_leg_bin'},
                        {label: placeholderKeys['client_leg_address'], key: 'client_leg_address'},
                        {label: placeholderKeys['client_leg_director'], key: 'client_leg_director'},
                        {label: placeholderKeys['client_leg_iban'], key: 'client_leg_iban'},
                        {label: placeholderKeys['client_leg_bik'], key: 'client_leg_bik'},
                        {label: placeholderKeys['client_leg_bank'], key: 'client_leg_bank'},
                    ],
                },
            ],
        },
        {
            key: 'inventory',
            label: i18n.t('configuration.document.labels.inventory'),
            children: [
                {label: placeholderKeys['inv_index'], key: 'inv_index', tag: 'table'},
                {label: placeholderKeys['inv_id'], key: 'inv_id', tag: 'table'},
                {label: placeholderKeys['inv_name'], key: 'inv_name', tag: 'table'},
                {label: placeholderKeys['inv_unique_id'], key: 'inv_unique_id', tag: 'table'},
                {label: placeholderKeys['inv_duration'], key: 'inv_duration', tag: 'table'},
                {label: placeholderKeys['inv_duration_fact'], key: 'inv_duration_fact', tag: 'table'},
                {label: placeholderKeys['inv_discount'], key: 'inv_discount', tag: 'table'},
                {label: placeholderKeys['inv_discount_str'], key: 'inv_discount_str', tag: 'table'},
                {label: placeholderKeys['inv_start'], key: 'inv_start', tag: 'table'},
                {label: placeholderKeys['inv_start_fact'], key: 'inv_start_fact', tag: 'table'},
                {label: placeholderKeys['inv_end'], key: 'inv_end', tag: 'table'},
                {label: placeholderKeys['inv_end_fact'], key: 'inv_end_fact', tag: 'table'},
                {label: placeholderKeys['inv_price'], key: 'inv_price', tag: 'table'},
                {label: placeholderKeys['inv_price_discount'], key: 'inv_price_discount', tag: 'table'},
                {label: placeholderKeys['inv_penalty'], key: 'inv_penalty', tag: 'table'},
                {label: placeholderKeys['inv_inventory_category'], key: 'inv_inventory_category', tag: 'table'},
                {label: placeholderKeys['inv_inventory_buy_price'], key: 'inv_inventory_buy_price', tag: 'table'},
                {label: placeholderKeys['inv_inventory_buy_date'], key: 'inv_inventory_buy_date', tag: 'table'},
                {label: placeholderKeys['inv_inventory_point_name'], key: 'inv_inventory_point_name', tag: 'table'},
                {label: placeholderKeys['inv_inventory_point_address'], key: 'inv_inventory_point_address', tag: 'table'},
                ...config.inventory.map(field => ({label: field.label, key: `inv_${field.name}`, tag: 'table'})),
                ...config.inventory_group.map(field => ({label: field.label, key: `inv_group_${field.name}`, tag: 'table'})),
            ],
        },
        {
            key: 'service',
            label: i18n.t('configuration.document.labels.services'),
            children: [
                {label: placeholderKeys['service_index'], key: 'service_index', tag: 'table'},
                {label: placeholderKeys['service_id'], key: 'service_id', tag: 'table'},
                {label: placeholderKeys['service_name'], key: 'service_name', tag: 'table'},
                {label: placeholderKeys['service_price'], key: 'service_price', tag: 'table'},
                {label: placeholderKeys['service_price_discount'], key: 'service_price_discount', tag: 'table'},
                {label: placeholderKeys['service_extra_delivery_address'], key: 'service_extra_delivery_address', tag: 'table'},
                {label: placeholderKeys['service_info'], key: 'service_info', tag: 'table'},
                {label: placeholderKeys['service_tarif_price'], key: 'service_tarif_price', tag: 'table'},
                {label: placeholderKeys['service_tarif_duration'], key: 'service_tarif_duration', tag: 'table'},
                {label: placeholderKeys['service_worker'], key: 'service_worker', tag: 'table'},
            ],
        },
    ]
}

export const config: EditorConfig = {
    fontColor: {colors},
    toolbar: {
        items: [
            "sourceEditing",
            "heading",
            "alignment",
            "fontFamily",
            "fontSize",
            "lineHeight",
            "highlight",
            "fontColor",
            "fontBackgroundColor",
            "-",
            "style",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "link",
            "bulletedList",
            "numberedList",
            "superscript",
            "subscript",
            "|",
            "outdent",
            "indent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "specialCharacters",
            "removeFormat",
            "htmlEmbed",
            "showBlocks",
            "selectAll",
            "pageBreak",
            "undo",
            "redo",
        ],
    },
    style: {
        definitions: [
            {
                name: i18n.t('configuration.document.styles.no_indentation'),
                element: 'p',
                classes: ['m-0', 'p-0']
            },
        ]
    },
    fontBackgroundColor: {
        colors: colors,
        columns: 5,
    },
    fontSize: {
        options: ["8px", "10px", "11px", "12px", "14px", "16px", "18px", "21px", "24px", "32px", "40px"],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
        tableCellProperties: {
            borderColors: colors,
            backgroundColors: colors,
            defaultProperties: {
                horizontalAlignment: 'center',
                verticalAlignment: 'center',
                padding: '4px'
            }
        },
        tableProperties: {
            borderColors: colors,
            backgroundColors: colors,
            defaultProperties: {
                alignment: 'left',
                borderCollapse: "collapse",
                width: '100%',
            }
        },
        defaultHeadings: {
            rows: 0,
            columns: 0,
        },
    },
    heading: {
        options: [
            {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
            {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
            {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
            {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
            {model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
            {model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'},
            {model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6'}
        ]
    },
    lineHeight: {
        options: [0.3, 0.5, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.5, 2, 2.5, 3]
    },
    placeholderKeys
}

export const baseConfig: EditorConfig = (lang: string = 'ru') => ({
    language: lang,
    fontColor: {colors},
    toolbar: {
        items: [
            "sourceEditing",
            "heading",
            "alignment",
            "fontFamily",
            "fontSize",
            "lineHeight",
            "highlight",
            "fontColor",
            "fontBackgroundColor",
            "-",
            "style",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "link",
            "bulletedList",
            "numberedList",
            "superscript",
            "subscript",
            "|",
            "outdent",
            "indent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "specialCharacters",
            "removeFormat",
            "pageBreak",
            "undo",
            "redo",
        ],
    },
})

export const getPrintStyles = (size: string = '210mm 297mm', margin: string = '2em') => `
    <style type="text/css">
    body {
        z-index: 100 !important;
        visibility: visible !important;
        position: relative !important;
        display: block !important;
        width: ${size.split(' ')[0]} !important;
        height: ${size.split(' ')[1]} !important;
        position: relative !important;
        padding: 0 !important;
        top: 0 !important;
        left: 0 !important;
        margin: auto !important;
        orphans: 0 !important;
        widows: 0!important;
        overflow: visible !important;
    }
    .ck-content .m-0 {
        margin: 0 !important;
    }
    .ck-content .m-0 {
        padding: 0 !important;
    }
    .ck-content .table > figcaption {
        display: table-caption;
        caption-side: top;
        word-break: break-word;
        text-align: center;
        color: var(--ck-color-table-caption-text);
        background-color: var(--ck-color-table-caption-background);
        padding: .6em;
        font-size: .75em;
        outline-offset: -1px;
    }
    .ck-content .table {
        margin: 4px auto;
        display: table;
    }
    .ck-content .table table {
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px double transparent;
    }
    .ck-content .table table td,
    .ck-content .table table th {
        min-width: 2em;
        padding: .4em;
        border: 1px solid transparent;
    }
    .ck-content .table table th {
        font-weight: bold;
        background: #0000000d;
    }
    .ck-content[dir="rtl"] .table th {
        text-align: right;
    }
    .ck-content[dir="ltr"] .table th {
        text-align: left;
    }
    .ck-content .table .ck-table-resized {
        width: 100% !important;
        table-layout: fixed;
    }
    .ck-content .table table {
        width: 100% !important;
        table-layout: fixed;
        overflow: hidden;
    }
    .ck-content .table td,
    .ck-content .table th {
        overflow-wrap: break-word;
        position: relative;
    }
    .ck-content .media {
        clear: both;
        margin: 0.9em 0;
        display: block;
        min-width: 15em;
    }
    .ck-content .todo-list {
        list-style: none;
    }
    .ck-content .todo-list li {
        margin-bottom: 5px;
    }
    .ck-content .todo-list li .todo-list {
        margin-top: 5px;
    }
    .ck-content .todo-list .todo-list__label > input {
        -webkit-appearance: none;
        display: inline-block;
        position: relative;
        width: var(--ck-todo-list-checkmark-size);
        height: var(--ck-todo-list-checkmark-size);
        vertical-align: middle;
        border: 0;
        left: -25px;
        margin-right: -15px;
        right: 0;
        margin-left: 0;
    }
    .ck-content .todo-list .todo-list__label > input::before {
        display: block;
        position: absolute;
        box-sizing: border-box;
        content: "";
        width: 100%;
        height: 100%;
        border: 1px solid #333333;
        border-radius: 2px;
        transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
    }
    .ck-content .todo-list .todo-list__label > input::after {
        display: block;
        position: absolute;
        box-sizing: content-box;
        pointer-events: none;
        content: "";
        left: calc( var(--ck-todo-list-checkmark-size) / 3 );
        top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
        width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
        height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
        border-style: solid;
        border-color: transparent;
        border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
        transform: rotate(45deg);
    }
    .ck-content .todo-list .todo-list__label > input[checked]::before {
        background: #26ab33;
        border-color: #26ab33;
    }
    .ck-content .todo-list .todo-list__label > input[checked]::after {
        border-color: #ffffff;
    }
    .ck-content .todo-list .todo-list__label .todo-list__label__description {
        vertical-align: middle;
    }
    .ck-content .image {
        display: table;
        clear: both;
        text-align: center;
        margin: 0.9em auto;
        min-width: 50px;
    }
    .ck-content .image img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        min-width: 100%;
    }
    .ck-content .image-inline {
        display: inline-flex;
        max-width: 100%;
        align-items: flex-start;
    }
    .ck-content .image-inline picture {
        display: flex;
    }
    .ck-content .image-inline picture,
    .ck-content .image-inline img {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 100%;
    }
    .ck-content .image.image_resized {
        max-width: 100%;
        display: block;
        box-sizing: border-box;
    }
    .ck-content .image.image_resized img {
        width: 100%;
    }
    .ck-content .image.image_resized > figcaption {
        display: block;
    }
    .ck-content .image > figcaption {
        display: table-caption;
        caption-side: bottom;
        word-break: break-word;
        color: var(--ck-color-image-caption-text);
        background-color: var(--ck-color-image-caption-background);
        padding: .6em;
        font-size: .75em;
        outline-offset: -1px;
    }
    .ck-content .marker-yellow {
        background-color: var(--ck-highlight-marker-yellow);
    }
    .ck-content .marker-green {
        background-color: var(--ck-highlight-marker-green);
    }
    .ck-content .marker-pink {
        background-color: var(--ck-highlight-marker-pink);
    }
    .ck-content .marker-blue {
        background-color: var(--ck-highlight-marker-blue);
    }
    .ck-content .pen-red {
        color: var(--ck-highlight-pen-red);
        background-color: transparent;
    }
    .ck-content .pen-green {
        color: var(--ck-highlight-pen-green);
        background-color: transparent;
    }
    .ck-content ol {
        list-style-type: decimal;
    }
    .ck-content ol ol {
        list-style-type: lower-latin;
    }
    .ck-content ol ol ol {
        list-style-type: lower-roman;
    }
    .ck-content ol ol ol ol {
        list-style-type: upper-latin;
    }
    .ck-content ol ol ol ol ol {
        list-style-type: upper-roman;
    }
    .ck-content ul {
        list-style-type: disc;
    }
    .ck-content ul ul {
        list-style-type: circle;
    }
    .ck-content ul ul ul {
        list-style-type: square;
    }
    .ck-content ul ul ul ul {
        list-style-type: square;
    }
    .ck-content .image-style-block-align-left,
    .ck-content .image-style-block-align-right {
        max-width: calc(100% - var(--ck-image-style-spacing));
    }
    .ck-content .image-style-align-left,
    .ck-content .image-style-align-right {
        clear: none;
    }
    .ck-content .image-style-side {
        float: right;
        margin-left: var(--ck-image-style-spacing);
        max-width: 50%;
    }
    .ck-content .image-style-align-left {
        float: left;
        margin-right: var(--ck-image-style-spacing);
    }
    .ck-content .image-style-align-center {
        margin-left: auto;
        margin-right: auto;
    }
    .ck-content .image-style-align-right {
        float: right;
        margin-left: var(--ck-image-style-spacing);
    }
    .ck-content .image-style-block-align-right {
        margin-right: 0;
        margin-left: auto;
    }
    .ck-content .image-style-block-align-left {
        margin-left: 0;
        margin-right: auto;
    }
    .ck-content p + .image-style-align-left,
    .ck-content p + .image-style-align-right,
    .ck-content p + .image-style-side {
        margin-top: 0;
    }
    .ck-content .image-inline.image-style-align-left,
    .ck-content .image-inline.image-style-align-right {
        margin-top: var(--ck-inline-image-style-spacing);
        margin-bottom: var(--ck-inline-image-style-spacing);
    }
    .ck-content .image-inline.image-style-align-left {
        margin-right: var(--ck-inline-image-style-spacing);
    }
    .ck-content .image-inline.image-style-align-right {
        margin-left: var(--ck-inline-image-style-spacing);
    }
    .ck-content code {
        background-color: #c7c7c74d;
        padding: .15em;
        border-radius: 2px;
    }
    .ck-content blockquote {
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        margin-right: 0;
        font-style: italic;
        border-left: solid 5px #cccccc;
    }
    .ck-content[dir="rtl"] blockquote {
        border-left: 0;
        border-right: solid 5px #cccccc;
    }
    .ck-content .text-tiny {
        font-size: .7em;
    }
    .ck-content .text-small {
        font-size: .85em;
    }
    .ck-content .text-big {
        font-size: 1.4em;
    }
    .ck-content .text-huge {
        font-size: 1.8em;
    }
    .ck-content .mention {
        background: var(--ck-color-mention-background);
        color: var(--ck-color-mention-text);
    }
    .ck-content hr {
        margin: 15px 0;
        height: 4px;
        background: #dedede;
        border: 0;
    }
    .ck-content pre {
        padding: 1em;
        color: #353535;
        background: #c7c7c74d;
        border: 1px solid #c4c4c4;
        border-radius: 2px;
        text-align: left;
        direction: ltr;
        tab-size: 4;
        white-space: pre-wrap;
        font-style: normal;
        min-width: 200px;
    }
    .ck-content pre code {
        background: unset;
        padding: 0;
        border-radius: 0;
    }
    @page {size:${size}; margin:${margin}; orphans:0!important;widows:0!important }
    </style>
`
