import {WorkshiftModel} from "src/services/workshift.service"
import toDateTime from "src/utils/date-time"
import {toPrice} from "src/utils/price"


interface WorkshiftReceiptConfig {
  workshift: WorkshiftModel
  type?: 'download' | 'print'
  className?: string
  paddingWidth?: number
}

function WorkshiftReceiptRow({name, value}: {name: string, value: JSX.Element | string | number}) {
  return <div className="flex justify-between gap-1 text-xs font-light">
    <span>{name}:</span>
    <span className="font-semibold text-nowrap">{value}</span></div>
}

export function WorkshiftReceipt({workshift, type = 'print', className = '', paddingWidth = 4}: WorkshiftReceiptConfig) {
  const padding = type === 'print' ? 0 : paddingWidth
  const paddingBottom = type === 'print' ? 64 : paddingWidth

  return <div id="capture" className={className} style={{fontFamily: 'Arial, Helvetica, Sans-Serif', width: 288, padding, paddingBottom}}>
    <div className="flex gap-2 flex-col mb-3">
      <WorkshiftReceiptRow name="Начало смены" value={workshift.start_at && toDateTime(workshift.start_at)} />
      <WorkshiftReceiptRow name="Конец смены" value={workshift.end_at && toDateTime(workshift.end_at)} />
      <WorkshiftReceiptRow name="Кассир" value={workshift.profile} />
      <WorkshiftReceiptRow name="Пункт проката" value={workshift.point} />
      <hr className="my-0" />
      <WorkshiftReceiptRow name="Количество созданных аренд" value={workshift.order_created_cnt} />
      <WorkshiftReceiptRow name="Сумма созданных аренд" value={toPrice(workshift.order_created_sum)} />
      <hr className="my-0" />
      <WorkshiftReceiptRow name="Количество выданных аренд" value={workshift.order_issued_cnt} />
      <WorkshiftReceiptRow name="Сумма выданных аренд" value={toPrice(workshift.order_issued_sum)} />
      <hr className="my-0" />
      <WorkshiftReceiptRow name="Количество принятых аренд" value={workshift.order_received_cnt} />
      <WorkshiftReceiptRow name="Сумма принятых аренд" value={toPrice(workshift.order_received_sum)} />
      <hr className="my-0" />
      <WorkshiftReceiptRow name="Количество отмененных аренд" value={workshift.order_cancelled_cnt} />
      <WorkshiftReceiptRow name="Сумма отмененных аренд" value={toPrice(workshift.order_cancelled_sum)} />
      <hr className="my-0" />
      <WorkshiftReceiptRow name="Количество оплат" value={workshift.payment_cnt} />
      <WorkshiftReceiptRow name="Сумма оплат" value={toPrice(workshift.payment_sum)} />
      <hr className="my-0" />
      {workshift.payments && workshift.payments.map((payment, i) => (
        <WorkshiftReceiptRow key={i} name={payment.name || 'Не выбрано'} value={<>{toPrice(payment.amount)} ({payment.count})</>} />
      ))}
    </div>
    <div className="text-center text-xs font-light">yume.cloud</div>
  </div>
}
