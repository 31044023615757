import moment from 'moment'
import {useTranslation} from 'react-i18next'
import i18n from "i18next"
const humanizeDuration = require('humanize-duration')

export const shortHumanizer = (lang: string) => humanizeDuration.humanizer({
  language: lang,
  largest: 3,
  round: true,
  delimiter: ' ',
  units: ["y", "mo", "d", "h", "m", "s"],
  languages: {
    ru: {
      y: () => i18n.t('common.times.year'),
      mo: () => i18n.t('common.times_abb.month'),
      d: () => i18n.t('common.times_abb.day'),
      h: () => i18n.t('common.times_abb.hour'),
      m: () => i18n.t('common.times_abb.minute'),
      s: () => i18n.t('common.times_abb.second'),
    },
    en: {
      y: () => "year",
      mo: () => "month",
      d: () => "day",
      h: () => "hour",
      m: () => "min",
      s: () => "sec",
    }
  },
})

export default function Duration({time, period = 'seconds'}: {time: any, period?: 'seconds' | 'milliseconds'}) {
  const {i18n, ready} = useTranslation()

  if (!ready) {
    return null
  }

  return shortHumanizer(i18n.language)(moment.duration(time, period))
}