import axios from 'axios'
import {CrudInterface} from 'src/interfaces/crud.interface'
import {ListModel} from 'src/models/common'
import {ApiAbstract} from 'src/abstract/api.abstract'
import {DetailOrderRequestServiceModel} from 'src/models/manager/order/order-request-service'

export class ManageOrderServicesService extends ApiAbstract implements CrudInterface<DetailOrderRequestServiceModel> {
  constructor() {
    super('v1/crm/requests')
  }

  public async list(orderId: number, params?: any): Promise<ListModel<DetailOrderRequestServiceModel>> {
    const response = await axios.get(this.getUrl(`${orderId}/services`), {params})
    return response.data
  }
  public async post(orderId: number, payload: any): Promise<DetailOrderRequestServiceModel> {
    const response = await axios.post(this.getUrl(`${orderId}/services`), payload)
    return response.data
  }
  public async patch(orderId: number, id: number, payload: any): Promise<DetailOrderRequestServiceModel> {
    const response = await axios.patch(this.getUrl(`${orderId}/services/${id}`), payload)
    return response.data
  }
  public async delete(orderId: number, id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${orderId}/services/${id}`))
    return response.data
  }

  // TODO
  public async get(id: number): Promise<any> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
}

const orderRequestServiceService = new ManageOrderServicesService()
export default orderRequestServiceService
