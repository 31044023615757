import {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useDebounce} from 'src/hooks/useDebounce'
import useQueryParams from 'src/hooks/useQuertParams'
import {ListParams} from 'src/models/common'
import {startOfMonth, endOfMonth} from 'src/components/shared/constants'
import {priceBeautify} from "src/utils/price"
import metricsService from "src/services/metrics/products-parts.service"
import {FormControlDaterange} from "src/components/shared/inputs/form-control-daterange"
import objectFilter from "src/utils/object.filter"
import {FormControl} from "src/components/shared/inputs/form-control"
import {MetricsClientBonusHistoryGeneral} from "src/models/manager/metrics/client-bonus"
import CardWrapper from "src/components/shared/components/card"
import {Outlet, useNavigate} from "react-router"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {LabelModel} from "src/models/label"
import {BehaviorSubject, firstValueFrom, map} from "rxjs"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {useTranslation} from 'react-i18next'
import {Segmented} from "src/components/shared/antd-custom"
import i18n from "i18next"

interface Filter extends ListParams {
    start_at: string
    end_at: string
}

const typeFilters = new BehaviorSubject<LabelModel[]>([
    {id: 0, label: i18n.t('metrics.bonuses.filter.outcome')},
    {id: 1, label: i18n.t('metrics.bonuses.filter.income')},
])

export default function ReportClientBonusWrapperComponent() {
    const navigate = useNavigate()
    const page = useCurrentRoute(4)
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useQueryParams()
    const [general, setGeneral] = useState<MetricsClientBonusHistoryGeneral>()
    const form = useForm<Filter>({
        defaultValues: {
            page: 1,
            pageSize: 10,
            start_at: startOfMonth.format('YYYY-MM-DD'),
            end_at: endOfMonth.format('YYYY-MM-DD'),
            search: '',
            ...searchParams
        }
    })
    const {watch, reset} = form
    const values = watch()

    const getGeneral = params => metricsService.listBonusGeneral(params).then(setGeneral)
    const searchDebounce = useDebounce(watch('search'), 500)

    useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

    useEffect(() => {
        getGeneral(form.watch())
        const sub = form.watch((params, {name}) => {
            if (name !== 'search') getGeneral(params)
            const query = objectFilter(params)
            setSearchParams(query)
        })
        return () => {
            sub.unsubscribe()
            setGeneral(undefined)
        }
    }, [])

    const sectionDataConfig = general ? [
        {
            values: [
                {
                    label: t('metrics.bonuses.card.sum'),
                    value: <div className="flex gap-2 flex-col">{priceBeautify(+general.sum)} Y</div>,
                    className: 'border-r border-gray-100 pr-5'
                },
                {
                    label: t('metrics.bonuses.card.delivered'),
                    value: <div className="flex gap-2 flex-col">{priceBeautify(+general.delivered)} Y</div>,
                    className: 'border-r border-gray-100 pr-5'
                },
                {
                    label: t('metrics.bonuses.card.received'),
                    value: <div className="flex gap-2 flex-col">{priceBeautify(+general.received)} Y</div>,
                },
            ],
        }
    ] : []

    return (
        <FormProvider {...form}>

            <div className="flex items-center mb-3">
                <FormControlDaterange className="form-control" name_start="start_at" name_end="end_at" />
            </div>

            <CardWrapper cardSectionData={sectionDataConfig} />

            <Outlet context={{
                title: (
                    <div className="flex gap-2 justify-end">
                        <Segmented
                            value={page}
                            className="bg-gray-50 self-center rounded-md mr-auto"
                            onChange={option => navigate(option)}
                            style={{background: 'var(--color-bg-light)', padding: 4, borderRadius: 6}}
                            options={[
                                {label: t('metrics.bonuses.pages.balance'), value: 'balance', className: "p-1 font-medium text-black"},
                                {label: t('metrics.bonuses.pages.history'), value: 'history', className: "p-1 font-medium text-black"},
                            ]}
                        />

                        {page === "history" && (
                            <div className="col-2">
                                <FormSelectAsync<LabelModel>
                                    className="col"
                                    placeholder={t('metrics.bonuses.filter.placeholder')}
                                    name="type"
                                    listOptions={() => typeFilters}
                                    getValue={(id: string) => firstValueFrom(typeFilters.pipe(map(list => list.find(obj => +obj.id === +id))))}
                                    getOptionLabel={(option: LabelModel) => (option ? option.label : null)}
                                    getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
                                    isClearable
                                    isSearchable={false}
                                />
                            </div>
                        )}

                        <FormControl
                            icon="search"
                            rootclassname="col-3"
                            className="form-control"
                            name="search"
                            placeholder={t('common.input.search')}
                        />
                    </div>
                )
            }} />

        </FormProvider>
    )
}
