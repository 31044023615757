import moment from "moment"

export default function checkTimeRange(s: string, e: string, s_inv: string, e_inv: string) {
  const start_at = moment(s)
  const end_at = moment(e)
  const inv_start_at = moment(s_inv)
  const inv_end_at = moment(e_inv)
  
  const _1 = start_at < inv_end_at
  const _2 = end_at > inv_start_at
  return _1 && _2
}
