import {CSSProperties, forwardRef} from "react"
import Select, {Props, components} from 'react-select'
import {isNil} from "src/utils/isNil"
import makeAnimated from 'react-select/animated'
import {SelectMenu, SelectOption} from "./common"
const animatedComponents = makeAnimated()

interface ExtendedProps extends Props {
    onModal?: () => void
    selectedIconStyles?: CSSProperties
    iconPlaceholderNeed?: boolean
    label?: string
    rootclassname?: string
}

const CustomSelect = forwardRef(({selectedIconStyles, iconPlaceholderNeed, label, rootclassname, ...config}: ExtendedProps) => {
    return (
        <div className={rootclassname}>
            {label && (
                <div className="flex gap-2 mb-2">
                    <label className="overflow line-clamp-1 text-[13px] color-gray-400">{label}</label>
                    {config.required && <div className="form-required"></div>}
                </div>
            )}
            <Select
                {...config}
                name={config.name}
                // menuPortalTarget={document.getElementById('vhContent')}
                components={{
                    ...animatedComponents,
                    Menu: !isNil(config.onModal) ? params => <SelectMenu {...params} onModal={config.onModal} /> : components.Menu,
                    Option: params => <SelectOption {...params} selectedIconStyles={selectedIconStyles} iconPlaceholderNeed={iconPlaceholderNeed} />,
                    ...config.components,
                }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        danger: "rgb(248, 52, 52)",
                        dangerLight: "rgb(248, 52, 52)",
                        primary: "rgb(161, 101, 253, 1)",
                        primary25: "rgb(161, 101, 253, 0.08)",
                        primary50: "rgb(161, 101, 253, 0.20)",
                        primary75: "rgb(161, 101, 253, 0.8)",
                    },
                })}
            />
        </div>)
})

export default CustomSelect
