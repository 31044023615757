import {InputHTMLAttributes, useContext} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'
import {DatePicker} from "../antd-custom"
import moment from "moment"
import clsx from "clsx"
import {ConfigContext} from "src"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

export function FormControlDate(config: FormControlConfig) {
  const {
    register,
    watch,
    setValue,
    formState: {errors},
  } = useFormContext()
  const error = errors[config.name]
  const {isMobile} = useContext(ConfigContext)
  const className = `${config.className ? config.className : ''} ${error ? 'invalid' : ''} w-full`
  const value = watch(config.name) ? moment(watch(config.name)) : undefined
  const onChange = (date: moment.Moment) => {
    if (date) {
      setValue(config.name, date.format('YYYY-MM-DD'), {shouldDirty: true, shouldTouch: true})
    } else {
      setValue(config.name, null, {shouldDirty: true, shouldTouch: true})
    }
  }

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      {isMobile ? (
        <input
          type="date"
          {...register(config.name, config.params)}
          value={value ? value.format("YYYY-MM-DD") : undefined}
          className={clsx(className, 'block')}
          disabled={config.disabled}
        />
      ) : (
        <DatePicker
          {...register(config.name, config.params)}
          format="DD-MM-YYYY"
          value={value}
          className={className}
          onChange={onChange}
          disabled={config.disabled}
          onBlur={e => {
            const value = (e.target as any).value
            const date = moment(value, "DD-MM-YYYY")
            if (value === '') {
              onChange(null)
              return
            }
            if (date.isValid()) onChange(date)
          }}
        />
      )}
    </div>
  )
}
