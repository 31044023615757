
import {Segmented} from "antd"
import {SegmentedProps} from "antd/lib"
import React, {CSSProperties} from "react"
import {clsx} from 'clsx'

export interface _SegmentedProps<T> extends Omit<SegmentedProps<T>, 'scroll'> {
    optionStyle?: CSSProperties
}

function _Segmented<T>(props: _SegmentedProps<T>, ref: React.Ref<HTMLDivElement>) {
    // const internalRef = useRef<HTMLDivElement>(null)
    // useImperativeHandle(ref, () => internalRef.current!)

    return (
        <Segmented
            {...props}
            className={clsx("scrollbar-hide", props.className)}
            style={{overflowX: 'scroll', backgroundColor: 'var(--color-gray-50)', ...props.style}}
            ref={ref}
        />

    )
}

export default _Segmented
