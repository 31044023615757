import moment from 'moment'
import i18n from "i18next"

export default function secondsToHuman(timeInSeconds: number | string) {
  if (!timeInSeconds) return ''
  if (!i18n.isInitialized) return ''

  const duration = moment.duration(timeInSeconds, 'seconds')

  const parts = []

  const years = duration.years()
  if (years > 0) parts.push(`${moment.duration(years, 'years').years()} ${i18n.t('common.times_abb.year')}`)

  const months = duration.months()
  if (months > 0) parts.push(`${moment.duration(months, 'months').months()} ${i18n.t('common.times_abb.month')}`)

  const days = duration.days()
  if (days > 0) parts.push(`${moment.duration(days, 'days').days()} ${i18n.t('common.times_abb.day')}`)

  const hours = duration.hours()
  if (hours > 0) parts.push(`${moment.duration(hours, 'hours').hours()} ${i18n.t('common.times_abb.hour')}`)

  const minutes = duration.minutes()
  if (minutes > 0) parts.push(`${moment.duration(minutes, 'minutes').minutes()} ${i18n.t('common.times_abb.minute')}`)

  return parts.join(' ')
}