import {TextareaHTMLAttributes} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'

interface FormControlConfig extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  icon?: string
  addonright?: JSX.Element
  label_container_classNames?:string

}

export function FormControlTextarea(config: FormControlConfig) {
  const {
    register,
    formState: {errors},
  } = useFormContext()
  const error = errors[config.name]

  const className = `${config.className ? config.className : ''} ${error ? 'invalid' : ''}`

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className={`flex gap-2 mb-2 ${config.label_container_classNames}`}>
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}

      <div className="input-group">
        {config.icon && <div className={`input-group-icon ${config.icon}`}></div>}
        <textarea {...config} {...register(config.name, config.params)} rows={3} className={className} autoComplete="off" />
        {config.addonright && <div className="input-group-append">{config.addonright}</div>}
      </div>
    </div>
  )
}
