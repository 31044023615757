import axios from 'axios'
import {CrudInterface} from '../../interfaces/crud.interface'
import {ListModel} from '../../models/common'
import {ApiAbstract} from '../../abstract/api.abstract'
import {InventoryMaintenanceModel, MaintenanceModel} from "../../models/manager/maintenance/maintenance.model";
import {InventoryGroupModel} from "../../models/manager/inventory/inventory-group.model";

export class ManageMaintenanceService extends ApiAbstract implements CrudInterface<MaintenanceModel> {
    constructor() {
        super('v1/crm/inventories')
    }

    public async list(params?: any): Promise<ListModel<InventoryMaintenanceModel>> {
        const response = await axios.get(this.getUrl('maintenance'), {params})
        return response.data
    }
    public async getForInventory(inventory_id: number): Promise<MaintenanceModel[]> {
        const response = await axios.get(this.getUrl(`${inventory_id}/maintenance`))
        return response.data
    }
    public async postForInventory(inventory_id: number, payload:any): Promise<any> {
        const response = await axios.post(this.getUrl(`${inventory_id}/maintenance`),payload)
        return response.data
    }
    public async extra_update(inventory_id: number, payload:any): Promise<any> {
        const response = await axios.post(this.getUrl(`${inventory_id}/maintenance/extra-update`),payload)
        return response.data
    }
    public async get(inventory_id: number, id:number): Promise<MaintenanceModel> {
        const response = await axios.get(this.getUrl(`${inventory_id}/maintenance/${id}`))
        return response.data
    }
    // Здесь put спросить, не напишешь post, будет ругаться
    public async post(inventory_id: number, id:number, payload:any): Promise<any> {
        const response = await axios.put(this.getUrl(`${inventory_id}/maintenance/${id}`),payload)
        return response.data
    }
    public async patch(inventory_id: number, id:number, payload:any): Promise<InventoryGroupModel> {
        const response = await axios.patch(this.getUrl(`${inventory_id}/maintenance/${id}`), payload)
        return response.data
    }
    public async delete(inventory_id: number, id:number, payload:any): Promise<InventoryGroupModel> {
        const response = await axios.patch(this.getUrl(`${inventory_id}/maintenance/${id}`), payload)
        return response.data
    }
    public async finish(inventory_id: number, id:number, payload:any): Promise<MaintenanceModel> {
        const response = await axios.post(this.getUrl(`${inventory_id}/maintenance/${id}/finish`),payload)
        return response.data
    }
}
const MaintenanceService = new ManageMaintenanceService()
export default MaintenanceService
