import {DatePicker} from "."
import type {Moment} from 'moment'
import React, {useContext} from "react"
import moment from "moment"
import {RangePickerProps} from "antd/es/date-picker/generatePicker/interface"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from 'react-i18next'
import {ConfigContext} from "src"
const {RangePicker} = DatePicker

export interface RangePickerPropsV2 extends Omit<RangePickerProps<Moment>, 'picker'> { }

const _RangePicker = React.forwardRef<any, RangePickerPropsV2>((props, ref) => {
    const {tenant, isMobile} = useContext(ConfigContext)
    const {t} = useTranslation()

    const rangePresets: RangePickerPropsV2['presets'] = [
        {label: t('orders.list.range_presets.for_the_current_year'), value: [moment().startOf('year'), moment().endOf('year').add(1, 'days')]},
        {label: t('orders.list.range_presets.for_the_current_month'), value: [moment().startOf('month'), moment().endOf('month').add(1, 'days')]},
        {label: t('orders.list.range_presets.last_month'), value: [moment().startOf('month').add(-1, 'months'), moment().startOf('month')]},
        {label: t('orders.list.range_presets.for_all_time'), value: [moment(tenant?.created_at), moment().endOf('year').add(1, 'days')]},
    ]

    return <>
        <RangePicker
            {...props}
            format="DD-MM-YYYY"
            presets={!isMobile ? rangePresets : []}
            ref={ref}
            allowEmpty={[false, false]}
            separator={<Icon icon="arrow_forward" className="text-xs" />}
        />
    </>
})

export default _RangePicker
