import {useRef, useEffect, useState, createContext, MutableRefObject, Dispatch, SetStateAction} from 'react'
import mapboxgl from 'mapbox-gl'
import geolocationService from "src/services/geolocation.service"
import {DeviceModel} from "src/models/geolocation"
import {CurrentDeviceComponent} from "./current"
import {Outlet} from "react-router"
import i18n from "src/i18n"
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = 'pk.eyJ1Ijoia2F6YmVrb3Z2IiwiYSI6ImNtMXFpZXlvYzAwazEycXF2cjMyMjZ4NHQifQ.PNCiOaQ7hmjcxR_pn9csaQ'

const DOT_SIZE = 100
const INITIAL_CENTER: [number, number] = [76.889709, 43.238949]
const INITIAL_ZOOM: number = 12

interface ClientContextModel {
    mapRef: MutableRefObject<any>,
    mapContainerRef: MutableRefObject<any>,
    devices: DeviceModel[],
    deviceMap: Record<number, DeviceModel>,
    onDeviceFocus: (device: DeviceModel) => void,
    currentDeviceId: number,
    setCurrentDeviceId: Dispatch<SetStateAction<number>>
}

export const MapContext = createContext<ClientContextModel>(undefined)

export function GeolocationComponent() {
    const mapRef = useRef<any>()
    const mapContainerRef = useRef<any>()

    const [devices, setDevices] = useState<DeviceModel[]>([])
    const [deviceMap, setDeviceMap] = useState<Record<number, DeviceModel>>({})
    const [currentDeviceId, setCurrentDeviceId] = useState<number>(undefined)

    const onDeviceFocus = (device: DeviceModel) => {
        if (!mapRef.current) return
        if (!device) return
        if (!device.position) return

        setCurrentDeviceId(device.id)
        mapRef.current.flyTo({center: [device.position.longitude, device.position.latitude], zoom: 16})
    }

    useEffect(() => {
        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            center: INITIAL_CENTER,
            zoom: INITIAL_ZOOM,
            language: i18n.language
        })

        mapRef.current.addControl(new mapboxgl.NavigationControl())

        const pulsingDot = {
            width: DOT_SIZE,
            height: DOT_SIZE,
            data: new Uint8Array(DOT_SIZE * DOT_SIZE * 4),

            onAdd: function () {
                const canvas = document.createElement('canvas')
                canvas.width = this.width
                canvas.height = this.height
                this.context = canvas.getContext('2d')
            },

            render: function () {
                const duration = 1000
                const t = (performance.now() % duration) / duration

                const radius = (DOT_SIZE / 2) * 0.3
                const outerRadius = (DOT_SIZE / 2) * 0.5 * t + radius
                const context = this.context

                context.clearRect(0, 0, this.width, this.height)
                context.beginPath()
                context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2)
                context.fillStyle = `rgba(255, 200, 200, ${1 - t})`
                context.fill()

                context.beginPath()
                context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2)
                context.fillStyle = 'rgba(255, 100, 100, 1)'
                context.strokeStyle = 'white'
                context.lineWidth = 2 + 4 * (1 - t)
                context.fill()
                context.stroke()

                this.data = context.getImageData(0, 0, this.width, this.height).data

                mapRef.current.triggerRepaint()

                return true
            }
        }

        mapRef.current.addImage('device-image', pulsingDot, {pixelRatio: 2})

        mapRef.current.on('load', () => {
            mapRef.current.addSource('devices', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                }
            })

            mapRef.current.on('mouseenter', 'devices', () => {
                mapRef.current.getCanvas().style.cursor = 'pointer'
            })

            mapRef.current.on('mouseleave', 'devices', () => {
                mapRef.current.getCanvas().style.cursor = ''
            })

            mapRef.current.addLayer({
                id: 'devices',
                type: 'symbol',
                source: 'devices',
                layout: {
                    'icon-image': 'device-image'
                }
            })
        })

        const sub = geolocationService.devices$.subscribe(_devices => {
            const _deviceMap = _devices.reduce((p, c) => ({...p, [c.id]: c}), {})

            setDevices(_devices)
            setDeviceMap(_deviceMap)

            mapRef.current.getSource('devices')?.setData({
                type: 'FeatureCollection',
                features: _devices.filter(d => !!d.position).map((device) => ({
                    type: "Feature",
                    properties: {deviceId: device.id},
                    geometry: {
                        type: 'Point',
                        coordinates: [device.position.longitude, device.position.latitude]
                    }
                }))
            })

            mapRef.current.on('click', 'devices', (e) => {
                mapRef.current.flyTo({center: e.features[0].geometry.coordinates, zoom: 16})
                const deviceId = e.features[0].properties.deviceId
                onDeviceFocus(_deviceMap[deviceId])
            })
        })

        geolocationService.loadDevices()
        const intervalId = setInterval(() => geolocationService.loadDevices(), 3000)

        return () => {
            mapRef.current.remove()
            sub.unsubscribe()
            clearInterval(intervalId)
        }
    }, [])

    return (
        <MapContext.Provider value={{mapRef, mapContainerRef, devices, deviceMap, onDeviceFocus, currentDeviceId, setCurrentDeviceId}}>
            <div className="flex flex-row w-full h-full">
                <div className="z-20 w-[400px] bg-white shadow-xl">
                    <Outlet />
                </div>
                <div className="relative flex-1 h-full">
                    <CurrentDeviceComponent />
                    <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full z-10" ref={mapContainerRef} />
                </div>
            </div>
        </MapContext.Provider>
    )
}