import {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import type {ColumnsType} from "antd/es/table"
import {Link} from "react-router-dom"
import {AsyncRentalPoint} from "../../abstract/async-rental-point"
import {FormProvider, useForm} from "react-hook-form"
import {ListParams} from "../../models/common"
import {useDebounce} from "../../hooks/useDebounce"
import {FormControl} from "../../components/shared/inputs/form-control"
import {ConfigContext} from 'src/index'
import {useCurrentRoute} from "../../hooks/useCurrentRoute"
import inventoryGroupService from "../../services/inventory/inventory-group.service"
import {SharedInventoryGroupSelect} from "../../components/shared/components/select/inventory-group"
import {colourStyles} from "../../components/shared/inputs/form-select-color-style"
import {SharedCategorySelect} from "../../components/shared/components/select/category"
import Table from "../../components/shared/antd-custom/table"
import {hexToRgbA} from "../../utils/color/hex-to-rgba"
import maintenanceService from "../../services/maintenance/maintenance.service"
import {MaintenanceModel} from "../../models/manager/maintenance/maintenance.model"
import {ImageField} from "../../components/shared/image-loader/image"
import {SharedInventoryStateSelect} from "src/components/shared/components/select/inventory-state"

interface MaintenanceParams extends ListParams {
    search: string
    state: string
    rental_point: number
    group: number | string
    client__signed: boolean
    group__category: number
    category: string
}

export default function MaintenanceHistory() {
    const [list, setList] = useState<MaintenanceModel[]>([])

    const {t} = useTranslation()
    const defaultConfig = {}
    const form = useForm<MaintenanceParams>({defaultValues: defaultConfig})
    const {reset, setValue, watch} = form
    const values = watch()
    const activeRoute = useCurrentRoute()
    const searchDebounce = useDebounce(watch('search', ''), 500)
    const {constantsMap} = useContext(ConfigContext)
    const onLoad = async (params: any): Promise<void> => maintenanceService
        .getForInventory(params.id).then(setList)
        .catch(e => e.response.status === 404 && setValue('page', 1))

    useEffect(() => reset({...values, page: 1, search: searchDebounce}), [searchDebounce])

    useEffect(() => {
        // onLoad(values)
        const sub = watch((params, {name}) => {
            if (name !== 'search') onLoad(params)
        })
        return () => {
            sub.unsubscribe()
            setList([])
        }
    }, [activeRoute])


    const columns: ColumnsType<MaintenanceModel> = [
        {
            width: 240,
            title: "Инвентарь",
            dataIndex: 'inventory',
            key: 'inventory',
            render: name =>
                <div className={'flex gap-3 items-center'}>
                    <ImageField src={'image'} className={'w-10 h-10 rounded-lg'} />

                    <Link onClick={(e) => e.stopPropagation()} to={`/`} className="fw-500 text-nowrap link">
                        {name}
                    </Link>

                </div>,
            sorter: true,
        },
        {
            width: 144,
            title: "Название ТО",
            dataIndex: 'name',
            key: 'name',
            render: state => state ? (
                <span
                    style={{
                        backgroundColor: hexToRgbA(constantsMap.INVENTORY_STATE_STATUS[+state].color, 0.2),
                        color: constantsMap.INVENTORY_STATE_STATUS[+state].color,
                    }}
                    className={'flex justify-center items-center h-8 w-full rounded-lg p-2 text-[13px] font-medium'}
                >
                    {constantsMap.INVENTORY_STATE_STATUS[+state].name}
                </span>
            ) : (
                <span
                    style={{backgroundColor: '#EDEEF7', color: '#8997A1'}}
                    className={'flex justify-center items-center h-8 w-full rounded-lg p-2 text-[13px] font-medium'}
                >
                    {t('maintenance.unchecked')}
                </span>
            ),
            sorter: true,
        }, {
            width: 161,
            title: "Дата и время",
            dataIndex: 'date_time',
            key: 'date_time',
            render: (_,) => (
                <></>
            ),
            sorter: true,
        }, {
            width: 192,
            title: "Тип",
            dataIndex: 'type',
            key: 'type',
            render: (_,) =>
                <div></div>
            ,
            sorter: true,
        }, {
            width: 136,
            title: "Состояние",
            dataIndex: 'state',
            key: 'state',
            render: (_,) => <></>,
            sorter: true,
        }, {
            width: 176,
            title: "Ответственный",
            dataIndex: 'responsible',
            key: 'responsible',
            render: (_,) => <></>,
            sorter: true,
        },
        {
            width: 176,
            title: "Описание",
            dataIndex: 'description',
            key: 'description',
            render: (_,) => <></>,
            sorter: true,
        }
    ]

    return (
        <FormProvider {...form}>
            <div className="d-flex gap-2 mt-4 mb-8 ">
                <FormControl
                    name="search"
                    className="form-control"
                    icon="search"
                    placeholder={t('common.input.search')}
                />
                <SharedInventoryStateSelect
                    name={'state'}
                    placeholder={t('maintenance.selects.state_select.all')}
                    styles={{
                        ...colourStyles(false),
                        placeholder: (styles, props) => ({
                            ...styles,
                            color: "black"
                        }),
                        container: (styles, props) => ({
                            ...styles,
                            minWidth: "192px"
                        })
                    }}
                    isClearable={true}
                />
                <AsyncRentalPoint
                    name={'rental_point'}
                    placeholder={t('maintenance.all_rental_point')}
                    className={'flex-initial'}
                    styles={{
                        ...colourStyles(false),
                        placeholder: (styles, props) => ({
                            ...styles,
                            color: "black"
                        }),
                        container: (styles, props) => ({
                            ...styles,
                            minWidth: "192px"
                        })
                    }}
                />
                <SharedInventoryGroupSelect
                    name="group"
                    placeholder={t('maintenance.all_products')}
                    listOptions={params => inventoryGroupService.list({...params, type: 0})}
                    isSearchable={true}
                    isClearable={true}
                    styles={{
                        ...colourStyles(false),
                        placeholder: (styles, props) => ({
                            ...styles,
                            color: "black"
                        }),
                    }}
                />
                <SharedCategorySelect
                    name="group__category"
                    placeholder={t('maintenance.all_categories')}
                    isClearable={true}
                    isSearchable={false}
                    styles={{
                        ...colourStyles(false),
                        placeholder: (styles, props) => ({
                            ...styles,
                            color: "black"
                        }),
                    }}
                />
            </div>
            <Table
                dataSource={list}
                columns={columns}
            // onAdd={(group,index)=>newMaintenance(group,index)}
            />

            {/*<PaginationFormComponent count={list.count} />*/}
        </FormProvider>
    )
};

