import {BaseSyntheticEvent, ReactNode, createContext, useEffect, useMemo, useState} from 'react'
import {FormProvider, useForm} from "react-hook-form"
import {useLocation, useNavigate, useParams} from 'react-router'
import {firstValueFrom} from "rxjs"
import modalService from "src/components/modal/global/modal.service"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"
import {InventoryModel} from "src/models/manager/inventory/inventory.model"
import {SaleFormModel, SaleInventoryFormModel, SaleInventoryFormUpdateModel, SaleInventoryModel, SaleModel} from "src/models/manager/order/order-sale.model"
import saleService from "src/services/sale/sale.service"
import keygen from "src/utils/keygen"
import SaleInventoryGroupList from "./inventories/modal"
import {isNil} from "src/utils/isNil"
import SaleInventoryGroupTarifList from "./inventories/price-modal"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import {InventoryTarifModel} from "src/models/manager/inventory/inventory-tarif.model"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import Icon from "src/components/shared/components/material-icon"
import {t} from "i18next"
import {Link} from "react-router-dom"
import inventoryService from "src/services/inventory/inventory.service"

interface OrderSaleContextType {
    sale: SaleModel
    invCnt: number
    disabled: boolean
    groups: InventoryGroupModel[]
    onGroupAdd: () => void
    onGroupSelect: (group: InventoryGroupModel) => void
    onGroupRemoval: (e: BaseSyntheticEvent<object, any, any>, group: InventoryGroupModel) => void
    onInventorySelect: (inventory: InventoryModel) => void
    onConfirm: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>
}

export const OrderSaleContext = createContext<OrderSaleContextType>(undefined)

export const OrderSaleProvider = ({children, inventories = {}}: {children: ReactNode, inventories?: Record<number, SaleInventoryModel>}) => {
    const {id} = useParams<{id: string}>()
    const form = useForm<SaleFormModel>({defaultValues: {price: 0, price_discount: 0, _inventories: {...inventories}}})
    const {reset, watch, handleSubmit} = form
    const [sale, setSale] = useState<SaleModel>(undefined)
    const [groups, setGroups] = useState<InventoryGroupModel[]>([])
    const [groupPriceMap, setGroupPriceMap] = useState<Record<number, number>>({})
    const [saleInventories, setInventories] = useState<SaleInventoryModel[]>([])
    const location = useLocation()
    const navigate = useNavigate()
    const values = watch()
    const disabled = useMemo(() => sale ? sale.status !== 0 : false, [sale])

    const onInventorySelect = async (inventory: InventoryModel) => {
        if (Object.keys(watch('_inventories')).some(key => watch(`_inventories.${key}.inventory.id`) === inventory.id)) {
            reset({
                ...values,
                _inventories: Object.fromEntries(
                    Object.values(watch('_inventories'))
                        .filter(obj => obj.inventory.id !== inventory.id)
                        .map(obj => [obj.id, obj])
                )
            })
        } else {
            const uniqueId = keygen("", "")
            let price = 0
            if (groupPriceMap.hasOwnProperty(inventory.group)) {
                price = groupPriceMap[inventory.group]
            } else {
                const tarifs = await inventoryGroupService.listTarifs(inventory.group, {})

                if (tarifs.length > 1) {
                    modalService.closeModal()
                    modalService.open({
                        component: <SaleInventoryGroupTarifList
                            tarifs={tarifs}
                            onSelect={(tarif) => {
                                price = tarif.price
                                modalService.closeModal()
                            }}
                        />
                    })
                } else if (tarifs.length !== 0) {
                    price = tarifs[0].price
                }

                setGroupPriceMap(prev => ({...prev, [inventory.group]: price}))
            }

            reset({
                ...values,
                _inventories: {
                    ...watch('_inventories'),
                    [uniqueId]: {
                        id: uniqueId,
                        price: price,
                        price_discount: price,
                        inventory,
                        tarif: undefined
                    }
                }
            })
        }
    }

    const onGroupSelect = async (group: InventoryGroupModel) => {
        const tarifs = await inventoryGroupService.listTarifs(group.id, {})
        const onAdd = (tarif: InventoryTarifModel) => {
            setGroupPriceMap(prev => ({...prev, [group.id]: tarif.price}))
            setGroups(prev => prev.map(obj => obj.id).includes(group.id) ? prev : [...prev, group])
            modalService.closeModal()
        }
        if (tarifs.length > 1) {
            modalService.closeModal()
            modalService.open({
                component: <SaleInventoryGroupTarifList
                    tarifs={tarifs}
                    onSelect={(tarif) => onAdd(tarif)}
                />
            })
        } else if (tarifs.length === 1) {
            onAdd(tarifs[0])
        }
    }

    const onGroupRemoval = (e: any, group: InventoryGroupModel) => {
        e.stopPropagation()
        reset({
            ...values,
            _inventories: Object.fromEntries(
                Object.values(watch('_inventories'))
                    .filter(obj => obj.inventory.group !== group.id)
                    .map(obj => [obj.id, obj])
            )
        })
        setGroups(prev => prev.filter(obj => obj.id !== group.id))
    }

    const onGroupAdd = () => {
        modalService.open({
            props: {size: "lg", backdrop: true},
            component: <SaleInventoryGroupList onSelect={onGroupSelect} />
        })
    }

    const onSave = async (payload: SaleFormModel) => {
        const inventoriesObject = payload._inventories
        const inventories = Object.values(inventoriesObject)
        delete payload._inventories

        const createPayload: SaleInventoryFormModel[] = inventories
            .filter(obj => Number.isNaN(+obj.id))
            .map(obj => ({...obj, inventory: obj.inventory ? obj.inventory.id : null}))
        const updatePayload: SaleInventoryFormUpdateModel[] = inventories
            .filter(obj => Number.isInteger(+obj.id))
            .map(obj => ({...obj, id: +obj.id, inventory: obj.inventory ? obj.inventory.id : null}))
        const deletePayload: number[] = saleInventories
            .filter(obj => isNil(inventoriesObject[obj.id]))
            .map(obj => +obj.id)

        let sale: SaleModel

        if (id) {
            await saleService.bulkDelete(+id, {ids: deletePayload})
            await saleService.bulkCreate(+id, {inventories: createPayload})
            await saleService.bulkUpdate(+id, {inventories: updatePayload})
            sale = await saleService.patch(+id, payload)
        } else {
            sale = await saleService.post(payload)
            await saleService.bulkCreate(sale.id, {inventories: createPayload})
            await saleService.patch(sale.id, payload)
        }
        return sale
    }

    const onConfirm = handleSubmit(async (payload) => {
        onSave(payload).then(async (_sale) => {
            const onConfirm = async () => {
                await saleService.confirm(_sale.id)
                navigate(location?.state?.path || "/sales")
                setTimeout(() => {
                    modalService.open({
                        props: {size: 'sm'},
                        component: <div className="flex gap-3 flex-col justify-center items-center">
                            <div style={{backgroundColor: 'var(--color-primary-20)', borderRadius: 8, padding: 10}}>
                                <Icon icon="check" className="color-primary font-semibold" />
                            </div>
                            <div className="flex gap-2 flex-col text-center">
                                <div className="text-sm">
                                    <span className="font-medium">{groups.map(group => group.name).join(', ')}</span> {t('sale.edit.sold_successfully')}
                                </div>
                            </div>
                            <button className="btn btn-primary btn-color-white w-full font-medium" onClick={() => modalService.closeModal()}>
                                {t('common.ok')}
                            </button>
                            <Link to="/sales/history" className="btn btn-card w-full font-medium">
                                {t('sale.edit.sale_history')}
                            </Link>
                        </div>
                    })
                }, 200)
            }

            modalService.open({
                component: <ConfirmModal
                    message={t('sale.modal.confirm')}
                    confirm_text={t('common.confirm.title')}
                    onConfirm={onConfirm}
                />
            })
        })
    })

    const getSale = async (_id: number) => Promise.all([
        firstValueFrom(saleService.get(_id)),
        saleService.listGroups(_id),
        saleService.listInventories(_id)
    ]).then(([_sale, _groups, _inventories]) => {
        setSale(_sale)
        setGroups(_groups)
        setInventories(_inventories)
        reset({..._sale, _inventories: Object.fromEntries(_inventories.map(obj => [obj.id, obj]))})
    })

    const onStateInventories = async () => {
        const tarif = location.state.tarif
        const list = await inventoryService.list({in_ids: location.state.inventories.join(',')})

        setGroupPriceMap(prev => ({...prev, [location.state.group.id]: tarif.price}))
        setGroups(prev => prev.map(obj => obj.id).includes(location.state.group.id) ? prev : [...prev, location.state.group])

        reset({
            ...values,
            _inventories: Object.fromEntries(
                list.results.map((inventory) => {
                    const key = keygen("", "")
                    return [
                        key,
                        {
                            id: key,
                            price: tarif?.price || 0,
                            price_discount: tarif?.price || 0,
                            tarif: tarif?.id,
                            inventory
                        }
                    ]
                })
            )
        })
    }

    useEffect(() => {
        if (id) {
            getSale(+id)
            return
        }
        if (location.state && location.state.group && location.state.inventories && location.state.tarif) {
            onStateInventories()
            return
        }
        if (location.state && location.state.group) {
            onGroupSelect(location.state.group)
            return
        }

        onGroupAdd()

        return () => {
            setSale(undefined)
            setGroups([])
        }
    }, [id])

    return (
        <OrderSaleContext.Provider value={{
            sale,
            groups,
            onGroupAdd,
            onGroupSelect,
            onGroupRemoval,
            onInventorySelect,
            onConfirm,
            invCnt: Object.values(values._inventories).length,
            disabled
        }}>
            <FormProvider {...form}>
                {children}
            </FormProvider>
        </OrderSaleContext.Provider>
    )
}
