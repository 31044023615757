import axios from "axios"
import {ApiAbstract} from "src/abstract/api.abstract"
import {ListModel} from "src/models/common"
import {ArticleModel, EventModel, EventRequestModel, ProductOrder} from "src/models/manager/website"

export class WebsiteService extends ApiAbstract {
  constructor() {
    super('v1/website')
  }

  // product order
  public async listProductOrders(): Promise<ProductOrder[]> {
    const response = await axios.get(this.getUrl('products/ordering'))
    return response.data
  }
  public async patchProductOrder(id: number, payload: any): Promise<ProductOrder> {
    const response = await axios.patch(this.getUrl(`products/ordering/${id}`), payload)
    return response.data
  }

  // articles
  public async listArtices(params?: any): Promise<ArticleModel[]> {
    const response = await axios.get(this.getUrl('articles'), {params})
    return response.data
  }
  public async createArtice(payload: any): Promise<ListModel<ArticleModel>> {
    const response = await axios.post(this.getUrl('articles'), payload)
    return response.data
  }
  public async getArtice(id: number): Promise<ListModel<ArticleModel>> {
    const response = await axios.get(this.getUrl(`articles/${id}`))
    return response.data
  }
  public async updateArtice(id: number, payload: any): Promise<ArticleModel> {
    const response = await axios.patch(this.getUrl(`articles/${id}`), payload, {params: {skip_loader: true}})
    return response.data
  }
  public async deleteArtice(id: number): Promise<ListModel<ArticleModel>> {
    const response = await axios.delete(this.getUrl(`articles/${id}`))
    return response.data
  }

  // events
  public async listEvents(params?: any): Promise<EventModel[]> {
    const response = await axios.get(this.getUrl('events'), {params})
    return response.data
  }
  public async listEventRequests(id: number, params?: any): Promise<EventRequestModel[]> {
    const response = await axios.get(this.getUrl(`events/${id}/requests`), {params})
    return response.data
  }
  public async createEvent(payload: any): Promise<EventModel> {
    const response = await axios.post(this.getUrl('events'), payload)
    return response.data
  }
  public async updateEvent(id: number, payload: any): Promise<EventModel> {
    const response = await axios.patch(this.getUrl(`events/${id}`), payload)
    return response.data
  }
  public async deleteEvent(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`events/${id}`))
    return response.data
  }
}

const websiteService = new WebsiteService()
export default websiteService
