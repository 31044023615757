import Editor from 'ckeditor5-custom-build/build/ckeditor'
import {InputHTMLAttributes, lazy, Suspense} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'
import {baseConfig} from "src/pages/config/documents/config"
import {useTranslation} from 'react-i18next'

const CKEditor = lazy(() => import('@ckeditor/ckeditor5-react').then(module => ({default: module.CKEditor})))

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

export function FormControlCkeditor(config: FormControlConfig) {
  const {watch, setValue} = useFormContext()
  const {i18n} = useTranslation()

  const handleChange = (event: any, editor: any) => {
    setValue(config.name, editor.getData(), {shouldDirty: true, shouldValidate: true})
  }

  return (
    <div className='col-12'>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <Suspense>
        <CKEditor
          editor={Editor}
          data={watch(config.name) || ""}
          config={baseConfig(i18n.language)}
          disabled={config.disabled}
          onChange={handleChange}
        />
      </Suspense>
    </div>
  )
}
