import {FormProvider, useForm, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import modalService from "src/components/modal/global/modal.service"
import React, {useEffect, useMemo, useState} from "react";
import {OptionChoose} from "./option-choose";
import {InventoryMaintenanceModel, MaintenanceModel} from "../../models/manager/maintenance/maintenance.model";
import {FormControl} from "../../components/shared/inputs/form-control";
import CustomSelect from "../../components/shared/inputs/select/select";
import {colourStyles} from "../../components/shared/inputs/form-select-color-style";
import {FormControlTextarea} from "../../components/shared/inputs/form-control-textarea";
import maintenanceService from "../../services/maintenance/maintenance.service";
import {FormControlDate} from "../../components/shared/inputs/form-control-date";
import moment from "moment-timezone";
import {SharedProfileSelect} from "../../components/shared/components/select/profile";
import {SharedInventoryStateSelect} from "../../components/shared/components/select/inventory-state";

interface maintenanceForm {
    id: number,
    name: string,
    description: string,
    comment: string,
    state: number,
    interval: string,
    maintenance_date: string,
    notify_time:string
    last_request: number,
    responsible: number
}

export default function MaintenanceModal(config: {inventory: InventoryMaintenanceModel, onSubmit: (paylaod: maintenanceForm) => Promise<any>,finish?:boolean}) {
    const {inventory,finish} = config
    const {t} = useTranslation()
    const [periodic,setPeriodic]=useState(finish ? false : inventory.last_maintenance?.interval!=='00:00:00')
    const [doToday,setDoToday]=useState((finish ? true : inventory.last_maintenance?.notify_time==="00:00:00"))


    function timeToDays(timeString:string) {
        if(!timeString){
            return
        }
        const [hours, minutes, days] = timeString.split(':').map(Number);
        const totalDays = days + (hours / 24) + (minutes / 1440);
        return totalDays?.toString();
    }

    const form = useForm<maintenanceForm>({
        defaultValues: {
            name:inventory.last_maintenance?.name || '',
            description:inventory.last_maintenance?.description || null,
            comment:inventory.last_maintenance?.comment || '',
            state:inventory.last_maintenance?.state || null,
            interval:timeToDays(inventory.last_maintenance?.interval) || '',
            maintenance_date:inventory.last_maintenance?.maintenance_date || `${moment().tz('UTC').toISOString()}`,
            notify_time:timeToDays(inventory.last_maintenance?.notify_time) || '',
            responsible:inventory.last_maintenance?.responsible || null,
        }
    })
    const onSubmit = form.handleSubmit(() => {
        config.onSubmit(values).then(() => modalService.closeAllModal())
    })
    const periodTypes = [
        { label: t('maintenance.maintenance_types.one_time'), value: false, hint: t('maintenance.one_time_maintenance')},
        { label: t('maintenance.maintenance_types.periodic'), value: true, hint: t('maintenance.periodic_maintenance')}
    ];

    const DoTypes = [
        { label: t('maintenance.maintenance_do.now'), value: true },
        { label: t('maintenance.maintenance_do.plan'), value: false }
    ]

    const {reset}=form
    const values = form.getValues()
    const maintenance_date = form.watch('maintenance_date')

    return (
        <FormProvider {...form}>
            <div className={'flex flex-col gap-8 '}>

                <div className={'font-semibold text-2xl text-test-black'}>{`${inventory.name} (${inventory.unique_id})`}</div>
                {!finish &&
                    <>
                    <OptionChoose
                        onChange={(val)=> {
                            setPeriodic(val)
                            if(val){
                                reset({...values, state:inventory.last_maintenance?.state || null,})
                            } else {
                                if(doToday){
                                    reset({...values, maintenance_date:inventory.last_maintenance?.maintenance_date || `${moment().tz('UTC').toISOString()}`,notify_time:timeToDays(inventory.last_maintenance?.notify_time) || '', interval:timeToDays(inventory.last_maintenance?.interval) || ''})
                                } else {
                                    reset({...values, interval:timeToDays(inventory.last_maintenance?.interval) || ''})

                                }

                            }
                        }}
                        options={periodTypes}
                        value={periodic}
                        textClassName={'font-medium text-test-black'}
                        hintClassName={'font-normal text-sm text-gray-test-350'}
                    />
                    {!periodic && <OptionChoose
                        onChange={(val)=> {
                            setDoToday(val)
                            if(val){
                                reset({...values, maintenance_date:inventory.last_maintenance?.maintenance_date || `${moment().tz('UTC').toISOString()}`,notify_time:timeToDays(inventory.last_maintenance?.notify_time) || ''})
                            } else {
                                reset({...values, state:inventory.last_maintenance?.state || null,})
                            }
                        }}
                        options={DoTypes}
                        value={doToday}
                        textClassName={'font-medium text-test-black'}
                        hintClassName='font-normal text-sm text-gray-test-350'
                    />
                    }
                    </>
                }

                <FormControl
                    name="name"
                    className="form-control h-12 cursor-text shadow-none border-gray-200 placeholder:text-gray-test-350"
                    placeholder={t('common.input.placeholder.name')}
                    label={t('common.input.name')}
                />
                {(periodic || !doToday ) &&
                    <div className={'flex gap-6 justify-between'}>
                        <FormControlDate type={'date'} value={maintenance_date} name={'maintenance_date'} label={`${t('maintenance.planned_date')} ${periodic ? t('maintenance.first') : ''} ${t('maintenance.maintenance_abr')}`} className={'h-[48px] w-[224px]'}/>
                        <div className="relative">
                            <FormControl
                                name={'notify_time'}
                                label={t('maintenance.notify_before')}
                                type={"number"}
                                className={'form-control-number h-[48px] rounded-md w-[224px] border border-gray-200 px-4'} number_only={+true}
                                number_decimal={+true}

                            />
                                <span className="absolute bottom-0 right-0 flex items-center pr-4 text-gray-test-350 font-normal text-center h-[48px]">
                                    {t('common.times_count.days')}
                                </span>
                        </div>
                    </div>}
                <div className={'flex gap-6 justify-between'}>
                    <SharedProfileSelect
                        menuPortalTarget={document.body}
                        className="col"
                        name="responsible"
                        label={t('maintenance.responsible')}
                        placeholder={t("common.select.user")}
                        listParams={{is_staff: true}}
                        isClearable={false}
                        isSearchable={false}
                        styles={{
                            ...colourStyles(false),
                            control: (styles, props) => ({
                                ...styles,
                                borderRadius:'6px',
                                width:doToday && '224px',
                                height:'48px',
                                borderColor:"var(--color-gray-200)"
                            }),
                            singleValue: (styles, props) => ({
                                ...styles,
                                textOverflow:'ellipsis',
                                overflow:"visible",
                                fontWeight: 400,

                            }),
                            dropdownIndicator: (styles, props) => ({
                                ...styles,
                                color: 'var(--color-test-black)'
                            }),
                            option: (styles, props) => ({
                                ...styles,
                                background: "transparent",
                                fontWeight: 400,
                                borderRadius: 0,
                                padding: '12px 16px',
                                transition: '0.25s all',
                                backgroundColor: 'transparent',
                                color: props.isSelected ? 'var(--color-primary)' : (props.isFocused ? 'var(--color-primary)' : 'var(--color-black)'),
                                borderBottom: '1px solid var(--color-gray-100)',
                                cursor: 'pointer',
                                ':active': {
                                    backgroundColor: 'transparent'

                                },
                                ':first-of-type': {
                                    marginTop: 0,
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    color:'inherit'
                                },
                                ':last-of-type': {
                                    borderBottomLeftRadius: 8,
                                    borderBottomRightRadius: 8
                                },
                                '&:focus': {
                                    backgroundColor: props.isSelected ? 'var(--color-primary)' : 'var(--color-primary-8)',
                                    color: props.isSelected ? 'var(--color-white)' : 'var(--color-primary)'
                                },
                                '&:hover': {
                                    backgroundColor: 'var(--color-primary-8)',
                                    color: 'var(--color-primary)'
                                },

                            }),
                            valueContainer: (styles, props) => ({
                                ...styles,
                                display: 'flex',
                                justifyContent: 'start',
                                flexWrap: 'nowrap',
                                gap: '4px',
                            }),
                            placeholder: (styles, props) => ({
                                ...styles,
                                color:"var(--color-gray-test-350)",
                                fontWeight:400
                            }),
                        }}
                        selectedIconStyles={{color: "var(--color-primary)"}}
                        iconPlaceholderNeed={true}

                    />
                    {(doToday && !periodic) &&
                        <SharedInventoryStateSelect
                            name={'state'}
                            placeholder={t('maintenance.condition')}
                            styles={{...colourStyles(false),
                            placeholder: (styles, props) => ({
                                    ...styles,
                                    color:"black"
                                }),
                                container:(styles, props) => ({
                                    ...styles,
                                    minWidth:"192px"
                                })
                            }}
                            isClearable={true}
                            menuPortalTarget={document.body}
                            label={t('maintenance.condition')}
                            className="col"
                            menuPlacement="bottom"
                            isSearchable={false}


                        />}
                    {periodic &&
                        <div className="relative">
                            <FormControl
                                name={'interval'}
                                label={t('maintenance.maintenance_periodical')}
                                type={"number"}
                                className={'form-control-number h-[48px] rounded-md w-[224px] border border-gray-200 px-4'}
                                number_only={+true}
                                number_decimal={+true}
                            />
                            <span
                                className="absolute bottom-0 right-0 flex items-center pr-4 text-gray-test-350 font-normal text-center h-[48px]">
                                    {t('common.times_count.days')}
                                </span>
                        </div>}
                </div>
                <FormControlTextarea name={'description'} label={t('common.input.textarea_label')}
                                     rootclassname="col mb-3"
                                     className="form-control cursor-text h-[187px] placeholder:text-gray-test-350"
                                     placeholder={t('groups.inventories.form.comment_placeholder')}
                                     required={false}
                />
                <div className={'flex justify-end gap-4'}>
                    <button className={'btn btn-card font-medium bg-gray-test-70 w-[105px]'}
                            onClick={() => modalService.closeModal()}
                    >
                        {t('common.back')}
                    </button>
                    <button className={'btn btn-primary btn-color-white font-medium w-[135px]'}
                            onClick={onSubmit}
                    >
                        {t('common.save')}
                    </button>
                </div>
            </div>
        </FormProvider>
)
}
