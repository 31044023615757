import {useContext, useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useDebounce} from 'src/hooks/useDebounce'
import {LabelModel} from 'src/models/label'
import {AsyncRentalPoint} from "src/abstract/async-rental-point"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import appService from "src/services/app.service"
import {firstValueFrom, map} from "rxjs"
import {FormControl} from "src/components/shared/inputs/form-control"
import {CustomFieldFilterList} from "../../settings/custom-field/field-filter"
import {useTranslation} from 'react-i18next'
import {ConfigContext} from "../../../index";
import Icon from "../../../components/shared/components/material-icon";

export default function InventoryListFilter({customFieldFilter = {}}: {customFieldFilter?: any}) {
    const form = useFormContext<any>()
    const {reset, watch} = form
    const {t} = useTranslation()
    const values = watch()
    const searchDebounce = useDebounce(watch('search'), 500)
    const {isMobile}=useContext(ConfigContext)

    useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

    return <>
        <div className={`flex gap-2 ${isMobile ? 'bg-white py-2 pl-2 pr-4 mb-[5px]' : 'mb-3'}`}>
            {!isMobile &&
                <>
                    <AsyncRentalPoint name='rental_point'/>
                    <div className="col">
                        <FormSelectAsync<LabelModel>
                            name="status"
                            className='w-full'
                            placeholder={t('orders.inventory.filter')}
                            listOptions={() => appService.getConstant('INVENTORY_STATUS')}
                            getValue={id => firstValueFrom(appService.getConstant('INVENTORY_STATUS').pipe(map(list => list.find(obj => obj.id === +id))))}
                            getOptionLabel={(option: LabelModel) => (option ? option.label : undefined)}
                            getOptionValue={(option: LabelModel) => (option ? String(option.id) : undefined)}
                            isClearable={true}
                            isSearchable={false}
                        />
                    </div>
                </>
            }

            <div className="col"></div>
            <FormControl
                rootclassname={isMobile ? "w-full" : "col-3"}
                icon="search"
                name="search"
                className="form-control"
                placeholder={t('common.input.search')}
            />
            {isMobile &&
                <div className={'min-h-12 min-w-12 rounded-lg border border-gray-200 flex justify-center items-center'}>
                    <Icon icon={'filter_list'} className={'h-5 w-6 text-2xl'}/>
                </div>
            }
        </div>

        <CustomFieldFilterList className="mb-3" prefix="extra__" type="inventory" filter={customFieldFilter} />
    </>
}
