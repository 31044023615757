import {ActionMeta, Props} from 'react-select'
import {useFormContext, Controller, RegisterOptions} from 'react-hook-form'
import {colourStyles} from './form-select-color-style'
import {useEffect, useState} from 'react'
import {isNil} from 'src/utils/isNil'
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import {Observable, Subscription} from "rxjs"
import CustomSelect from "./select/select"

export interface FormSelectAsyncConfig<T> extends Props {
  label?: string
  name: string
  params?: RegisterOptions<any, any>
  listOptions: () => Observable<T[]>
  getValue?: (id: any | any[]) => Promise<T | T[]>
  modalProps?: ModalConfig
  disabled?: boolean,
  iconPlaceholderNeed?:boolean
}

export default function FormSelectAsync<T>(config: Partial<FormSelectAsyncConfig<T>>) {
  const {control, watch} = useFormContext()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<T[]>([])
  const [sub, setSub] = useState<Subscription>()
  const [selectedOption, setSelectedOption] = useState<T | T[]>(null)
  const onModal = config.modalProps ? () => modalService.open(config.modalProps) : undefined
  const value: T | T[] = watch(config.name)

  useEffect(() => {
    if (!isNil(value) && config.getValue !== undefined) {
      setLoading(true)
      config.getValue(value).then(setSelectedOption).finally(() => setLoading(false))
    }
  }, [value])

  const onFocus = () => {
    if (isNil(sub)) {
      const _sub = config.listOptions().subscribe(setOptions)
      setSub(_sub)
    }
  }

  useEffect(() => {
    return () => sub && sub.unsubscribe()
  }, [])
  return (
    <div className={config.className}>
      <Controller
        name={config.name}
        control={control}
        rules={config.params}
        render={({field: {onBlur, value, onChange, ref}, fieldState, formState}) => (
          <CustomSelect
            isClearable
            isSearchable
            name={config.name}
            onFocus={onFocus}
            {...config}
            styles={config.styles ? config.styles : colourStyles(!isNil(fieldState.error))}
            onChange={(val: T | T[], action: ActionMeta<T | T[]>) => {
              setSelectedOption(val)

              if (Array.isArray(val)) {
                setSelectedOption(val.length > 0 ? val : null)
                onChange(val.map(obj => config.getOptionValue(obj)))
              } else {
                setSelectedOption(val || null)
                onChange(config.getOptionValue(val))
              }
              if (!isNil(config.onChange)) config.onChange(val, action)
            }}
            ref={ref}
            isLoading={loading}
            value={!isNil(value) ? selectedOption : null}
            onBlur={onBlur}
            isDisabled={config.disabled}
            options={options}
            onModal={onModal}
          />
        )}
      />
    </div>
  )
}
