// order statuses
export const ORDER_REQUEST = 0
export const ORDER_RESERVED = 1
export const ORDER_INRENT = 2
export const ORDER_CANCELED = 3
export const ORDER_COMPLETED = 4
export const ORDER_DEBTOR = 5


export enum OrderStatus {
  REQUEST = 0,
  RESERVED = 1,
  INRENT = 2,
  CANCELED = 3,
  COMPLETED = 4,
  DEBTOR = 5
}

export enum PaymentStatus {
  PENDING = 0,
  PAID = 1,
  PARTLY_PAID = 2
}

export enum DiscountType {
  PERCENT = 0,
  PROMOCODE = 1
}

export enum ClientType {
  INDIVIDUAL = 0,
  LEGAL = 1
}
