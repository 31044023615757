import {useFieldArray, useFormContext} from "react-hook-form"
import {firstValueFrom, of} from "rxjs"
import modalService from "src/components/modal/global/modal.service"
import Icon from "src/components/shared/components/material-icon"
import {FormControl} from "src/components/shared/inputs/form-control"
import {EMPTY_LIST} from "src/models/common"
import keygen from "src/utils/keygen"
import {toPrice} from "src/utils/price"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import {isNil} from "src/utils/isNil"
import inventorySetItemsService from "src/services/inventory/inventory-set-items.service"
import {useParams} from "react-router"
import inventorySetPriceService from "src/services/inventory/inventory-set-price.service"
import {useCallback, useEffect, useRef, useState} from "react"
import {InventoryGroupModal} from "../../group/list/modal-wrapper"
import {InputNumber} from 'antd'
import {useTranslation} from 'react-i18next'
import {SharedTimePeriodSelect} from "src/components/shared/components/select/time-period"
import {SharedInventoryGroupSelect} from "src/components/shared/components/select/inventory-group"
import "./styles.scss"

export const ProductSetPriceTable = () => {
    const {id} = useParams()
    const ref = useRef(null)
    const [isRightShadow, setIsRightShadow] = useState(false)
    const {t} = useTranslation()
    const form = useFormContext()
    const items = useFieldArray({control: form.control, name: 'items'})
    const prices = useFieldArray({control: form.control, name: 'prices'})
    const tarifs = form.watch('tarifs')

    const calculateOverallPrice = useCallback((id: number | string) => {
        const keys = Object.keys(tarifs)
        const items = form.watch('items')
        const periodKeys = keys.filter(k => k.split("_")[1] === String(id))
        const price = periodKeys.reduce((p, c) => {
            const tarifPrice = (+(tarifs[c]?.price) || 0)
            const itemCount = (items.find((item: any) => String(item._id) === c.split("_")[0]) as any)?.count || 1
            return p + tarifPrice * itemCount
        }, 0)
        return toPrice(price)
    }, [form, tarifs])

    const onInventoryGroup = (prefix: string) => {
        modalService.open({
            props: {size: 'xl'},
            component: (
                <InventoryGroupModal
                    onPost={async (group, count) => {
                        form.setValue(`${prefix}group`, group.id, {shouldDirty: true, shouldValidate: true, shouldTouch: true})
                        form.setValue(`${prefix}count`, count, {shouldDirty: true, shouldValidate: true, shouldTouch: true})
                        modalService.closeModal()
                        return []
                    }}
                />
            ),
        })
    }

    const onPriceAdd = () => {
        const key = keygen('')
        prices.append({period: null, _id: key})
    }

    const onItemAdd = () => {
        const key = keygen('')
        items.append({group: undefined, count: 1, _id: key})
        onInventoryGroup(`items.${items.fields.length}.`)
    }

    const onDeletePrice = (index: number) => {
        const price = form.watch(`prices.${index}`)
        const priceKey = String(price._id)
        const priceId = price.id

        const onRemove = () => {
            const keys = Object.keys(form.watch('tarifs'))
            const newTarifDict = keys.filter(key => key.split('_')[1] !== priceKey).map(key => ({...form.watch(`tarifs.${key}`)}))
            form.reset({...form.watch(), tarifs: Object.fromEntries(newTarifDict.map(dict => [`${dict.inventory_set}_${dict.inventory_set_price}`, dict]))})
            prices.remove(index)
        }

        if (isNil(priceId)) {
            const keys = Object.keys(form.watch('tarifs'))
            form.reset({...form.watch(), tarifs: Object.fromEntries(keys.filter(key => key.split('_')[1] !== priceKey).map(key => [key, form.watch(`tarifs.${key}`)]))})
            prices.remove(index)
            return
        }
        const onConfirm = () => inventorySetPriceService.delete(+id, +priceId).then(onRemove)

        modalService.open({
            component: <ConfirmModal
                message={t('set.edit.delete_set_price')}
                confirm_text={t('common.delete')}
                delete={true}
                onConfirm={onConfirm}
            />
        })
    }

    const onDeleteItem = (index: number) => {
        const item = form.watch(`items.${index}`)
        const itemKey = String(item._id)
        const itemId = item.id

        const onRemove = () => {
            const keys = Object.keys(form.watch('tarifs'))
            const newTarifDict = keys.filter(key => key.split('_')[0] !== itemKey).map(key => ({...form.watch(`tarifs.${key}`)}))
            form.reset({...form.watch(), tarifs: Object.fromEntries(newTarifDict.map(dict => [`${dict.inventory_set}_${dict.inventory_set_price}`, dict]))})
            items.remove(index)
        }

        if (isNil(itemId)) {
            onRemove()
            return
        }
        const onConfirm = () => inventorySetItemsService.delete(+id, +itemId).then(onRemove)

        modalService.open({
            component: <ConfirmModal
                message={t('set.edit.delete_set_group')}
                confirm_text={t('common.delete')}
                delete={true}
                onConfirm={onConfirm}
            />
        })
    }

    useEffect(() => {
        const container = ref.current

        const handleScroll = () => {
            const {scrollWidth, clientWidth, scrollLeft} = container
            setIsRightShadow(clientWidth + scrollLeft + 2 <= scrollWidth)
        }

        container.addEventListener('scroll', handleScroll)
        handleScroll()
        return () => container.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <div className="set-price-table-container">
            <table className="table set-price-table" ref={ref}>
                <thead className="w-full">
                    <tr className="w-full">
                        <th style={{width: 288}}></th>
                        <th className="font-medium" style={{minWidth: 80, maxWidth: 80, textAlign: 'center', verticalAlign: 'middle'}}>
                            {t('common.amount')}
                        </th>
                        {prices.fields.map((price, index) => (
                            <th className="font-medium" key={price.id} style={{minWidth: 288, width: `${100 / prices.fields.length}%`}}>
                                <div className="flex items-center gap-2">
                                    <SharedTimePeriodSelect
                                        className="p-0 flex-1"
                                        name={`prices.${index}.period`}
                                        params={{required: true}}
                                        menuPortalTarget={document.getElementById('vhContent')}
                                        isClearable={false}
                                        isSearchable={false}
                                    />
                                    <Icon icon="delete" className="color-red cursor-pointer p-1" onClick={() => onDeletePrice(index)} />
                                </div>
                            </th>
                        ))}
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="w-full">
                    {items.fields.map((item: any, index) => (
                        <tr key={item.id}>
                            <td className="font-medium" style={{width: 288, minWidth: 288}}>
                                <SharedInventoryGroupSelect
                                    name={`items.${index}.group`}
                                    className="col"
                                    onMenuOpen={() => onInventoryGroup(`items.${index}.`)}
                                    listOptions={() => firstValueFrom(of(EMPTY_LIST))}
                                    menuPortalTarget={document.getElementById('vhContent')}
                                    params={{required: true}}
                                    isSearchable={false}
                                    isClearable={false}
                                />
                            </td>
                            <td style={{width: 80, minWidth: 80}}>
                                <InputNumber
                                    size="large"
                                    min={1}
                                    max={100}
                                    defaultValue={1}
                                    className="form-control"
                                    style={{padding: 0}}
                                    value={form.watch(`items.${index}.count`)}
                                    onChange={val => form.setValue(`items.${index}.count`, val)}
                                />
                            </td>
                            {prices.fields.map((price: any) => (
                                <td key={`${price.id}_${index}`} style={{minWidth: 192}}>
                                    <FormControl
                                        rootclassname="col"
                                        name={`tarifs.${`${item._id}_${price._id}`}.price`}
                                        type="number"
                                        params={{required: true}}
                                        placeholder={t('common.input.price')}
                                        className="form-control w-full"
                                        number_only={+true}
                                        number_decimal={+true}
                                    />
                                </td>
                            ))}
                            {index === 0 && (
                                <td style={{width: 48, minWidth: 48, textAlign: 'center', verticalAlign: 'middle'}} rowSpan={items.fields.length}>
                                    <Icon icon="add" className="btn btn-card py-2" onClick={onPriceAdd} style={{height: '100%'}} />
                                </td>
                            )}
                            <td>
                                <button className="btn btn-red btn-color-white" onClick={() => onDeleteItem(index)}>
                                    <Icon icon="delete" />
                                </button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td style={{width: 192, minWidth: 192}}>
                            <div className="btn btn-card" onClick={onItemAdd}>
                                <Icon icon="add" />{t('set.edit.add_group')}
                            </div>
                        </td>
                        <td />
                        {prices.fields.map((price: any, index) => (
                            <td key={`${price.id}_price`} style={{textAlign: 'center', verticalAlign: "middle"}}>
                                <div className="flex gap-2 items-center">
                                    <FormControl
                                        rootclassname="col"
                                        name={`prices.${index}.name`}
                                        placeholder={t('set.edit.set_price')}
                                        className="form-control w-full"
                                    />
                                    <span className="font-medium text-base">{calculateOverallPrice(price._id)}</span>
                                </div>
                            </td>
                        ))}
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <div className={`set-price-table-shadow ${isRightShadow ? 'show' : ''}`}></div>
        </div>
    )
}
