import axios from 'axios'
import {ListModel} from '../../models/common'
import {ApiAbstract} from '../../abstract/api.abstract'
import {InventoryScheduleModel} from '../../models/manager/inventory/inventory.model'

export class ManageInventoriesScheduleService extends ApiAbstract {
  constructor() {
    super('v1/crm/inventories/schedules')
  }
  public async list(params?: any): Promise<ListModel<InventoryScheduleModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async getBoundaries(): Promise<{min_date: string; max_date: string}> {
    const response = await axios.get(this.getUrl('boundaries'))
    return response.data
  }
}

export default new ManageInventoriesScheduleService()
