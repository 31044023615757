import HeaderContent from "src/components/header/header-content"
import integrationImage from 'src/assets/images/integrations.png'
import {useEffect, useState} from "react"
import {IntegrationModel} from "src/models/integration"
import integrationService from "src/services/integration"
import {ImageField} from "src/components/shared/image-loader/image"
import {Link} from "react-router-dom"
import Icon from "src/components/shared/components/material-icon"
import modalService from "src/components/modal/global/modal.service"
import {IntegrationConnection} from "./connection.modal"
import {useTranslation} from 'react-i18next'
import "./styles.scss"

function IntegrationBanner() {
    const {t} = useTranslation()
    return <div className="integration-banner mb-4">
        <div className="flex gap-2 flex-col color-white integration-banner-text">
            <div className="text-3xl font-bold">{t('integration.integrate_yume')}</div>
            <div className="text-[15px]">{t('integration.description_yume')}</div>
        </div>
        <img src={integrationImage} className="integration-banner-image" alt="" />
    </div>
}

export function IntegrationComponent() {
    const [list, setList] = useState<IntegrationModel[]>([])
    const {t} = useTranslation()

    const onConnect = (integration: IntegrationModel) => {
        modalService.open({component: <IntegrationConnection integration={integration} />})
    }

    useEffect(() => {
        const sub = integrationService.integrations$.subscribe(setList)
        return () => sub.unsubscribe()
    }, [])

    return <>
        <HeaderContent>
            <div className="text-2xl font-semibold header-content">{t('integration.header')}</div>
        </HeaderContent>

        <IntegrationBanner />

        <div className="integration-list">
            {list.map((integration) => (
                <div key={integration.code} className="card-wrapper flex justify-between flex-col gap-2" style={{height: 320}}>
                    <div style={{opacity: integration.disabled ? 0.5 : 1, pointerEvents: integration.disabled ? 'none' : 'inherit'}} className="flex gap-2 flex-col w-full">
                        <div className="flex gal-2 justify-between items-center">
                            <ImageField
                                draggable={false}
                                src={integration.icon}
                                className="w-12 h-12 rounded-lg overflow-hidden object-contain"
                            />
                            {integration.extra && 'is_yume_cloud' in integration.extra && integration.extra['is_yume_cloud'] && (
                                <span className="color-primary fw-semibold">{t('integration.from_yume')}</span>
                            )}
                        </div>
                        <div className="text-xl font-bold">{integration.name}</div>
                        <div className="text-sm" style={{whiteSpace: 'pre-line'}}>{integration.comment}</div>
                        <hr className="color-gray-200 w-full" />
                    </div>

                    {integration.disabled ? (
                        <div className="p-3 border border-gray-100 shadow text-center w-full rounded-lg font-medium leading-5">
                            {t('common.soon')}
                        </div>
                    ) : (
                        integration.connected ? (
                            <div className="flex gap-2 w-full">
                                <div className="btn btn-green-8 gap-1 text-[13px] btn-color-green fw-semibold w-full">
                                    <Icon icon="check" />
                                    {t('common.connected')}
                                </div>
                                <Link to={integration.code} className="btn btn-black text-[13px] fw-semibold w-full">
                                    {t('common.go_to')}
                                </Link>
                            </div>
                        ) : (
                            <div className="flex gap-2 w-full">
                                <button
                                    onClick={() => onConnect(integration)}
                                    className="btn btn-primary btn-color-white fw-semibold w-full"
                                >
                                    {t('common.connect')}
                                </button>
                                <Link to={integration.code} className="btn btn-icon text-[13px] fw-semibold w-full">
                                    {t('common.go_to')}
                                </Link>
                            </div>
                        )
                    )}
                </div>
            ))}
        </div >
    </>
}
