import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from 'react-i18next'
import modalService from "src/components/modal/global/modal.service"
import {SharedInventorySelect} from "src/components/shared/components/select/inventory"
import {FormControl} from "src/components/shared/inputs/form-control"
import geolocationService from "src/services/geolocation.service"

export function DeviceAddModalComponent() {
    const {t} = useTranslation()
    const form = useForm({defaultValues: {name: '', uniqueid: '', inventory_id: null}})
    const {handleSubmit} = form
    const onSave = handleSubmit((payload) => {
        geolocationService.createDevice(payload)
        modalService.closeModal()
    })

    return <FormProvider {...form}>
        <div className="flex flex-col gap-4">
            <h2 className="mb-0 font-semibold text-2xl">{t("gps.add.header")}</h2>
            <FormControl
                name="name"
                rootclassname="w-full"
                className="form-control"
                params={{required: true}}
                label={t("common.input.name")}
                placeholder={t("common.input.name")}
            />
            <SharedInventorySelect
                name="inventory_id"
                className="w-full"
                label={t('common.select.placeholder.inventory')}
                placeholder={t('common.select.placeholder.inventory')}
                params={{required: true}}
                isSearchable={false}
                isClearable={true}
            />
            <FormControl
                name="uniqueid"
                rootclassname="w-full"
                className="form-control"
                params={{required: true}}
                label={t("gps.add.unique_id")}
                placeholder={t("gps.add.unique_id_placeholder")}
            />
            <div className="flex gap-3 justify-end items-center">
                <button className="btn btn-gray-100 btn-color-black">
                    {t("common.back")}
                </button>
                <button className="btn btn-primary btn-color-white" onClick={onSave}>
                    {t("gps.add.connect")}
                </button>
            </div>
        </div>
    </FormProvider>
}
