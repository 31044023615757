import axios from "axios"
import {Centrifuge, Subscription, ConnectedContext, DisconnectedContext, JoinContext, LeaveContext, PublicationContext, SubscribedContext, SubscriptionErrorContext} from "centrifuge"
import {toast} from "react-toastify"
import authService from "./auth.service"
import {firstValueFrom} from "rxjs"

const endpointApi = process.env.REACT_APP_API_BACKEND
const host = window.location.host

const subdomainPattern = /(wss?:\/\/)([^.]+)\.(.*)/
const prod = process.env.NODE_ENV === 'production'
let wssHost = `wss://rent.api.dev.yume.cloud`
if (prod) wssHost = `wss://${host.replace("localhost:3000", endpointApi)}`.replace(subdomainPattern, '$1$2.api.$3')

const cent = new Centrifuge(`${wssHost}/connection/websocket`, {
    getToken: async () => {
        const profile = await firstValueFrom(authService.profile$)
        if (!profile) return Promise.reject()

        const response = await axios.post('v1/crm/communication/connect/')
        const {token} = await response.data
        return token
    }
})
const subscribtions: Record<string, Subscription> = {}

export function connect(
    onConnect: (ctx: ConnectedContext) => void = (ctx) => console.log('Connected to Centrifugo:', ctx),
    onDisconnect: (ctx: DisconnectedContext) => void = (ctx) => console.log('Disconnected from Centrifugo:', ctx)
): void {
    cent.connect()
    cent.on('connected', onConnect)
    cent.on('disconnected', onDisconnect)
}

export function disconnect(): void {
    cent.disconnect()
}

export function newSubsription(
    channel: string = 'notification:yume',
    subscription_api: string = '/v1/crm/communication/subscribe/',
    onPublication: (ctx: PublicationContext) => void = (ctx) => toast(JSON.stringify(ctx.data)),
    onSubscribed: (ctx: SubscribedContext) => void = (ctx) => console.log(`Subscribed to ${ctx.channel}`, ctx),
    onJoin: (ctx: JoinContext) => void = (ctx) => console.log(`Joined ${ctx.channel}:`, ctx.info.user),
    onLeave: (ctx: LeaveContext) => void = (ctx) => console.log(`Leaved ${ctx.channel}:`, ctx.info.user),
    onError: (err: SubscriptionErrorContext) => void = (err) => console.error(`Subscription error on ${err.channel}`, err)
) {
    if (!(channel in subscribtions)) {
        subscribtions[channel] = cent.newSubscription(channel, {
            getToken: async () => {
                const profile = await firstValueFrom(authService.profile$)
                if (!profile) return Promise.reject()

                const response = await axios.post(subscription_api)
                const {token} = await response.data
                return token
            }
        })
    }

    subscribtions[channel].on('publication', onPublication)
    subscribtions[channel].on('subscribed', onSubscribed)
    subscribtions[channel].on('error', onError)
    subscribtions[channel].subscribe()

    subscribtions[channel].on('join', onJoin)
    subscribtions[channel].on('leave', onLeave)
}

export function unsubsribe(channel: string = 'notification:yume') {
    subscribtions[channel].unsubscribe()
}

export default cent