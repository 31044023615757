import moment from "moment"
import {useContext, useEffect, useMemo, useState} from "react"
import {timer} from "rxjs"
import {TenantModel} from "src/services/tenant.service"
import {ModalComponent} from "../modal/global/modal.v2"
import Icon from "../shared/components/material-icon"
import {useLocation} from "react-router-dom"
import subscriptionModalService from "../../services/subscription.service"
import {ConfigContext} from "src"
import clsx from "clsx"

const timer$ = timer(0, 1000)

function Timer({tenant, children}: {tenant: TenantModel, children: JSX.Element}) {
    const [days, setDays] = useState<number>()
    const [showModal, setShowModal] = useState<boolean>(false)

    useEffect(() => {
        const sub = subscriptionModalService.show$.subscribe(({show}) => setShowModal(show))

        const timer = timer$.subscribe(() => {
            const duration = moment.duration(moment(tenant.end_at).valueOf() - moment.now(), 'milliseconds')
            const daysLeft = duration.asDays()
            setDays(daysLeft)

            if (daysLeft <= 0 && !showModal) setShowModal(true)
        })

        return () => {
            sub.unsubscribe()
            timer.unsubscribe()
        }
    }, [])

    if (days && days > 0) return children

    return (
        <>
            <ModalComponent
                component={
                    <div className="flex flex-col gap-8 items-center justify-center pt-4">
                        <div className={'flex justify-center items-center bg-[#F5222D33] h-12 w-12 rounded-md'}>
                            <Icon icon={'error'} className={'text-[#F5222D] h-5 w-5'} />
                        </div>
                        <div className={'flex flex-col items-center gap-4 text-center max-w-96'}>
                            <span className={'font-semibold text-2xl text-test-black'}>Период подписки завершен</span>
                            <span className={'font-normal text-[15px] text-test-black'}>Выберите и оформите подписку на сервис, либо свяжитесь с менеджером для уточнения деталей </span>
                        </div>
                        <div className="flex flex-col gap-4 items-center w-full">
                            <span
                                className="w-full btn btn-primary btn-color-white font-medium"
                                onClick={() => subscriptionModalService.setShow({show: true, type: null})}
                            >
                                Перейти к тарифам
                            </span>
                            <a
                                className="w-full btn btn-icon font-medium text-[15px]"
                                href="https://wa.me/+7779479990"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Связаться с менеджером
                            </a>
                        </div>
                    </div>
                }
                show={showModal}
                removable={false}
            />
            {children}
        </>
    )
}

export default function HeaderPeriodBanner() {
    const {tenant} = useContext(ConfigContext)
    const daysLeft = useMemo(() => tenant && moment(tenant.end_at).diff(moment(), 'days'), [tenant])
    const left = useMemo(() => tenant && moment.duration(moment(tenant.end_at).valueOf() - moment.now(), 'milliseconds').humanize(true), [tenant])
    const url = useLocation()

    return tenant && url.pathname !== "/profile/subscription" && daysLeft < 14 && (
        <Timer tenant={tenant}>
            {daysLeft > 0 && (
                <div className={clsx('flex justify-center items-center min-h-14 gap-1 md:gap-6 p-1', {"bg-[#FF541B]": daysLeft <= 1, "bg-[#FFCA1B]": daysLeft > 1})}>
                    <div className="flex gap-2 items-center">
                        <Icon icon="error" className={clsx('h-5 w-5', daysLeft === 1 ? 'text-white' : 'text-black')} />
                        {daysLeft === 1 ? (
                            <span className={'font-medium text-white'}>Последний день | Оформите подписку и продолжите работу с нашим сервисом</span>
                        ) : (
                            <span className='font-medium text-sm text-black'>
                                Напоминаем, что <span className='text-alert'>{left}</span> подходит к концу ваш текущий период использования сервиса.
                            </span>
                        )}
                    </div>
                    <span
                        className={clsx('btn p-1 text-nowrap', {"btn-black": daysLeft <= 1, "btn-white btn-color-black": daysLeft > 1})}
                        onClick={() => subscriptionModalService.setShow({show: true, type: null})}
                    >
                        Перейти к тарифам
                    </span>
                </div>
            )}
        </Timer>
    )
}
