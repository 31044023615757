import axios from 'axios'
import {ListModel} from '../../models/common'
import {ApiAbstract} from '../../abstract/api.abstract'
import {getAPI} from '../../interceptor'
import {BehaviorSubject, Observable, switchMap} from 'rxjs'
import {SaleFormModel, SaleHistoryModel, SaleInventoryBulkCreateModel, SaleInventoryBulkDeleteModel, SaleInventoryBulkUpdateModel, SaleInventoryModel, SaleModel} from "src/models/manager/order/order-sale.model"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"

export interface OrderAction {
  status: number
  order_inventories?: number[]
}

export class SaleService extends ApiAbstract {
  constructor() {
    super('v1/crm/sales')
  }

  refresh$: BehaviorSubject<void> = new BehaviorSubject<void>(null)

  public async getCount(params?: any): Promise<any> {
    const response = await axios.get(this.getUrl('counts'), {params})
    return response.data
  }
  public async list(params?: any): Promise<ListModel<SaleModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async listHistory(params?: any): Promise<ListModel<SaleHistoryModel>> {
    const response = await axios.get(this.getUrl('history'), {params})
    return response.data
  }

  get(id: number): Observable<SaleModel> {
    return this.refresh$.pipe(switchMap(() => getAPI(axios.get(this.getUrl(id)))))
  }

  public async post(payload: SaleFormModel): Promise<SaleModel> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async patch(id: number, payload: SaleFormModel): Promise<SaleModel> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }
  public async delete(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
  public async confirm(id: number): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${id}/confirm`))
    return response.data
  }
  public async listInventories(id: number, params?: any): Promise<SaleInventoryModel[]> {
    const response = await axios.get(this.getUrl(`${id}/inventories`), {params})
    return response.data
  }
  public async listGroups(id: number, params?: any): Promise<InventoryGroupModel[]> {
    const response = await axios.get(this.getUrl(`${id}/groups`), {params})
    return response.data
  }
  public async bulkCreate(orderId: number, payload: SaleInventoryBulkCreateModel): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${orderId}/inventories/bulk_create`), payload)
    return response.data
  }
  public async bulkUpdate(orderId: number, payload: SaleInventoryBulkUpdateModel): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${orderId}/inventories/bulk_update`), payload)
    return response.data
  }
  public async bulkDelete(orderId: number, payload: SaleInventoryBulkDeleteModel): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${orderId}/inventories/bulk_delete`), payload)
    return response.data
  }
}

const saleService = new SaleService()
export default saleService
