import {useTranslation} from 'react-i18next'
import {useContext, useEffect, useState} from "react"
import {Segmented} from "src/components/shared/antd-custom"
import subscriptionModalService, {SubscriptionModalType} from "src/services/subscription.service"
import tenantService, {Subscription, SubscriptionPeriod, TenantModel} from "src/services/tenant.service"
import Icon from "src/components/shared/components/material-icon"
import moment from "moment"
import {ConfigContext} from "src"
import clsx from "clsx"
import {priceBeautify} from "src/utils/price"

type CURRENCY = 'USD' | 'KZT'

function Permission({permitted, children}: {permitted: boolean, children: JSX.Element | string}) {
    return <div className="flex gap-2 items-center text-[15px]">
        {permitted ? <Icon icon="check" className="color-green" /> : <Icon icon="close" className="color-red" />}
        {children}
    </div>
}

const currencyFormatter: Record<CURRENCY, string> = {'USD': "$", 'KZT': '₸'}
const defaultMonth = 1

function TenantSubscriptionTarif({
    subscription,
    currency,
    option,
    setOption,
}: {
    subscription: Subscription,
    currency: CURRENCY,
    option,
    setOption,
}) {
    const {t, i18n} = useTranslation()
    const {tenant} = useContext(ConfigContext)
    const end_at = tenant && tenant.end_at
    const current = tenant && tenant.subscription && tenant.subscription.id === subscription.id

    const options = subscription.periods.filter(period => period.currency === currency).map((period) => {
        const label = moment.duration(period.months, 'months').add(period.days, 'days').humanize()
        return {label, className: 'w-full py-1', value: period.months}
    })

    const tarifMap: Record<number, SubscriptionPeriod> = Object.fromEntries(
        subscription.periods
            .filter(period => period.currency === currency)
            .map((period) => [period.months, period])
    )

    const limit = subscription.limits
    if (!tarifMap[option] || !tarifMap[defaultMonth]) return <></>

    const onSubscriptionPayment = async () => {
        const ret = await tenantService.createInvoice({
            subscription: subscription.id,
            period: tarifMap[option].id,
            phone: "+77077121500",
            success_url: window.location.href,
            extra: {}
        })
        window.location.replace(ret.invoice.extra.wooppay_link)
    }
    const initialPrice = priceBeautify(tarifMap[defaultMonth].price / tarifMap[defaultMonth].months, 0)
    const currentPrice = priceBeautify(tarifMap[option].price / tarifMap[option].months, 0)

    return (
        <div className={clsx('min-w-64 flex flex-col flex-1 gap-6 px-6 py-5 shadow rounded-lg', subscription.name === 'Pro' ? 'border-[1.5px] border-primary' : 'border border-gray-100')}>

            <div className="flex flex-col gap-[6px]">
                <div className="text-2xl text-test-black font-bold flex gap-2 items-center">
                    {subscription.name}
                    {subscription.name === 'Pro' && (
                        <span className="text-primary text-xs font-medium bg-[#A165FD] bg-opacity-20 rounded-md py-1 px-2">Популярно</span>
                    )}
                </div>

                <div className="text-sm font-medium text-test-black gap-2">
                    {option !== 1 && (
                        <span className="text-alert line-through">
                            {currencyFormatter[currency]}{initialPrice}
                        </span>
                    )}
                    &nbsp;
                    <span className="text-[14px]">{currencyFormatter[currency]}{currentPrice}</span>
                    <span className="font-medium text-sm"> / {t("profile.subscription.tarif.monthly")}</span>
                </div>

                {current && (
                    <span className={'text-gray-test-350 font-normal text-[13px]'}>
                        Действительно до {moment(end_at).format("DD.MM.YYYY")}
                    </span>
                )}
            </div>

            {options.length > 0 && (
                current ? (
                    <button className="btn text-[#52C41A] bg-[#52C41A] bg-opacity-20 font-medium hover:text-[#52C41A] " style={{height: 44}}>
                        {t("profile.subscription.tarif.current")}
                    </button>
                ) : subscription.name !== 'Freemium' && subscription.name !== 'Enterprise' && (
                    <Segmented<number>
                        value={option}
                        onChange={setOption}
                        style={{background: 'var(--color-bg-light)', padding: 4, borderRadius: 6}}
                        options={options}
                    />
                )
            )}

            {subscription.name === 'Enterprise' && (
                <button className='btn btn-black'>
                    Связаться с нами
                </button>
            )}

            {!current && subscription.name !== 'Freemium' && subscription.name !== 'Enterprise' && (
                <button
                    className={`btn btn-primary btn-color-white ${subscription.name !== 'Pro' && 'bg-gray-test-70 text-test-black'}`}
                    onClick={onSubscriptionPayment}
                >
                    {t("common.connect")}
                </button>
            )}

            {limit && (
                <div className="flex flex-col gap-2 flex-1">
                    <Permission permitted={true}>
                        {limit.rental_point !== 'unlimited' ?
                            <span>
                                <span
                                    className="font-semibold">{limit.rental_point} {t("profile.subscription.tarif.rental_point1")}{(i18n.language === 'ru' && limit.rental_point > 1) && 'а'}
                                </span> {t("profile.subscription.tarif.rental_point2")}{(i18n.language === 'en' && limit.rental_point > 1) && 's'}
                            </span>
                            :
                            <span>Пункты проката
                                <span
                                    className="font-semibold"> без лимита
                                </span>
                            </span>
                        }
                    </Permission>
                    <Permission permitted={true}>
                        {limit.staff !== 'unlimited' ?
                            <span>
                                <span
                                    className="font-semibold">{limit.staff} {limit.staff > 1 ? t("profile.subscription.tarif.accounts") : t("profile.subscription.tarif.account")}
                                </span> {limit.staff > 1 ? t("profile.subscription.tarif.for_staff") : t("profile.subscription.tarif.for_staffs")}
                            </span>
                            :
                            <span>Аккаунты
                                <span
                                    className="font-semibold"> без лимита
                                </span>
                            </span>
                        }

                    </Permission>
                    <Permission permitted={true}>
                        {limit.inventory_group === 'unlimited' ? (
                            <span>{t("profile.subscription.tarif.product_count")} <span className="font-semibold">{t("profile.subscription.tarif.unlimited")}</span></span>
                        ) : (
                            <span><span className="font-semibold">{limit.inventory_group} {+limit.inventory_group > 1 ? t("profile.subscription.tarif.position") : t("profile.subscription.tarif.positions")} </span> {+limit.inventory_group > 1 ? t("profile.subscription.tarif.product") : t("profile.subscription.tarif.products")}</span>
                        )}
                    </Permission>
                    <Permission permitted={true}>
                        {limit.request === 'unlimited' ? (
                            <span>{t("profile.subscription.tarif.rent_count")} <span className="font-semibold">{t("profile.subscription.tarif.unlimited")}</span></span>
                        ) : (
                            <span><span className="font-semibold">{limit.request} {+limit.request > 1 ? t("profile.subscription.tarif.rent") : t("profile.subscription.tarif.rentals")}</span> {t("profile.subscription.tarif.monthly")}</span>
                        )}
                    </Permission>

                    <Permission permitted={limit.sale} children={t("profile.subscription.tarif.accounting_rent_and_prod")} />
                    <Permission permitted={limit.sale} children={t("profile.subscription.tarif.accounting_sells")} />
                    <Permission permitted={limit.document} children={t("profile.subscription.tarif.document")} />
                    <Permission permitted={limit.metrics} children={t("profile.subscription.tarif.metrics")} />
                    <Permission permitted={limit.bonus} children={t("profile.subscription.tarif.bonus")} />
                    <Permission permitted={limit.custom_fields} children={t("profile.subscription.tarif.custom_fields")} />
                </div>
            )}
        </div>
    )
}

export function TenantSubscriptionModal() {
    const [option, setOption] = useState<number>(6)
    const [subs, setSubs] = useState<Subscription[]>([])
    const [currency, setCurrency] = useState<CURRENCY>('KZT')
    const {t} = useTranslation()

    useEffect(() => {
        const sub = tenantService.subscriptions$.subscribe(setSubs)
        return () => sub.unsubscribe()
    }, [])

    return option && (
        <div className="flex flex-col gap-0 w-100">
            <div className="py-8 border-b border-gray-100">
                <div className="px-8">
                    <div className="flex gap-2 justify-between items-start mb-3">
                        <h3 className="text-3xl font-bold mb-0">{t('profile.subscription.title')}</h3>
                        <Segmented<'USD' | 'KZT'>
                            value={currency}
                            onChange={setCurrency}
                            style={{background: 'var(--color-bg-light)', padding: 4, borderRadius: 6}}
                            options={[
                                {label: 'USD', value: 'USD'},
                                {label: 'KZT', value: 'KZT'}
                            ]}
                        />
                    </div>
                    <div className="text-[15px]">{t('profile.subscription.free')}</div>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row overflow-x-scroll gap-3 p-3 md:gap-6 md:p-8">
                {subs.map(sub => (
                    <TenantSubscriptionTarif
                        key={sub.id}
                        subscription={sub}
                        option={option}
                        setOption={setOption}
                        currency={currency}
                    />
                ))}
            </div>
        </div>
    )
}
