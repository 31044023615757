import axios from 'axios'
import {ApiAbstract} from '../abstract/api.abstract'

export class ImageUploadService extends ApiAbstract {
    constructor() {
        super('v1/crm/images')
    }

    public async post(image: File): Promise<{image_url: string}> {
        const formData = new FormData()
        formData.append('image', image)
        const response = await axios.post(this.getUrl(), formData)
        return response.data
    }
}

const imageUploadService = new ImageUploadService()
export default imageUploadService
