import {useContext, useMemo} from 'react'
import {Link} from 'react-router-dom'
import Icon from "src/components/shared/components/material-icon"
import {ORDER_STATUS_NUMBER_CODE, ORDER_STATUS_NUMBER_NAME} from "src/components/shared/constants"
import {invertColor} from "src/utils/color/color-invert"
import {useTranslation} from 'react-i18next'
import {ConfigContext} from "src"

export function RequestStatus({requestId, requestStatus}: {requestId: number, requestStatus: number}) {
    const {t} = useTranslation()
    const {settings} = useContext(ConfigContext)
    const color = useMemo(() => settings[ORDER_STATUS_NUMBER_CODE[requestStatus]], [settings, requestStatus])

    return requestId && requestStatus && (
        <Link
            to={`/orders/${requestId}/all`}
            className="flex items-center md:justify-center w-full px-2 py-1 gap-3 rounded-lg"
            style={{backgroundColor: color, color: invertColor(color)}}
        >
            <span className="font-medium">{t(ORDER_STATUS_NUMBER_NAME[requestStatus])}</span>
            <Icon icon="chevron_right" />
        </Link>
    )
}
