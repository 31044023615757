import React, {useCallback, useContext, useEffect, useState} from "react"
import widgetService from "src/services/widget.service"
import {ProductResult} from "src/models/manager/website"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {FormProvider, useForm, useWatch} from "react-hook-form"
import {ImageField} from "src/components/shared/image-loader/image"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import {ManageInventorySetService} from "src/services/inventory/inventory-set.service"
import modalService from "src/components/modal/global/modal.service"
import {EMPTY_LIST, ListModel} from "src/models/common"
import Table from "src/components/shared/antd-custom/table";
import {ImageInputField} from "src/components/shared/components/image-field";
import ModalContent from "./modal-content";
import IframeGenerator from "./iframeGenerate";
import tenantService from "src/services/tenant.service";
import {ConfigContext} from "src/index";
import {useTranslation} from 'react-i18next'

const ProductPublishedList = () => {
    const inventorySetService = new ManageInventorySetService()
    const [list, setList] = useState<ListModel<ProductResult>>(EMPTY_LIST)
    const form = useForm<any>({defaultValues: {page: 1, pageSize: 10}})
    const {setValue, watch} = form
    const values = watch()
    const logo = useWatch({ control: form.control, name: 'logo' })
    const [isEditing, setIsEditing] = useState(false);
    const {tenant} = useContext(ConfigContext)
    const {t} = useTranslation()

    const getProduct = async (params = { page: values.page, pageSize: values.pageSize }) => {
        widgetService
            .list(params)
            .then(setList)
            .catch(e => e.response?.status === 404 && setValue('page', 1))
    };

    const updateProductList = (itemId: number, type: 'set' | 'group', remove: boolean) => {
        if (itemId === undefined) return
        const payload = { published: remove }

        const service = type === 'group' ? inventoryGroupService : inventorySetService
        service.patch(itemId, payload)
            .then(() => getProduct({ page: values.page, pageSize: values.pageSize }))
    };

    const checkAndUpdateProduct = useCallback((product: any, remove: boolean) => {
        if (product.set_id) {
            updateProductList(product.set_id, 'set', remove)
        } else if (product.group_id) {
            updateProductList(product.group_id, 'group', remove)
        }
    }, [updateProductList]);

    const columns = [
        {
            title: t('website.widget.image'),
            key: "image",
            render: (_, {name, image}) => <div className="flex gap-2 items-center">
                <ImageField className="w-20 h-20" src={image} />
                <div>{name}</div>
            </div>,
        }
    ];

    const onShowModal = (type) => {
        const isGroup = type === 'group';
        modalService.open({
            props: {size: 'lg'},
            component: (
                <ModalContent isGroup={isGroup} getProduct={getProduct}/>
            ),
        })
    }
    const onSubmitImage = async () => {
        if (!logo || logo === tenant.logo) return
        const formData = new FormData()
        formData.append("logo", logo)
        try {
            await tenantService.sendLogo(formData)
            setIsEditing(true)
        } catch (error) {
            console.log('Произошла ошибка', error)
        }
    }

    useEffect(() => {
        getProduct()
    }, [])

    useEffect(() => {
        const subscription = watch((values) => {
            getProduct({page: values.page, pageSize: values.pageSize})
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        if (tenant.logo) {
            setValue("logo", tenant.logo)
            setIsEditing(true)
        }
    }, [tenant.logo, setValue])

    return (
        <FormProvider {...form}>
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4 p-6 bg-white shadow-sm rounded-lg">
                    <div className="flex justify-between">
                        <div className="flex flex-col gap-4">
                            <ImageInputField name="logo" />
                            <span>{isEditing ? t('website.widget.edit_logo') : t('website.widget.add_logo')}</span>
                            <button onClick={onSubmitImage} className="btn btn-primary btn-color-white">
                                {t('website.widget.send_logo')}
                            </button>
                        </div>
                    </div>
                </div>
                <Table
                    title={() => (
                        <div className="flex gap-2 justify-end">
                            <button
                                className="px-5 py-3 rounded-lg btn-primary text-white font-semibold text-sm"
                                onClick={() => onShowModal('group')}>{t('website.widget.add_group')}</button>
                            <button
                                className="px-5 py-3 rounded-lg btn-primary text-white font-semibold text-sm "
                                onClick={() => onShowModal('set')}>{t('website.widget.add_set')}</button>
                        </div>
                    )}
                    columns={columns}
                    dataSource={list.results}
                    rowKey="id"
                    onChange={(pagination) => setValue('page', pagination.current)}
                    onDelete={(data) => checkAndUpdateProduct(data, false)}
                />
                <PaginationFormComponent count={list.count}/>
                {/*<IframeGenerator/>*/}
            </div>

        </FormProvider>
    )
}

export default ProductPublishedList
