import axios from 'axios'
import {CrudInterface} from '../../interfaces/crud.interface'
import {ListModel} from '../../models/common'
import {ApiAbstract} from '../../abstract/api.abstract'
import {InventoryGroupModel, InventoryGroupStateModel} from '../../models/manager/inventory/inventory-group.model'
import {BaseInventoryModel} from 'src/models/manager/inventory/inventory.model'
import {isNil} from "src/utils/isNil"
import {InventoryTarifModel} from "src/models/manager/inventory/inventory-tarif.model"

export class ManageInventoryGroupService extends ApiAbstract implements CrudInterface<InventoryGroupModel> {
  constructor() {
    super('v1/crm/groups')
  }

  public async list(params?: any): Promise<ListModel<InventoryGroupModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async listStates(params?: any): Promise<InventoryGroupStateModel[]> {
    const response = await axios.get(this.getUrl('states'), {params})
    return response.data
  }
  public async get(id: number): Promise<InventoryGroupModel> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
  public async post(payload: any): Promise<InventoryGroupModel> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async patch(id: number, payload: any): Promise<InventoryGroupModel> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }
  public async listTarifs(id: number, params?: any): Promise<InventoryTarifModel[]> {
    const response = await axios.get(this.getUrl(`${id}/tarifs`), {params})
    return response.data
  }
  public async getTarif(id: number, tarifId: number): Promise<InventoryTarifModel> {
    const response = await axios.get(this.getUrl(`${id}/tarifs/${tarifId}`))
    return response.data
  }
  public async patchTarif(id: number, tarifId: number, payback: any): Promise<InventoryTarifModel> {
    const response = await axios.patch(this.getUrl(`${id}/tarifs/${tarifId}`), payback)
    return response.data
  }
  public async deleteTarif(id: number, tarifId: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${id}/tarifs/${tarifId}`))
    return response.data
  }
  public async createTarifs(id: number, payload: any): Promise<InventoryTarifModel> {
    const response = await axios.post(this.getUrl(`${id}/tarifs`), payload)
    return response.data
  }
  public async publish(id: number): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${id}/publish`), {})
    return response.data
  }
  public async archive(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${id}/archive`))
    return response.data
  }
  public async delete(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
  public async listInventories(id: number, params?: any): Promise<ListModel<BaseInventoryModel>> {
    const response = await axios.get(this.getUrl(`${id}/inventories`), {params})
    return response.data
  }
  public async bulkCreateInventories(id: number, payload: any): Promise<any> {
    if (isNil(payload.buy_price)) payload.buy_price = 0
    const response = await axios.post(this.getUrl(`${id}/inventories/bulk_create`), payload)
    return response.data
  }
}

const inventoryGroupService = new ManageInventoryGroupService()
export default inventoryGroupService
