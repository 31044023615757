import {useContext} from 'react'
import {MapContext} from "./map"
import {ImageField} from "src/components/shared/image-loader/image"
import Icon from "src/components/shared/components/material-icon"
import {Tooltip} from "antd"
import {Link} from "react-router-dom"
import modalService from "src/components/modal/global/modal.service"
import {DeviceAddModalComponent} from "./add.modal"
import {useTranslation} from 'react-i18next'

export function DeviceListComponent() {
    const {t} = useTranslation()
    const {devices, onDeviceFocus} = useContext(MapContext)

    const onAdd = () => {
        modalService.open({
            component: <DeviceAddModalComponent />
        })
    }

    return <>
        <div className="flex justify-between gap-2 items-center border-b border-gray-100 p-5">
            <h3 className="font-semibold text-[22px]">{t('gps.devices')}</h3>
            <div className="flex gap-2 items-center text-blue-500 font-medium cursor-pointer" onClick={onAdd}>
                <Icon icon="add" />
                {t("common.add")}
            </div>
        </div>
        <div className="flex flex-col justify-stretch">
            {devices.map((device) => (
                <div key={device.id} className="flex justify-between items-center border-b border-gray-100">
                    <div className="flex gap-5 items-center cursor-pointer p-5" onClick={() => onDeviceFocus(device)}>
                        <ImageField src={device.inventory.image} className="w-12 h-12 rounded-md" />
                        <div className="flex flex-col gap-1">
                            <div className="font-medium text-[15px]">{device.name}</div>
                            <div className="text-gray-400 text-[13px]">{device.inventory.name} / {device.inventory.unique_id}</div>
                            {device.status && device.status.includes('online') && <div className="text-accept font-medium">{t('gps.online')}</div>}
                        </div>
                    </div>
                    <Tooltip placement="bottom" title="Поездки" className="m-5 ml-0">
                        <Link to={`/geolocation/${device.id}/trips`} className="btn btn-card">
                            <Icon icon="route" className="text-xl" />
                        </Link>
                    </Tooltip>
                </div>
            ))}
        </div>
    </>
}