import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {Observable, firstValueFrom, map} from "rxjs"
import appService from "src/services/app.service"
import {InventoryStateStatus} from "src/models/manager/constants"
import {useTranslation} from 'react-i18next'
import {StylesConfig} from 'react-select'
import {colourStyles} from 'src/components/shared/inputs/form-select-color-style'
import {hexToRgbA} from 'src/utils/color/hex-to-rgba'
import {invertColor} from "src/utils/color/color-invert"


type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue'
const states: Observable<InventoryStateStatus[]> = appService.getConstant('INVENTORY_STATE_STATUS')

const selectStyle: StylesConfig<InventoryStateStatus, true> = {
    ...colourStyles(false),
    option: (styles, props) => {
        const {data, isDisabled, isFocused, isSelected} = props
        const backgroundColor = () => {
            if (isDisabled) return hexToRgbA(data.color, 0.2)
            if (isSelected) return hexToRgbA(data.color)
            if (isFocused) return hexToRgbA(data.color, 0.8)
            return hexToRgbA(data.color, 0.6)
        }
        const color = () => {
            if (isDisabled) return 'var(--color-black)'
            return invertColor(data.color)
        }
        return {
            ...colourStyles(false).option(styles, props),
            backgroundColor: backgroundColor(),
            color: color()
        }
    }
}

export function SharedInventoryStateSelect(config: Omit<FormSelectAsyncConfig<InventoryStateStatus>, RemovalKeys>) {
    const {t} = useTranslation()
    return <FormSelectAsync<InventoryStateStatus>
        {...config}
        // styles={selectStyle}
        listOptions={() => states}
        placeholder={t('common.select.state_status')}
        getValue={(id: number) => firstValueFrom(states.pipe(map(list => list.find((obj: InventoryStateStatus) => obj.id === id))))}
        getOptionLabel={(option: InventoryStateStatus) => (option ? String(option.name) : null)}
        getOptionValue={(option: InventoryStateStatus) => (option ? String(option.id) : null)}
    />
}
