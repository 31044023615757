import {StringMap, TOptions} from "i18next"

export type UserSalaryType = 'creations' | 'reservations' | 'issues' | 'receivements'

export const salaryNameMap: Record<UserSalaryType, TOptions<StringMap> | string> = {
  creations: 'settings.user_actions.creations',
  reservations: 'settings.user_actions.reservations',
  issues: 'settings.user_actions.issues',
  receivements: 'settings.user_actions.receivements'
}

export interface UserSalaryTypeEarningModel {
  request_id: number
  request_status: number
  type: UserSalaryType
  amount: number
  earning: number
  dates: string[]
}

export interface UserSalaryDetailModel extends Record<UserSalaryType, {
  count: number
  amount: number
  earning: number
}> { }
