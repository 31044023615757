import React, {useContext, useMemo, useRef, useState} from "react"
import Modal from "react-bootstrap/Modal"
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import Icon from "src/components/shared/components/material-icon"
import {ConfigContext} from "../../../index"
import {isNil} from "src/utils/isNil"

export const ModalComponent = (config: ModalConfig & {show: boolean, removable?: boolean, index?: string}) => {
  const removable = config.removable !== undefined ? config.removable : true
  const [dragOffset, setDragOffset] = useState(0)
  const {isMobile} = useContext(ConfigContext)
  const startY = useRef(null)

  const component = useMemo(() => {
    if (isNil(config.index)) return config.component
    if (Array.isArray(config.component)) return config.component
    return React.cloneElement(config.component, {id: config.index})
  }, [config.component, config.index])

  const handleModalToggle = () => {
    modalService.closeModal(config.index)
    if (config.onHide !== undefined) config.onHide()
  }

  const style = useMemo(() => {
    if (!config.show) return {}
    if (!isMobile) return {}
    return {
      transform: `translateY(${dragOffset}px)`,
      transition: dragOffset === 0 ? "transform 0.3s ease-out" : "none"
    }
  }, [config.show, isMobile, dragOffset])

  const handleTouchStart = (e) => {
    if (!isMobile) return
    startY.current = e.touches[0].clientY
  }

  const handleTouchMove = (e) => {
    if (!isMobile) return
    const currentY = e.touches[0].clientY
    const offset = currentY - startY.current
    setDragOffset(Math.max(0, offset))
  }

  const handleTouchEnd = () => {
    if (!isMobile) return
    if (dragOffset > 100) handleModalToggle()
    setDragOffset(0)
  }

  return (
    <Modal
      {...config.props}
      show={config.show}
      onHide={config.props?.backdrop ? () => undefined : handleModalToggle}
      centered={true}
      dialogClassName={config.props?.dialogClassName}
      style={style}
    >
      <Modal.Body>
        {(removable) && (
          <div className="modal-remove" onClick={handleModalToggle}>
            <Icon icon="close" />
          </div>
        )}
        {isMobile && (
          <div
            className="modal-grab-indicator"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          />
        )}
        {component}
      </Modal.Body>
    </Modal>
  )
}

export default ModalComponent
