import {BaseInventoryModel} from "./manager/inventory/inventory.model"

export enum PositionAttribute {
    batteryLevel = 'batteryLevel',
    distance = 'distance',
    totalDistance = 'totalDistance',
    motion = 'motion',
}

export interface DeviceModel {
    id: number
    name: string
    uniqueid: string
    status: string
    disabled: boolean
    lastupdate: string
    position: PositionModel
    phone: any
    model: any
    contact: any
    category: any
    attributes: string
    inventory: BaseInventoryModel
}

export interface PositionModel {
    id: number
    device: number
    protocol: string
    devicetime: string
    fixtime: string
    servertime: string
    valid: boolean
    latitude: number
    longitude: number
    altitude: number
    speed: number
    course: number
    address: any
    accuracy: number
    network: string
    geofenceids: string
    attributes: Record<PositionAttribute, string | number>
}

interface TripPositionModel {
    id: number
    protocol: string
    fixtime: string
    speed: number
    course: number
    valid: boolean
    latitude: number
    longitude: number
    altitude: number
    attributes: Object
    address: string
    accuracy: number
}

export interface TripModel {
    id: number
    device_id: number
    distance: number
    duration: string
    speed: number
    start: TripPositionModel
    end: TripPositionModel
}