import {Outlet, useNavigate} from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'
import HeaderContent from "src/components/header/header-content"
import {ConfigContext} from "src"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import deliveryService from "src/services/delivery.service"
import {OrderRequestDeliveryCountModel} from "src/models/manager/order/order-request-delivery.model"
import {Segmented} from "src/components/shared/antd-custom"
import clsx from "clsx"

export default function DeliveryWrapperComponent() {
    const navigate = useNavigate()
    const [counts, setCounts] = useState<OrderRequestDeliveryCountModel>()
    const {settings} = useContext(ConfigContext)
    const activeRoute = useCurrentRoute()

    useEffect(() => {
        deliveryService.getCount().then(setCounts)
    }, [])

    return settings && <>
        <HeaderContent>
            <div className="header-content flex flex-col items-start gap-3 pt-4 pb-2">
                <div className="font-semibold text-[22px] text-test-black">Мои заказы</div>
                <Segmented
                    options={[
                        {
                            value: 'pending',
                            className: 'flex justify-center flex-1 p-2 font-medium leading-4',
                            label: <div className={clsx("flex items-center gap-2", {"text-primary": activeRoute === 'pending'})}>
                                <span>Новые запросы</span>
                                <div className={clsx("min-w-3 min-h-3 p-1 rounded leading-3", {"bg-primary bg-opacity-20": activeRoute === 'pending'})}>

                                    {counts ? counts.pending : 0}
                                </div>
                            </div>,
                        },
                        {
                            value: 'inprocess',
                            className: 'flex justify-center flex-1 p-2 font-medium leading-4',
                            label: <div className={clsx("flex items-center gap-2", {"text-primary": activeRoute === 'inprocess'})}>
                                <span>В работе</span>
                                <div className={clsx("min-w-3 min-h-3 p-1 rounded leading-3", {"bg-primary bg-opacity-20": activeRoute === 'inprocess'})}>
                                    {counts ? counts.in_progress : 0}
                                </div>
                            </div>,
                        },
                    ]}
                    className="w-full bg-gray-100 self-center mr-auto"
                    onChange={(key: string) => navigate(`/deliveries/${key}`, {relative: 'path'})}
                    style={{background: 'var(--color-bg-light)', padding: 4}}
                    value={activeRoute}
                />
            </div>
        </HeaderContent>
        <Outlet />
    </>
}
