import {InputHTMLAttributes} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'
import InputMask, {Props} from 'react-input-mask'

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement>, Props {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

export function FormControlMask(config: FormControlConfig) {
  const {
    register,
    watch,
    formState: {errors},
  } = useFormContext()
  const error = errors[config.name]

  const className = `${config.className ? config.className : ''} ${error ? 'invalid' : ''}`

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <InputMask
        {...config}
        {...register(config.name, config.params)}
        value={watch(config.name) || ''}
        className={className}
        autoComplete="off"
      />
    </div>
  )
}
