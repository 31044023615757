import Table from "src/components/shared/antd-custom/table"
import React, {useEffect, useState} from 'react'
import {DndContext} from '@dnd-kit/core'
import {restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import type {DragEndEvent} from '@dnd-kit/core'
import type {ColumnsType} from 'antd/es/table'
import websiteService from "src/services/website.service"
import {ArticleModel} from "src/models/manager/website"
import modalService from "src/components/modal/global/modal.service"
import ArticleEdit from "./edit"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from 'react-i18next';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

const Row = ({children, ...props}: RowProps) => {
  const record: ArticleModel = children && children[0]?.props.record
  const [toggle, setToggle] = useState(record?.active || false)
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && {...transform, scaleY: 1}),
    transition,
    ...(isDragging ? {position: 'relative', zIndex: 99} : {}),
  }

  const onToggle = async () => {
    const res = await websiteService.updateArtice(record.id, {active: !toggle})
    setToggle(res.active)
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        const element: React.ReactElement = child as React.ReactElement

        if (element.key === 'sort') {
          return React.cloneElement(element, {
            children: <Icon icon="menu" className="btn btn-icon" ref={setActivatorNodeRef} {...listeners} />
          })
        }

        if (element.key === 'active') {
          return React.cloneElement(element, {
            children: <input checked={toggle} onChange={onToggle} type="checkbox" className="form-check-input" style={{marginTop: 12, marginBottom: 12}} autoComplete="off" />
          })
        }

        return child
      })}
    </tr>
  )
}

const WebsiteArticles = () => {
  const {t}=useTranslation()
  const [list, setList] = useState<ArticleModel[]>([])

  const onArticle = (article: ArticleModel) => {
    modalService.open({
      props: {size: 'lg', backdrop: true},
      component: <ArticleEdit article={article} postSubmit={() => websiteService.listArtices().then(setList)} />
    })
  }

  const onCreateModal = () => {
    modalService.open({
      props: {size: 'lg', backdrop: true},
      component: <ArticleEdit postSubmit={() => websiteService.listArtices().then(setList)} />
    })
  }

  const columns: ColumnsType<ArticleModel> = [
    {
      width: '1%',
      key: 'sort',
    },
    {
      title: t('website.articles.name'),
      key: 'title',
      dataIndex: 'title',
      render: title => title,
    },
    {
      width: '1%',
      title: t('website.articles.active'),
      key: 'active',
    },
    {
      width: '1%',
      title: '',
      key: 'action',
      render: (_, record) => (
        <button onClick={() => onArticle(record)} className="btn btn-card">
          <Icon className="text-lg" icon="edit" />
        </button>
      )
    },
  ]

  const onDragEnd = ({active, over}: DragEndEvent) => {
    if (active.id !== over?.id) {
      setList((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id)
        const overIndex = previous.findIndex((i) => i.id === over?.id)
        websiteService.updateArtice(active.id as number, {order: overIndex + 1})
        return arrayMove(previous, activeIndex, overIndex)
      })
    }
  }

  useEffect(() => {
    websiteService.listArtices().then(setList)
    return () => setList([])
  }, [])

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={list}
        strategy={verticalListSortingStrategy}
      >
        <div className="flex gap-3 items-center mb-3">
          <div className="text-xl font-semibold">{t('website.pages.advertisement')}</div>
          <button className="btn btn-add" onClick={onCreateModal}>
            <Icon icon="add" />
          </button>
        </div>
        <Table
          components={{body: {row: Row}}}
          rowKey={obj => obj.id}
          columns={columns}
          dataSource={list}
        />
      </SortableContext>
    </DndContext>
  )
}

export default WebsiteArticles
