import {CategoryModel} from "src/models/manager/constants"
import {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {TreeNodeProps, TreeSelect} from "antd"
import {buildTree} from "src/utils/list-to-tree"
import React, {useContext} from "react"
import {ConfigContext} from "src"
import Icon from "../material-icon"
import {useFormContext} from "react-hook-form"

type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue' | 'modalProps'
// const inventoryCategories: Observable<CategoryModel[]> = appService.getConstant('INVENTORY_CATEGORIES')

interface CategorySelectConfig extends Omit<FormSelectAsyncConfig<CategoryModel>, RemovalKeys> {
    addOption?: boolean
}

export function SharedCategorySelect(config: CategorySelectConfig) {
    // const service = new GenericEntityService<CategoryModel>('v1/crm/categories')
    // const {t} = useTranslation()
    const {constants} = useContext(ConfigContext)
    const form = useFormContext()
    const {watch, setValue} = form
    const value = watch(config.name)

    // const onCreate = async payload => {
    //     payload.comment = payload.comment || null
    //     const res = await service.post(payload)
    //     appService.loadConstants()
    //     return res
    // }

    // const modalProps: ModalConfig = {
    //     props: {size: 'lg', backdrop: true},
    //     component: (
    //         <ConfigAddOrEdit<CategoryModel>
    //             onSubmit={inventory => onCreate(inventory).then(() => modalService.closeModal())}
    //             buttonTitle={t('common.add')}
    //             title={t('configuration.category.header_add')}
    //             mode="add"
    //         >
    //             <FormControl
    //                 name="name"
    //                 label={t('common.input.name')}
    //                 className="form-control"
    //                 rootclassname="col"
    //                 params={{required: true}}
    //                 required
    //             />
    //         </ConfigAddOrEdit>
    //     )
    // }

    // return <FormSelectAsync<CategoryModel>
    //     {...config}
    //     isSearchable
    //     modalProps={config.addOption && modalProps}
    //     listOptions={() => inventoryCategories}
    //     getValue={(id: number) => firstValueFrom(inventoryCategories.pipe(map(list => list.find((obj: CategoryModel) => obj.id === +id))))}
    //     getOptionLabel={(option: CategoryModel) => (option ? String(option.name) : null)}
    //     getOptionValue={(option: CategoryModel) => (option ? String(option.id) : null)}
    // />

    return <div className={config.className}>
        {config.label && (
            <div className="flex gap-2 mb-2">
                <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
                {config.required && <div className="form-required"></div>}
            </div>
        )}

        <TreeSelect<CategoryModel>
            value={value}
            placeholder={config.placeholder}
            onChange={val => setValue(config.name, val, {shouldDirty: true, shouldValidate: true, shouldTouch: true})}
            allowClear={{clearIcon: <Icon icon="exit" className="font-bold text-2xl text-black" />}}
            style={{width: '100%'}}
            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
            fieldNames={{label: "name", value: "id", children: "children"}}
            switcherIcon={(props: TreeNodeProps) => (
                <div className="w-6 h-6 leading-6" style={{transform: props.expanded ? "rotate(180deg)" : "inherit"}}>
                    <Icon icon="expand_more" className="w-6 h-6 leading-6" />
                </div>
            )}
            removeIcon={() => <Icon icon="delete" />}
            treeData={buildTree<CategoryModel>(constants.INVENTORY_CATEGORIES)}
            popupClassName={'z-[10000]'}
            treeDefaultExpandAll
        />
    </div>
}
