import {Link} from "react-router-dom"
import Icon from "src/components/shared/components/material-icon"
import {ImageField} from "src/components/shared/image-loader/image"
import {OrderRequestInventoryError} from "src/pages/orders/details/context/order-context.model"
import {useTranslation} from 'react-i18next'

export function InventoryError({inventoryError}: {inventoryError: OrderRequestInventoryError}) {
    const {t} = useTranslation()
    return <div className="d-flex gap-2 flex-column">
        <div className="font-16 fw-500 text-align-center mb-2">{inventoryError.error}</div>

        {inventoryError.inventories.map((inventory) => (
            <div className="card-bio p-2 md:p-3 d-flex gap-2 justify-content-between" key={inventory.object_id}>

                <div className="flex gap-2 items-center">
                    <ImageField
                        draggable={false}
                        src={inventory.image}
                        className="bg-bg-light object-contain w-12 h-12 rounded-lg"
                    />
                    <div className="flex gap-2 flex-col">
                        <span className="text-[14px] font-medium">{inventory.name}</span>
                        <span className="text-gray-500">{t('orders.inventory.unique_id')} {inventory.unique_id}</span>
                    </div>
                </div>

                <Link to={`/orders/${inventory.request_id}/all`} className="btn btn-card p-2 md:p-3 flex gap-2 text-base font-medium">
                    <span>{t('orders.type.rent')} {inventory.request_unique_id || `#${inventory.request_id}`}</span>
                    <Icon icon="chevron_right" />
                </Link>

            </div>
        ))}
    </div>
}