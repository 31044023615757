import axios from 'axios'
import {BehaviorSubject, Observable, from, of, shareReplay, switchMap} from "rxjs"
import {axiosResponce} from '../interceptor/axios-response'
import authService from "./auth.service"

export interface WorkshiftModel {
  id: number
  start_at: string
  end_at: string
  profile: string
  point: string
  point_address: string
  order_created_cnt: number
  order_created_sum: number
  order_reserved_cnt: number
  order_reserved_sum: number
  order_issued_cnt: number
  order_issued_sum: number
  order_received_cnt: number
  order_received_sum: number
  order_cancelled_cnt: number
  order_cancelled_sum: number
  payment_orders_cnt: number
  payment_cnt: number
  payment_sum: number

  payments?: Array<{type: number, name: string, count: number, amount: number}>
}

class WorkshiftService {
  refreshing: boolean = false

  getWorkshift$ = new BehaviorSubject<void>(null)
  getWorkshift = () => this.getWorkshift$.next()

  workshift$: Observable<WorkshiftModel> = authService.profile$.pipe(
    switchMap((profile) => profile
      ? this.getWorkshift$.pipe(
        switchMap(() => from(axios.get<WorkshiftModel>('/v1/crm/workshift/')).pipe(axiosResponce)),
        shareReplay(1)
      )
      : of(undefined)
    ),
    shareReplay(1)
  )

  public async start(): Promise<WorkshiftModel> {
    const res = await axios.get('/v1/crm/workshift/start/')
    this.getWorkshift()
    return res.data
  }
  public async end(): Promise<WorkshiftModel> {
    const res = await axios.get('/v1/crm/workshift/end/')
    this.getWorkshift()
    return res.data
  }
}

export default new WorkshiftService()
