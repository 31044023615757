import gps from 'src/assets/images/integrations/gps_1.png'
import {useContext} from "react"
import {ConfigContext} from "src"
import {useTranslation} from 'react-i18next'
import HeaderContent from "src/components/header/header-content"
import {Link} from "react-router-dom"
import {IntegrationLandingWrapper} from "../landing"
import Icon from "src/components/shared/components/material-icon"
import {Image} from 'antd'

export function GPSIntegrationLanding() {
    const {integrationMap} = useContext(ConfigContext)
    const {t} = useTranslation()
    const integration = integrationMap["gps"]

    return integration && <>
        <HeaderContent>
            <div className="flex gap-2 items-center header-content">
                <Link to="/integrations" className="text-2xl font-semibold link">{t('integration.header')}</Link>
                <Icon icon="chevron_right" />
                <div className="text-2xl font-semibold">{integration.name}</div>
            </div>
        </HeaderContent>

        <IntegrationLandingWrapper integration={integration}>
            <div className="flex flex-row gap-3 overflow-x-scroll mb-3">
                <Image.PreviewGroup>
                    <Image src={gps} className="h-64 object-contain rounded-lg shadow border border-gray-100 bg-white" draggable={false} />
                </Image.PreviewGroup>
            </div>

            <div className="flex flex-col gap-2">
                <p className="text-base">Интеграция с gps. Контроль местоположения и маршрутов ваших продуктов</p>
                <p className="text-base">Мы реализовали интеграцию с GPS, позволяющую вам легко отслеживать местоположение ваших продуктов и контролировать их перемещения в режиме реального времени</p>

                <div className="flex flex-col gap-2">
                    <h3 className="text-[18px] font-semibold mb-0">Как работает интеграция:</h3>
                    <ol className="flex flex-col gap-1 list-decimal text-base">
                        <li className="ml-4"><strong>Выбор продукта с трекером:</strong> Вы можете выбрать любой продукт, к которому подключен GPS-трекер, и привязать его к системе для отслеживания</li>
                        <li className="ml-4"><strong>Отображение на карте:</strong> Местоположение выбранного продукта будет отображаться на интерактивной карте прямо в вашей CRM. Вы сможете в любой момент увидеть его текущее местоположение</li>
                        <li className="ml-4"><strong>История маршрутов:</strong> Система сохраняет данные о передвижениях продукта, позволяя вам просматривать историю маршрутов, включая расстояние, среднюю скорость и продолжительность каждой поездки</li>
                        <li className="ml-4"><strong>Уведомления:</strong> Настройте уведомления, чтобы получать оповещения в случае отклонений от запланированного маршрута или при достижении определенных контрольных точек</li>
                    </ol>
                </div>

                <div className="flex flex-col gap-2">
                    <h3 className="text-[18px] font-semibold mb-0">Преимущества интеграции:</h3>
                    <ol className="flex flex-col gap-1 list-disc text-base">
                        <li className="ml-4"><strong>Полный контроль:</strong> Следите за продуктами, находящимися в аренде или доставке, в режиме реального времени</li>
                        <li className="ml-4"><strong>Повышенная безопасность:</strong> Мгновенно узнавайте о любых изменениях в маршруте или местоположении</li>
                        <li className="ml-4"><strong>Удобство использования:</strong> Отслеживайте несколько продуктов одновременно, прямо из интерфейса вашей CRM</li>
                    </ol>
                </div>
            </div>
            {/* 
            Интеграция с gps. Контроль местоположения и маршрутов ваших продуктов

Мы реализовали интеграцию с GPS, позволяющую вам легко отслеживать местоположение ваших продуктов и контролировать их перемещения в режиме реального времени

Как работает интеграция:

1. Выбор продукта с трекером: Вы можете выбрать любой продукт, к которому подключен GPS-трекер, и привязать его к системе для отслеживания

2. Отображение на карте: Местоположение выбранного продукта будет отображаться на интерактивной карте прямо в вашей CRM. Вы сможете в любой момент увидеть его текущее местоположение

3. История маршрутов: Система сохраняет данные о передвижениях продукта, позволяя вам просматривать историю маршрутов, включая расстояние, среднюю скорость и продолжительность каждой поездки

4. Уведомления: Настройте уведомления, чтобы получать оповещения в случае отклонений от запланированного маршрута или при достижении определенных контрольных точек

Преимущества интеграции:

• Полный контроль: Следите за продуктами, находящимися в аренде или доставке, в режиме реального времени

• Повышенная безопасность: Мгновенно узнавайте о любых изменениях в маршруте или местоположении

• Удобство использования: Отслеживайте несколько продуктов одновременно, прямо из интерфейса вашей CRM */}
        </IntegrationLandingWrapper>
    </>
}