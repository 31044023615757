import {CSSProperties} from "react"
import {GroupBase, MenuProps, OptionProps, components} from 'react-select'
import Icon from "src/components/shared/components/material-icon"

const btnStyle: CSSProperties = {
    padding: "16px 16px",
    backgroundColor: 'var(--color-white)',
    borderTop: "1px solid var(--color-gray-100)",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
}

interface CustomOptionProps extends OptionProps<any, boolean, GroupBase<any>> {
    selectedIconStyles?: React.CSSProperties;
    iconPlaceholderNeed?:boolean
}

export function SelectOption(props: CustomOptionProps) {
    return (
        <components.Option {...props}>
            <span className="flex items-center justify-between gap-2" style={{minHeight: 20}}>
                {props.children}
                {props.isSelected ? <Icon icon="radio_button_checked" className="color-white" style={{...props.selectedIconStyles}}/> : props.iconPlaceholderNeed && <div className={'w-5 h-5 rounded-full border border-[#DADCE0]'}/>}
            </span>
        </components.Option>
    )
}

export function SelectMenu(props: MenuProps<any, boolean, GroupBase<any>> & {onModal: () => void}) {
    return (
        <components.Menu {...props}>
            {props.children}
            <span className="flex items-center justify-center w-full cursor-pointer" style={btnStyle} onClick={props.onModal}>
                <Icon icon="add" className="text-xl color-primary font-bold" />
            </span>
        </components.Menu >
    )
}
