import moment from "moment"

interface ComparisonModel {
  id: number
  set: number
  set_group: string
  inventory: number
  tarif: number
  tarif_price: number
  tarif_duration: string
  discount: number
  additional_discount: number
  start_at: string
  end_at: string
}

export function detectChanges(a: ComparisonModel, b: ComparisonModel) {
  return a.id === b.id &&
    a.set === b.set &&
    a.set_group === b.set_group &&
    a.inventory === b.inventory &&
    a.tarif === b.tarif &&
    a.tarif_price === b.tarif_price &&
    a.tarif_duration === b.tarif_duration &&
    a.discount === b.discount &&
    a.additional_discount === b.additional_discount &&
    moment(a.start_at).format('YYYY-MM-DD HH:mm') === moment(b.start_at).format('YYYY-MM-DD HH:mm') &&
    moment(a.end_at).format('YYYY-MM-DD HH:mm') === moment(b.end_at).format('YYYY-MM-DD HH:mm')
}