
import {TableProps} from 'antd/es/table'
import {Popover, Table} from "antd"
import React, {MouseEvent, useContext} from "react"
import {ColumnsType, SorterResult} from "antd/es/table/interface"
import {useFormContext} from "react-hook-form"
import Icon from "../components/material-icon"
import {ConfigContext} from "src"

export interface _TableProps extends Omit<TableProps<any>, 'scroll'> {
    option?: {
        children: JSX.Element,
        icon: string
    },
    onEdit?: (data: any, index: number) => void
    onDelete?: (data: any, index: number) => void
    onNavigate?: (data: any, index: number) => void
    onAdd?: (data: any, index: number) => (e: MouseEvent<HTMLButtonElement>) => void
    onHistory?: (data: any, index: number) => (e: MouseEvent<HTMLButtonElement>) => void
}

const _Table = React.forwardRef<any, _TableProps>((props, ref) => {
    const form = useFormContext()
    const {isMobile} = useContext(ConfigContext)
    const {reset, watch} = form || {reset: (...args) => { }, watch: (...args) => { }}
    const values = watch()
    const onChange = (_pagination: any, _filters: any, sorter: SorterResult<any>) => {
        const sort = sorter.order === 'ascend' ? '' : '-'
        reset({...values, ordering: sorter.order ? `${sort}${sorter.field}` : null})
    }

    const option = props.option

    const columns: ColumnsType = [
        ...props.columns,
        {
            hidden: !!!option,
            width: 48,
            key: 'option',
            align: 'center',
            fixed: 'right',
            className: 'border-l border-gray-100 w-16 cursor-pointer p-0 hover:bg-gray-100',
            onHeaderCell: () => ({style: {borderLeft: 'none'}}),
            render: () => (
                <Popover
                    overlayInnerStyle={{padding: 0}}
                    trigger="hover"
                    placement="leftBottom"
                    content={option.children}
                >
                    <div className="w-100 h-100">
                        <Icon icon={option.icon || 'more_vert'} className="h-full p-3" />
                    </div>
                </Popover>
            )
        },
        {
            width: 48,
            hidden: !!!props.onEdit,
            key: 'edit',
            align: 'center',
            fixed: 'right',
            className: 'border-l border-gray-100 w-16 cursor-pointer p-0 hover:bg-gray-100',
            onHeaderCell: () => ({style: {borderLeft: 'none'}}),
            onCell: (data, index) => ({
                style: {borderLeft: '1px solid var(--color-gray-100)'},
                onClick: () => props.onEdit(data, index)
            }),
            render: () => <Icon icon='edit' className="h-full p-3" />
        },
        {
            width: 48,
            hidden: !!!props.onDelete,
            key: 'delete',
            align: 'center',
            className: 'border-l border-gray-100 bg-alert bg-opacity-5 w-16 cursor-pointer p-0 hover:bg-opacity-20',
            onHeaderCell: () => ({style: {borderLeft: 'none'}}),
            onCell: (data, index) => ({
                style: {borderLeft: '1px solid var(--color-gray-100)'},
                onClick: () => props.onDelete(data, index)
            }),
            render: () => <Icon icon='delete' className="text-alert h-full p-3" />
        },
        {
            width: 48,
            hidden: !!!props.onNavigate,
            key: 'navigate',
            align: 'center',
            fixed: 'right',
            className: 'border-l border-gray-100 w-16 cursor-pointer p-0 hover:bg-gray-100',
            onHeaderCell: () => ({style: {borderLeft: 'none'}}),
            onCell: (data, index) => ({
                style: {borderLeft: '1px solid var(--color-gray-100)'},
                onClick: () => props.onNavigate(data, index)
            }),
            render: () => <Icon icon='chevron_right' className="h-full p-3" />
        },
        {
            width: 48,
            hidden: !!!props.onAdd,
            key: 'add',
            align: 'center',
            fixed: 'right',
            className: 'border-l border-gray-100 w-16 cursor-pointer p-0 hover:bg-gray-100',
            onHeaderCell: () => ({style: {borderLeft: 'none'}}),
            onCell: (data, index) => ({
                style: {borderLeft: '1px solid var(--color-gray-100)'},
                onClick: (e) => props.onAdd(data, index)(e)
            }),
            render: () => <Icon icon='add' className="h-full p-3" />
        },
        {
            width: 48,
            hidden: !!!props.onHistory,
            key: 'history',
            align: 'center',
            fixed: 'right',
            className: 'border-l border-gray-100 w-16 cursor-pointer p-0 hover:bg-gray-100',
            onHeaderCell: () => ({style: {borderLeft: 'none'}}),
            onCell: (data, index) => ({
                style: {borderLeft: '1px solid var(--color-gray-100)'},
                onClick: (e) => props.onHistory(data, index)(e)
            }),
            render: () => <Icon icon='history' className="h-full p-3" />
        }
    ]

    const max = columns.reduce((p, c) => (+c.width || 0) + p, 0) || 1024

    return <Table
        bordered={false}
        pagination={false}
        {...props}
        columns={columns}
        size={isMobile ? 'small' : 'large'}
        scroll={{x: `max(${isMobile ? window.innerWidth - 2 : max}px, 100%)`}}
        onChange={form ? onChange : props.onChange}
        ref={ref}
    />
})

export default _Table
