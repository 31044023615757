import {Outlet, useNavigate} from 'react-router'
import {TabConfig, TabPanel, TabPanelConfig} from 'src/components/shared/tabs/tabs'
import {useCurrentRoute} from 'src/hooks/useCurrentRoute'
import HeaderContent from "src/components/header/header-content"
import {useContext, useEffect, useState} from "react"
import authService, {ProfileModel} from "src/services/auth.service"
import {useTranslation} from 'react-i18next'
import {ConfigContext} from "src"
import clsx from "clsx"
import {useLocation} from "react-router-dom"

export default function ReportComponent() {
  const navigate = useNavigate()
  const activeRoute = useCurrentRoute(3)
  const {t} = useTranslation()
  const [profile, setProfile] = useState<ProfileModel>()
  const hasPerm = (key: string) => profile.perms.some(perm => perm.includes(key) || perm.includes('metricsmodel')) || profile.is_superuser

  const {isMobile} = useContext(ConfigContext)

  function TabRender({route, label, disabled}: {route: string, label: string, disabled: boolean}) {
    const {isMobile} = useContext(ConfigContext)
    const location = useLocation()
    const active = location.pathname.includes(route)
    return isMobile ? (
      <span className={`min-h-10 flex items-center justify-center gap-2 rounded-[32px] p-2 px-3 cursor-pointer ${active ? 'bg-test-black' : 'bg-gray-test-70'} ${disabled && '!bg-gray-400'}`}>
        <span className={clsx('font-medium text-[13px] text-nowrap', active ? 'text-white' : 'text-test-black')}>{label}</span>
      </span>
    ) : (
      <span className={clsx('tab-nav-link text-nowrap font-medium text-[13px]', {active, 'bg-[#f8f9fd]': disabled})}>{label}</span>
    )
  }

  const tabsConfig: TabConfig = profile && {
    items: [
      {key: 'sales', label: () => <TabRender route={'sales'} label={t('metrics.pages.sales')} disabled={!hasPerm('metricsfinance')} />},
      {key: 'clients', label: () => <TabRender route={'clients'} label={t('metrics.pages.clients')} disabled={!hasPerm('metricsclient')} />},
      {key: 'managers', label: () => <TabRender route={'managers'} label={t('metrics.pages.managers')} disabled={!hasPerm('metricsmanager')} />},
      {key: 'services', label: () => <TabRender route={'services'} label={t('metrics.pages.services')} disabled={!hasPerm('metricsservice')} />},
      {key: 'sublease', label: () => <TabRender route={'sublease'} label={t('metrics.pages.sublease')} disabled={!hasPerm('metricssublease')} />},
      {key: 'inventory', label: () => <TabRender route={'inventory'} label={t('metrics.pages.inventory')} disabled={!hasPerm('metricsinventory')} />},
      {key: 'inventorization', label: () => <TabRender route={'inventorization'} label={t('metrics.pages.inventorization')} disabled={true} />, disabled: true},
      {key: 'bonuses', label: () => <TabRender route={'bonuses'} label={t('metrics.pages.bonuses')} disabled={!hasPerm('metricsbonus')} />},
      // {key: 'website', label: t('metrics.pages.website')},
    ],
    onClick: (config: TabPanelConfig) => !config.disabled && navigate(`/reports/${config.key}`, {relative: 'path'}),
    activeTab: activeRoute,
    style: {padding: '0 16px', paddingTop: 8},
    isMobile: isMobile
  }

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    return () => sub.unsubscribe()
  }, [])

  return <>
    {profile && (
      <HeaderContent>
        <div className="flex pt-[18.5px] mx-4 font-semibold text-[22px] text-test-black h-16 border-b border-gray-100">
          {t('aside.menu.metrics')}
        </div>
        <TabPanel {...tabsConfig} />
      </HeaderContent>
    )}
    <Outlet />
  </>
}
