import {ApiAbstract} from "../abstract/api.abstract";
import { ProductResult} from "../models/manager/website";
import axios from "axios";
import {ListModel} from "../models/common";

export class WidgetService extends ApiAbstract{
    constructor() {
        super('v1/shared')
    }

    public async list(params?: any): Promise<ListModel<ProductResult>> {
        const response = await axios.get(this.getUrl('products'), {params})
        return response.data
    }

}

const widgetService = new WidgetService();
export default widgetService;