import {ProfileModel} from "src/services/auth.service"
import {OrderRequestInventoryModel} from "./order-request-inventory"
import {ClientModel} from "../clients/client.model"
import {InventoryGroupModel} from "../inventory/inventory-group.model"

export enum DeliveryType {
  DELIVERY = 0,
  PICK_UP = 1
}

export enum DeliveryStatus {
  PENDING = 0,
  IN_PROGRESS = 1,
  DONE = 2
}

export interface OrderRequestDeliveryCountModel {
  pending: number
  in_progress: number
  done: number
}

export interface DeliveryPlaceModel {
  latitude: number
  longitude: number
  name: string
}

export interface OrderRequestDeliveryModel {
  id: number
  request: number
  delivery_place: DeliveryPlaceModel
  issue_place: DeliveryPlaceModel
  delivered_at: string
  issued_at: string
  pickup_date: string
  type: DeliveryType
  status: DeliveryStatus
  comment: string
  price: number
  price_discount: number
  extra: any
  worker: number
  worker_detail: ProfileModel
}

export interface OrderRequestDeliveryDetailModel extends OrderRequestDeliveryModel {
  client: ClientModel
  manager: ProfileModel
  groups: InventoryGroupModel[]
  inventories: OrderRequestInventoryModel[]
}