import {Observable, map} from 'rxjs'
import settingService from 'src/services/settings.service'
import {isNil} from './isNil'
import {Async} from 'src/abstract/async-pipe'


export function priceBeautify(price: number, fixed = 2): string {
  return (!isNil(price) && !isNaN(price)) ? Number(price).toFixed(fixed).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
}

export function toPrice(price: number, prefix: string = '', suffix: string = ''): JSX.Element {
  const observable: Observable<string> = settingService.config$.pipe(
    map(config => {
      if (isNil(price)) return `${prefix}${config['currency']} 0${suffix}`
      return `${prefix}${config['currency']} ${(Math.round(Number(price) * 100) / 100)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${suffix}`
    }),
  )
  return <Async observable={observable} />
}
