import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import type {ColumnsType} from "antd/es/table"
import {FormProvider, useForm} from "react-hook-form"
import {ListParams} from "../../models/common"
import {useDebounce} from "../../hooks/useDebounce"
import {ConfigContext} from 'src/index'
import {useCurrentRoute} from "../../hooks/useCurrentRoute"
import Table from "../../components/shared/antd-custom/table"
import {hexToRgbA} from "../../utils/color/hex-to-rgba"
import maintenanceService from "../../services/maintenance/maintenance.service"
import {InventoryMaintenanceModel, MaintenanceModel} from "../../models/manager/maintenance/maintenance.model"
import {ImageField} from "../../components/shared/image-loader/image"
import usersService from "../../services/users"
import profileImage from "../../assets/images/profile-icon.svg"
import moment from "moment-timezone"

interface MaintenanceParams extends ListParams {
    search: string
    state: string
    rental_point: number
    group: number | string
    client__signed: boolean
    group__category: number
    category: string
}

export default function OneMaintenanceHistory(config: {inventory: InventoryMaintenanceModel}) {
    const [list, setList] = useState<MaintenanceModel[]>([])
    const {t} = useTranslation()
    const {inventory} = config
    const defaultConfig = {id: inventory.id}
    const form = useForm<MaintenanceParams>({defaultValues: defaultConfig})
    const {reset, setValue, watch} = form
    const values = watch()
    const activeRoute = useCurrentRoute()
    const searchDebounce = useDebounce(watch('search', ''), 500)
    const {constantsMap} = useContext(ConfigContext)
    const onLoad = async (params: any): Promise<void> =>
        maintenanceService.getForInventory(params.id).then(res => {
            setList(res)
        }).catch(e => e.response.status === 404 && setValue('page', 1))
    useEffect(() => reset({...values, page: 1, search: searchDebounce}), [searchDebounce])

    useEffect(() => {
        onLoad(values)
        const sub = watch((params, {name}) => {
            if (name !== 'search') onLoad(params)
        })
        return () => {
            sub.unsubscribe()
            setList([])
        }
    }, [activeRoute])


    const ResponsibleColumn = ({responsible}) => {
        const [responsibleData, setResponsibleData] = useState<any>()

        useEffect(() => {
            const fetchResponsibleName = async () => {
                if (responsible) {
                    try {
                        const res = await usersService.get(+responsible)
                        setResponsibleData(res)
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
            fetchResponsibleName()
        }, [responsible])

        return (
            <div className={' flex gap-3 items-center'}>
                <ImageField
                    src={responsibleData?.avatar}
                    fallbackimage={profileImage}
                    className="object-cover rounded-full w-8 h-8"
                />
                <div className={'flex justify-center items-center font-normal text-sm text-test-black'}>
                    {responsibleData ? (responsibleData.last_name + ' ' + responsibleData.first_name) : 'Загрузка...'}
                </div>
            </div>
        )
    }


    const columns: ColumnsType<MaintenanceModel> = [
        {
            width: 174,
            title: "Название ТО",
            dataIndex: 'name',
            key: 'name',
            render: name =>
                <div className={'flex justify-start items-center font-normal text-sm text-test-black'}>
                    {name}
                </div>,
            sorter: true,
        },
        {
            width: 174,
            title: "Дата и время",
            dataIndex: 'maintenance_date',
            key: 'maintenance_date',
            render: maintenance_date => {
                const formattedDate = moment(maintenance_date).format('DD-MM-YYYY, HH:mm')
                return <div className={'flex justify-start items-center font-normal text-sm text-test-black'}>
                    {formattedDate}
                </div>
            }
            ,
            sorter: true,
        }, {
            width: 174,
            title: "Тип",
            dataIndex: 'type',
            key: 'type',
            render: (_, {interval, notify_time, }) => <div className={'flex justify-start items-center font-normal text-sm text-test-black'}>
                {interval !== '00:00:00' ? "Периодическое ТО" : notify_time !== '00:00:00' ? "Запланированное разовое ТО" : "Разовое ТО"}
            </div>,
            sorter: true,
        }, {
            width: 174,
            title: "Состояние",
            dataIndex: 'state',
            key: 'state',
            render: state => state ? (
                <span
                    style={{
                        backgroundColor: hexToRgbA(constantsMap.INVENTORY_STATE_STATUS[+state].color, 0.2),
                        color: constantsMap.INVENTORY_STATE_STATUS[+state].color,
                    }}
                    className={'flex justify-center items-center h-8 w-full rounded-lg p-2 text-[13px] font-medium'}
                >
                    {constantsMap.INVENTORY_STATE_STATUS[+state].name}
                </span>
            ) : (
                <span
                    style={{backgroundColor: '#EDEEF7', color: '#8997A1'}}
                    className={'flex justify-center items-center h-8 w-full rounded-lg p-2 text-[13px] font-medium'}
                >
                    {t('maintenance.unchecked')}
                </span>
            ),
            sorter: true,
        }, {
            width: 174,
            title: "Ответственный",
            dataIndex: 'responsible',
            key: 'responsible',
            render: (responsible) => <ResponsibleColumn responsible={responsible} />,
            sorter: true,
        }, {
            width: 174,
            title: "Описание",
            dataIndex: 'description',
            key: 'description',
            render: description => <div className={'flex justify-start items-center font-normal text-sm text-test-black'}>
                {description}
            </div>,
            sorter: true,
        }
    ]

    return (
        <div className={'min-w-[1200px]'}>
            <FormProvider {...form}>
                <div className={'flex flex-col gap-8'}>
                    <span className={'font-semibold text-2xl text-test-black'}>История ТО ({inventory.unique_id})</span>
                    <Table

                        dataSource={list}
                        columns={columns}
                    />
                </div>
            </FormProvider>
        </div>

    )
};

