import React from 'react'
import Icon from "src/components/shared/components/material-icon"
import clsx from "clsx"
import {Link} from 'react-router-dom'
import {useCurrentRoute} from 'src/hooks/useCurrentRoute'
import {Tooltip} from "antd"

type LinkWrapperProps = {
    route?: string
    icon: string
    text: string
    collapse?: boolean
    subRoutes?: {route: string; icon: string; text: string}[]
    isSection?: boolean
    onClick?: () => void
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({route, icon, text, subRoutes, isSection, collapse , onClick}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const currentRoute = useCurrentRoute(2)
    const toggleSubMenu = () => setIsOpen(p => !p)

    return (
        <Tooltip placement="right" title={text} open={!collapse ? false : undefined}>
            {isSection && (
                <Link to={`/${route}`} onClick={toggleSubMenu} className={clsx('menu-item mb-2', {'': isSection, 'menu-item-activeSub': isOpen})}>
                    <Icon className="menu-item-icon" icon={icon} />
                    <div className="menu-item-name">{text}</div>

                    {isSection && <Icon className={`'menu-item-arrow'  ${isOpen ? 'rotate-180' : ''}`} icon={'keyboard_arrow_down'} />}
                </Link>
            )}

            {isSection && isOpen && subRoutes &&
                subRoutes.map((sub, index) => (
                    <Link
                        key={index}
                        to={`/${sub.route}`}
                        className={clsx('menu-item mb-2 ml-[10px]', currentRoute.includes(sub.route) && 'active')}
                    >
                        <Icon className="menu-item-icon" icon={sub.icon} />
                        <div className="menu-item-name">{sub.text}</div>
                    </Link>
                )
                )}

            {!isSection && route && (
                <Link to={`/${route}`} className={clsx('menu-item mb-2', currentRoute.includes(route) && 'active')}>
                    <Icon className="menu-item-icon" icon={icon} />
                    <div className="menu-item-name">{text}</div>
                </Link>
            )}
            {!isSection && !route && (
                <div onClick={onClick}
                     className={clsx('menu-item mb-2', isSection && isOpen && 'menu-item-activeSub')}>
                    <Icon className="menu-item-icon" icon={icon}/>
                    <div className="menu-item-name">{text}</div>
                </div>
            )}
        </Tooltip>
    )
}

export default LinkWrapper