import {ColumnsType} from 'antd/es/table'
import {useContext, useEffect, useState} from 'react'
import {FormProvider, useFormContext} from 'react-hook-form'
import {ImageField} from "src/components/shared/image-loader/image"
import Table from "src/components/shared/antd-custom/table"
import PaginationFormComponent from 'src/components/shared/pagination/pagination-form'
import useQueryParams from 'src/hooks/useQuertParams'
import {EMPTY_LIST, ListModel} from 'src/models/common'
import {InventoryModel} from 'src/models/manager/inventory/inventory.model'
import {hexToRgbA} from 'src/utils/color/hex-to-rgba'
import objectFilter from 'src/utils/object.filter'
import inventoriesService from 'src/services/inventory/inventory.service'
import {TableRowSelection} from "antd/es/table/interface"
import {colorPaletes} from "src/components/shared/constants"
import {isNil} from "src/utils/isNil"
import {useTranslation} from 'react-i18next'
import {ConfigContext} from "src"
import {useDebounce} from "src/hooks/useDebounce"

export default function InventoryListComponent({
    title = undefined,
    additionalColumns = [],
    onRow = undefined,
    rowSelection = undefined,
    saveParams = false,
    onNavigate = undefined,
    onDelete = undefined
}: {
    title?: () => React.ReactNode
    additionalColumns?: ColumnsType<InventoryModel>
    onRow?: (data: InventoryModel, index?: number) => React.HTMLAttributes<any> & React.TdHTMLAttributes<any>
    rowSelection?: (data: InventoryModel[]) => TableRowSelection<InventoryModel>
    saveParams?: boolean,
    onNavigate?: (data: InventoryModel, index: number) => void
    onDelete?: (data: InventoryModel, index: number) => void
}) {
    const {setSearchParams} = useQueryParams()
    const {constantsMap, isMobile} = useContext(ConfigContext)
    const form = useFormContext<any>()
    const {watch, reset, setValue} = form
    const [list, setList] = useState<ListModel<InventoryModel>>(EMPTY_LIST)
    const values = watch()
    const {t} = useTranslation()

    const mobileColumns: ColumnsType<InventoryModel> = [
        {
            width: '100%',
            title: t('groups.inventories.name'),
            key: 'name',
            dataIndex: 'name',
            render: (name, inventory) => {
                const {image, unique_id, status, sublease_user, } = inventory
                return (
                    <div className="flex gap-2 items-center">
                        <ImageField src={image} style={{width: 48, height: 48, borderRadius: 8}} />
                        <div className="flex items-center gap-2 justify-between w-full">
                            <div className="flex flex-col gap-1 flex-1">
                                <span className="font-medium">{name}</span>
                                <span>{unique_id}</span>
                            </div>
                            <span
                                className="flex justify-center items-center h-8 w-max rounded-lg p-2 text-[13px] font-medium"
                                style={{
                                    backgroundColor: hexToRgbA(colorPaletes[status], 0.2),
                                    color: colorPaletes[status]
                                }}
                            >
                                {constantsMap.INVENTORY_STATUS[status]?.label}
                            </span>
                            {sublease_user && (
                                <div className="btn px-2 py-1 btn-color-primary btn-primary-10">{t('groups.inventories.sublease')}</div>
                            )}
                        </div>
                    </div>
                )
            },
            sorter: true,
        },
    ]

    const columns: ColumnsType<InventoryModel> = [
        {
            width: 80,
            title: '№',
            key: 'id',
            dataIndex: 'id',
            render: id => id,
        },
        {
            width: '100%',
            title: t('groups.inventories.name'),
            key: 'name',
            dataIndex: 'name',
            render: (name, inventory) => (
                <div className="flex gap-2 items-center">
                    <ImageField src={inventory.image} style={{width: 48, height: 48, borderRadius: 8}} />
                    <div className="flex items-center gap-2">
                        <span className="font-medium">{name}</span>
                        {inventory.sublease_user && <div className="btn px-2 py-1 btn-color-primary btn-primary-10">{t('groups.inventories.sublease')}</div>}
                    </div>
                </div>
            ),
            sorter: true,
        },
        {
            width: 120,
            title: t('groups.inventories.unique_id'),
            dataIndex: 'unique_id',
            key: 'unique_id',
            render: unique_id => unique_id,
            sorter: true,
        },
        {
            width: 120,
            title: t('groups.inventories.rental_point'),
            dataIndex: 'rental_point',
            key: 'rental_point',
            render: rental_point => constantsMap.RENTAL_POINTS[rental_point]?.name,
            sorter: true,
        },
        {
            width: 120,
            title: t('groups.inventories.status'),
            key: 'status',
            dataIndex: 'status',
            render: status => (
                <span
                    className="flex justify-center items-center h-8 w-full rounded-lg p-2 text-[13px] font-medium"
                    style={{backgroundColor: hexToRgbA(colorPaletes[status], 0.2), color: colorPaletes[status]}}
                >
                    {constantsMap.INVENTORY_STATUS[status]?.label}
                </span>
            ),
            sorter: true,
        }
    ]

    const listInventories = async (params: any) => inventoriesService
        .list(params).then(setList)
        .catch(e => e.response.status === 404 && setValue('page', 1))

    const searchDebounce = useDebounce(watch('search'), 500)
    useEffect(() => reset({...values, page: 1, search: searchDebounce}), [searchDebounce])

    useEffect(() => {
        listInventories(values)
        const sub = watch((params, {name}) => {
            if (name !== 'search') listInventories(params)
            const query = objectFilter(params)
            if (saveParams) setSearchParams(query)
        })

        return () => {
            sub.unsubscribe()
            setList(EMPTY_LIST)
        }
    }, [])

    return (
        <FormProvider {...form}>
            <Table
                showHeader={!isMobile}
                rowKey={obj => obj.id}
                title={title}
                rowSelection={!isNil(rowSelection) && rowSelection(list.results)}
                columns={isMobile ? mobileColumns : [...columns, ...additionalColumns]}
                onRow={onRow}
                onNavigate={onNavigate}
                onDelete={onDelete}
                dataSource={list.results}
            />

            <PaginationFormComponent count={list.count} />
        </FormProvider>
    )
}
