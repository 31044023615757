import i18n from "i18next";
export const MAINTENANCE_NOT_VERIFIED = 0
export const MAINTENANCE_GOOD = 1
export const MAINTENANCE_BAD = 2

export type MAINTENANCE_STATE =
    typeof MAINTENANCE_NOT_VERIFIED |
    typeof MAINTENANCE_GOOD |
    typeof MAINTENANCE_BAD

export const NOT_WASHED = 0
export const WASHED = 1

export type WASHED_STATE=
    typeof NOT_WASHED |
    typeof WASHED

type WashLabel = {
    value: WASHED_STATE,
    label: string
}

export const UNDEFINED=0
export const SUMMER_TIRES = 1
export const WINTER_TIRES = 2

export type TIRES_STATE=
    // typeof UNDEFINED |
    typeof SUMMER_TIRES |
    typeof WINTER_TIRES

type TireLabel = {
    value: TIRES_STATE,
    label: string
}

let washedLabels: WashLabel[] = [];
let tiresLabels: TireLabel[] = [];

i18n.on('loaded', () => {
    washedLabels = [
        { value: NOT_WASHED, label: i18n.t('maintenance.selects.washed.not_washed') },
        { value: WASHED, label: i18n.t('maintenance.selects.washed.washed') }
    ];

    tiresLabels = [
        // {value:UNDEFINED,label:"Не выбрано"},
        { value: SUMMER_TIRES, label: i18n.t('maintenance.selects.tires.summer') },
        { value: WINTER_TIRES, label: i18n.t('maintenance.selects.tires.winter') }
    ];

});

export { washedLabels, tiresLabels };
