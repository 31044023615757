import {useContext, useEffect} from "react"
import {firstValueFrom, map} from "rxjs"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {LabelModel} from "src/models/label"
import {CustomField, CustomFieldModel, defaultCustomField, optionlessFieldTypes} from "src/models/setting-config"
import Icon from "src/components/shared/components/material-icon"
import {FormProvider, useFieldArray, useForm} from "react-hook-form"
import {Popover} from "antd"
import {FormControlCheckbox} from "src/components/shared/inputs/form-control-checkbox"
import {useTranslation} from 'react-i18next'
import {FormControl} from "src/components/shared/inputs/form-control"
import {fields$} from "./edit"
import {CustomFieldChoice} from "./field-choice"
import settingService from "src/services/settings.service"
import keygen from "src/utils/keygen"
import modalService from "src/components/modal/global/modal.service"
import {ConfigContext} from "src"
import {StringMap, TOptions} from "i18next"

export function CustomFieldModal({type}: {type: keyof CustomFieldModel}) {
    const form = useForm<CustomField>({defaultValues: {name: keygen()}})
    const {t} = useTranslation()
    const {settings} = useContext(ConfigContext)
    const {watch, control, formState, setValue, handleSubmit} = form
    const values: CustomField = watch()

    const custom_fields = settings ? settings.custom_fields : defaultCustomField
    const optionsForm = useFieldArray({control, name: 'options'})
    const onOptionAdd = () => optionsForm.append("")

    const onSubmit = handleSubmit(async (field) => {
        const payload = custom_fields
        payload[type] = [...payload[type], field]
        await settingService.saveCustomFields(payload)
        modalService.closeModal()
    })

    useEffect(() => {
        if (optionlessFieldTypes.includes(values.type)) setValue(`options`, [])
    }, [])

    return <FormProvider {...form}>
        <h3 className="text-[21px] font-bold mb-3">{t('settings.custom-fields.new_custom_field')}</h3>
        <div className="flex flex-col gap-2 mb-3">
            <div className="flex w-full gap-3 items-end">
                <FormControl
                    rootclassname="col"
                    className="form-control"
                    placeholder={t('settings.custom-fields.placeholder.name')}
                    required={true}
                    params={{required: true}}
                    name={`label`}
                />
                <FormSelectAsync<LabelModel>
                    className="col"
                    placeholder={t('settings.custom-fields.placeholder.type')}
                    name={`type`}
                    params={{required: true}}
                    required={true}
                    listOptions={() => fields$}
                    getValue={(field: string) => firstValueFrom(fields$.pipe(map(list => list.find(obj => obj.id === field))))}
                    getOptionLabel={(option: LabelModel) => (option ? t(option.label) : null)}
                    getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
                    isSearchable={false}
                    isClearable={false}
                />

                <Popover
                    trigger="click"
                    placement="leftTop"
                    overlayInnerStyle={{padding: 0}}
                    content={<div style={{padding: 12, width: 320, maxHeight: 400, overflowY: 'scroll'}}>
                        <FormControlCheckbox
                            name={`required`}
                            className="form-check-input my-0"
                            rootclassname="mb-2"
                            label={t('settings.custom-fields.required')}
                        />
                        <FormControlCheckbox
                            name={`filter`}
                            className="form-check-input my-0"
                            rootclassname="mb-2"
                            label={t('settings.custom-fields.use_in_filter')}
                        />
                        <FormControlCheckbox
                            name={`table`}
                            className="form-check-input my-0"
                            label={t('settings.custom-fields.use_in_table')}
                        />
                        {optionsForm.fields.length !== 0 && (
                            <>
                                <div className="text-sm color-gray-400 my-2">{t('settings.custom-fields.options')}</div>
                                {optionsForm.fields.map((field, index) => (
                                    <div className="flex gap-1 mb-2" key={field.id}>
                                        <CustomFieldChoice
                                            type={values.type}
                                            params={{
                                                name: `options.${index}`,
                                                placeholder: t('settings.custom-fields.option'),
                                                params: {required: true},
                                                className: "form-control",
                                                rootclassname: "w-full"
                                            }}
                                        />
                                        <div className="btn btn-red btn-color-white" onClick={() => optionsForm.remove(index)}>
                                            <Icon icon="delete" />
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                        {!optionlessFieldTypes.includes(values.type) && <div onClick={onOptionAdd} className="btn btn-card mt-2">{t('settings.custom-fields.add_option')}</div>}
                    </div>}>
                    <span className="btn btn-card" style={{width: 48, height: 48}}>
                        <Icon icon="settings" />
                    </span>
                </Popover>
            </div>
        </div>
        <div className="flex justify-end">
            <button className="btn btn-primary btn-color-white" onClick={onSubmit} disabled={!formState.isValid}>{t('common.add')}</button>
        </div>
    </FormProvider>
}
