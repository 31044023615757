import {lazy} from 'react'
import {Navigate, RouteObject} from 'react-router-dom'
import CustomSuspense from "src/components/custom-suspense"
import {InventoryGroupInventoriesBulkCreate} from "src/pages/group/edit/inventories-bulk-create"
import SaleFormComponent from "src/pages/sale/edit/form"
import {SaleInventoryList} from "src/pages/sale/edit/inventories/inventories"
import SaleWrapperComponent from "src/pages/sale/wrapper"

const InventoryDetailComponent = lazy(() => import('src/pages/inventories/detail/inventory-detail'))
const InventoryGroupInventorizationComponent = lazy(() => import("src/pages/group/detail/inventory-group-inventorization"))
const InventoryInventorizationListComponent = lazy(() => import('src/pages/inventories/detail/inventory-inventorization'))
const InventoryRentListComponent = lazy(() => import('src/pages/inventories/detail/inventory-rents'))
const InventoryTarifListComponent = lazy(() => import('src/pages/inventories/detail/inventory-tarifs'))
const InventoryEditComponent = lazy(() => import('src/pages/inventories/edit/inventory-edit'))
const InventoryGroupDetailComponent = lazy(() => import("src/pages/group/detail/inventory-group"))
const InventoryGroupImagesComponent = lazy(() => import("src/pages/group/detail/inventory-group-images"))
const InventoryGroupInventoriesComponent = lazy(() => import("src/pages/group/detail/inventory-group-inventories"))
const InventoryGroupRentsComponent = lazy(() => import("src/pages/group/detail/inventory-group-rents"))
const InventoryGroupSalesComponent = lazy(() => import("src/pages/group/detail/inventory-group-sales"))
const InventoryGroupEditComponent = lazy(() => import("src/pages/group/edit"))
const InventoryGroupListComponent = lazy(() => import("src/pages/group/list"))
const InventoryGroupTarifsComponent = lazy(() => import("src/pages/group/detail/inventory-group-tarifs"))
const SaleListComponent = lazy(() => import("src/pages/sale/list"))
const SaleHistoryComponent = lazy(() => import("src/pages/sale/history"))
const SaleEditComponent = lazy(() => import("src/pages/sale/edit"))

export const inventoryRoutes: RouteObject[] = [
  {path: 'groups', element: <CustomSuspense><InventoryGroupListComponent /></CustomSuspense>},
  {path: 'groups/add', element: <CustomSuspense><InventoryGroupEditComponent /></CustomSuspense>},
  {path: 'groups/:id/edit', element: <CustomSuspense><InventoryGroupEditComponent /></CustomSuspense>},
  {
    path: 'groups/:id',
    element: <CustomSuspense><InventoryGroupDetailComponent /></CustomSuspense>,
    children: [
      {path: '', element: <Navigate to="inventories" replace={true} />},
      {path: 'inventories', element: <CustomSuspense><InventoryGroupInventoriesComponent /></CustomSuspense>},
      {path: 'images', element: <CustomSuspense><InventoryGroupImagesComponent /></CustomSuspense>},
      {path: 'inventorization', element: <CustomSuspense><InventoryGroupInventorizationComponent /></CustomSuspense>},
      {path: 'rents', element: <CustomSuspense><InventoryGroupRentsComponent /></CustomSuspense>},
      {path: 'sales', element: <CustomSuspense><InventoryGroupSalesComponent /></CustomSuspense>},
      {path: 'tarifs', element: <CustomSuspense><InventoryGroupTarifsComponent /></CustomSuspense>},
    ]
  },
  {
    path: 'sales', 
    element: <SaleWrapperComponent />,
    children: [
      {path: '', element: <CustomSuspense><SaleListComponent /></CustomSuspense>},
      {path: 'history', element: <CustomSuspense><SaleHistoryComponent /></CustomSuspense>},
    ]
  },
  
  {
    path: 'sales/add',
    element: <CustomSuspense><SaleEditComponent /></CustomSuspense>,
    children: [
      {path: '', element: <SaleInventoryList />},
      {path: 'form', element: <SaleFormComponent />},
      {path: '*', element: <Navigate to="" replace={true} />}
    ]
  },
  {
    path: 'sales/:id',
    element: <CustomSuspense><SaleEditComponent /></CustomSuspense>,
    children: [
      {path: '', element: <SaleInventoryList />},
      {path: 'form', element: <SaleFormComponent />},
      {path: '*', element: <Navigate to="" replace={true} />}
    ]
  },

  {path: 'groups/:id/inventories/add', element: <CustomSuspense><InventoryGroupInventoriesBulkCreate /></CustomSuspense>},
  {path: 'groups/:id/inventories/:inventoryId/edit', element: <CustomSuspense><InventoryEditComponent /></CustomSuspense>},
  {
    path: 'groups/:id/inventories/:inventoryId',
    element: <CustomSuspense><InventoryDetailComponent /></CustomSuspense>,
    children: [
      {path: '', element: <Navigate to="rents" replace={true} />},
      {path: 'rents', element: <CustomSuspense><InventoryRentListComponent /></CustomSuspense>},
      {path: 'tarifs', element: <CustomSuspense><InventoryTarifListComponent /></CustomSuspense>},
      {path: 'inventorization', element: <CustomSuspense><InventoryInventorizationListComponent /></CustomSuspense>},
    ],
  }
]
