export const getNestedObject = (object: Object, path) => {
    const pathArray = path.split('.')

    for (const propertyName of pathArray) {
        if (object.hasOwnProperty(propertyName)) {
            object = object[propertyName]
        } else {
            return {}
        }
    }
    
    return object
}