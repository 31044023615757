import {PropsWithChildren, useEffect, useState} from 'react'
import {Observable} from 'rxjs'

type AsyncProps = PropsWithChildren<{observable: Observable<any>}>

export function Async({observable}: AsyncProps): JSX.Element {
  const [value, setValue] = useState<JSX.Element>(undefined)

  useEffect(() => {
    const sub = observable.subscribe(setValue)
    return () => sub.unsubscribe()
  }, [observable])

  return value
}
