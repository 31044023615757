export interface MessageModalConfig {
  title?: string
  message: string
}

export const MessageModal = (config: MessageModalConfig) => {
  return (
    <div className="flex gap-2 flex-col">
      {config.title && (
        <div className="flex mb-4 font-semibold text-lg justify-center">
          {config.title}
        </div>
      )}
      {config.message && (
        <div className="flex justify-center" style={{textAlign: 'center'}}>
          <span dangerouslySetInnerHTML={{__html: config.message}}></span>
        </div>
      )}
    </div>
  )
}
