import {RentalPointModel} from "src/models/manager/constants"

export class RentalPointService {
    private _point: RentalPointModel
    private _filterPoint: number

    get point(): RentalPointModel {
        return this._point
    }

    get filterPoint(): number {
        return this._filterPoint
    }

    setPoint(point: RentalPointModel): void {
        if (!point) return
        this._point = point
    }

    setFilterPoint(point: RentalPointModel): void {
        if (!point) return
        this._filterPoint = point.id
    }
}

const rentalPointService = new RentalPointService()
export default rentalPointService
