import axios from 'axios'
import {ApiAbstract} from '../../abstract/api.abstract'
import {InventorySetPriceModel} from '../../models/manager/inventory/inventory-set.model'

export class ManageInventorySetPriceService extends ApiAbstract {
  constructor() {
    super('v1/crm/inventories/sets')
  }
  public async delete(id: number, priceId: number): Promise<InventorySetPriceModel> {
    const response = await axios.delete(this.getUrl(`${id}/prices/${priceId}`))
    return response.data
  }
}
const inventorySetPriceService = new ManageInventorySetPriceService()
export default inventorySetPriceService
