import React, { useEffect, useState} from "react";
import Table from "src/components/shared/antd-custom/table";
import { ImageField } from "src/components/shared/image-loader/image";
import {FormProvider, useForm} from "react-hook-form";
import PaginationFormComponent from "src/components/shared/pagination/pagination-form";
import {EMPTY_LIST, ListModel} from "src/models/common";
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model";
import {InventorySetModel} from "src/models/manager/inventory/inventory-set.model";
import inventoryGroupService from "src/services/inventory/inventory-group.service";
import {ManageInventorySetService} from "src/services/inventory/inventory-set.service";

interface IModalContentProps {
    isGroup: boolean
    getProduct: () => Promise<any>
}

const ModalContent = ({ isGroup, getProduct }: IModalContentProps) => {
    const form = useForm({defaultValues: {page: 1, pageSize: 10, shareable: true}})
    const { watch, setValue} = form
    const values = watch()
    const inventorySetService = new ManageInventorySetService()
    const [list, setList] = useState<ListModel<InventoryGroupModel | InventorySetModel>>(EMPTY_LIST)
    const modalService = isGroup ? inventoryGroupService : inventorySetService

    const getList = async (params = { page: values.page, pageSize: values.pageSize, shareable: true }) => {
        modalService.list(params)
            .then(setList)
            .catch(e => e.response?.status === 404 && setValue('page', 1))
    }

    const updateList = (product: any, add: boolean ) => {
        if(product.id === undefined) return
        const payload = { published: add }

        modalService.patch(product.id, payload)
            .then(() => getList({ page: values.page, pageSize: values.pageSize ,shareable: true }))
            .then(() => getProduct())
    };

    const columns = [
        {
            title: "Название",
            key: "image",
            render: (_, {name, image}) => <div className="flex gap-2 items-center">
                <ImageField className="w-16 h-16" src={image} />
                <div>{name}</div>
            </div>}
    ];

    useEffect(() => {
        getList()
    }, [])

    useEffect(() => {
        const subscription = watch((modal) => {
            getList({page: modal.page, pageSize: modal.pageSize, shareable: true})
        })
        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <FormProvider {...form}>
            <Table
                columns={columns}
                dataSource={list.results}
                rowKey="id"
                onChange={(pagination) => setValue('page', pagination.current)}
                onAdd={(data) => (e) => {
                    e.preventDefault();
                    updateList(data, true)
                }}
            />
            <PaginationFormComponent count={list.count}/>
        </FormProvider>
    )
};

export default ModalContent;