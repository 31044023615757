export function buildTree<T extends {id: number | string, parent: number | string}>(data: T[]): T[] {
    const map = new Map()
    const roots = []

    data.forEach(item => {
        map.set(item.id, {...item, children: []})
    })

    data.forEach(item => {
        const node = map.get(item.id)
        if (item.parent !== null && map.has(item.parent)) {
            map.get(item.parent).children.push(node)
        } else {
            roots.push(node)
        }
    })
    return roots
}