import {useFormContext} from "react-hook-form"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"
import {SaleFormModel, SaleInventoryModel} from "src/models/manager/order/order-sale.model"
import {toPrice} from "src/utils/price"


export default function SaleGroupSummaryComponent({group}: {group: InventoryGroupModel}) {
    const {watch} = useFormContext<SaleFormModel>()
    const inventories: SaleInventoryModel[] = Object.values(watch('_inventories')).filter((obj: SaleInventoryModel) => obj.inventory.group === group.id) as SaleInventoryModel[]
    const price = inventories.reduce((prev, curr) => prev + +curr.price, 0)

    return (
        <div className="flex gap-2 justify-between items-end color-gray-400">
            <span>{group.name} ({inventories.length})</span>
            <span className="sale-summary-divider"></span>
            <span className="font-medium">{toPrice(price)}</span>
        </div>
    )
}
