export const extractHttpErrorMessage = (data: any) => {
  if (!data || typeof data === 'string') {
    return data
  }
  if (Array.isArray(data)) {
    return extractHttpErrorMessage(data[0])
  } else {
    const objKeys = Object.keys(data)
    return extractHttpErrorMessage(data[objKeys[0]])
  }
}

export const SKIP_ERROR_HANDLING_PARAMS = {
  skip_error_handling: true,
}

export const SKIP_LOADER_SHOWING_PARAMS = {
  skip_loader_showing: true,
}
