import {lazy} from 'react'
import {Navigate, RouteObject} from 'react-router-dom'
import CustomSuspense from "src/components/custom-suspense"

const ClientProvider = lazy(() => import('src/pages/clients/detail/client-context'))
const ClientDetailComponent = lazy(() => import('src/pages/clients/detail/client-detail'))
const ClientDocumentListComponent = lazy(() => import('src/pages/clients/detail/client-document'))
const ClientFileListComponent = lazy(() => import('src/pages/clients/detail/client-files'))
const ClientInfoDetailComponent = lazy(() => import('src/pages/clients/detail/client-info'))
const ClientRentsDetailComponent = lazy(() => import('src/pages/clients/detail/client-rents'))
const ClientBonusListComponent = lazy(() => import('src/pages/clients/detail/client-bonuses'))
const ClientEditComponent = lazy(() => import('src/pages/clients/edit/clients-edit'))
const ClientComponent = lazy(() => import('src/pages/clients/list/clients'))

export const clientRoutes: RouteObject[] = [
  {path: 'clients', element: <CustomSuspense><ClientComponent /></CustomSuspense>},
  {path: 'clients/add', element: <CustomSuspense><ClientEditComponent /></CustomSuspense>},
  {path: 'clients/:clientId/edit', element: <CustomSuspense><ClientEditComponent /></CustomSuspense>},
  {
    path: 'clients/:clientId',
    element: (
      <CustomSuspense>
        <ClientProvider>
          <ClientDetailComponent />
        </ClientProvider>
      </CustomSuspense>
    ),
    children: [
      {path: '', element: <Navigate to="info" relative="route" replace={true} />},
      {path: 'info', element: <CustomSuspense><ClientInfoDetailComponent /></CustomSuspense>},
      {path: 'files', element: <CustomSuspense><ClientFileListComponent /></CustomSuspense>},
      {path: 'documents', element: <CustomSuspense><ClientDocumentListComponent /></CustomSuspense>},
      {path: 'rents', element: <CustomSuspense><ClientRentsDetailComponent /></CustomSuspense>},
      {path: 'bonuses', element: <CustomSuspense><ClientBonusListComponent /></CustomSuspense>},
    ],
  },
]
