import axios from 'axios'
import {ApiAbstract} from '../abstract/api.abstract'
import {OrderRequestDeliveryCountModel, OrderRequestDeliveryDetailModel, OrderRequestDeliveryModel} from "src/models/manager/order/order-request-delivery.model"
import {ListModel} from "src/models/common"
import {Geocoding} from "src/models/manager/geocoding"

export class DeliveryService extends ApiAbstract {
    constructor() {
        super('v1/crm/requests')
    }

    async geocoding(params: any): Promise<Geocoding[]> {
        const response = await axios.get<Geocoding[]>('v1/crm/geocoding/', {params: {...params, skip_loader: true}})
        return response.data
    }

    async getCount(): Promise<OrderRequestDeliveryCountModel> {
        const response = await axios.get<OrderRequestDeliveryCountModel>(this.getUrl('deliveries/count'))
        return response.data
    }

    async list(params?: any): Promise<ListModel<OrderRequestDeliveryModel>> {
        const response = await axios.get<ListModel<OrderRequestDeliveryModel>>(this.getUrl('deliveries'), {params})
        return response.data
    }

    async get(orderId: number, id: number): Promise<OrderRequestDeliveryDetailModel> {
        const response = await axios.get<OrderRequestDeliveryDetailModel>(this.getUrl(`${orderId}/deliveries/${id}`))
        return response.data
    }

    async start(orderId: number, id: number): Promise<undefined> {
        const response = await axios.post<undefined>(this.getUrl(`${orderId}/deliveries/${id}/pickup`))
        return response.data
    }

    async finish(orderId: number, id: number): Promise<undefined> {
        const response = await axios.post<undefined>(this.getUrl(`${orderId}/deliveries/${id}/delivery`))
        return response.data
    }
}

const deliveryService = new DeliveryService()
export default deliveryService
