import axios from 'axios'
import {ApiAbstract} from 'src/abstract/api.abstract'
import {ContentTypeSections} from "src/pages/accounts/group/group-edit"

export interface PermissionModel {
  id: number
  codename: string
}

export interface PermissionGroupModel {
  id: number
  name: string
  permissions: number[]
}

export interface ContentTypeModel {
  id: number
  permissions: PermissionModel[]
  app_label: string
  name: string
  type: ContentTypeSections
  children?: ContentTypeModel[]
}

export class ManagePermissionService extends ApiAbstract {
  constructor() {
    super('v1/crm/permissions')
  }

  public async permissions(): Promise<ContentTypeModel[]> {
    const response = await axios.get(this.getUrl())
    return response.data
  }

  public async groups(params?: any): Promise<PermissionGroupModel[]> {
    const response = await axios.get(this.getUrl('groups'), {params})
    return response.data
  }
  public async postGroup(payload: any): Promise<PermissionGroupModel> {
    const response = await axios.post(this.getUrl('groups'), payload)
    return response.data
  }
  public async getGroup(id: number): Promise<PermissionGroupModel> {
    const response = await axios.get(this.getUrl(`groups/${id}`))
    return response.data
  }
  public async patchGroup(id: number, payload: any): Promise<PermissionGroupModel> {
    const response = await axios.patch(this.getUrl(`groups/${id}`), payload)
    return response.data
  }
  public async deleteGroup(id: number): Promise<any> {
    const response = await axios.delete(this.getUrl(`groups/${id}`))
    return response.data
  }
}

const permissionService = new ManagePermissionService()
export default permissionService
