import {useTranslation} from 'react-i18next'

export interface ArticleModel {
    id: number
    title: string
    body: string
    outer_url: string
    active: boolean
    extra: {
        body_kk: string
        body_ru: string
        title_kk: string
        title_ru: string
        image_kk: string | File
        image_ru: string | File
    }
}

export interface EventModel {
    id: number
    residents: EventResidentModel[]
    created_at: string
    updated_at: string
    slug: string
    title: string
    body: string
    location: string
    date: string
    image: string
    video: string
    location_link: string
    active: boolean
    user_limit: number
    user_registered: number
    extra: {
        title_ru: string
        title_kk: string
        body_ru: string
        body_kk: string
        location_kk: string
        location_ru: string
    }
}

export interface EventResidentModel {
    id: number
    created_at: string
    updated_at: string
    name: string
    order: number
    image: string
    event: number
}

export interface EventRequestModel {
    id: number
    created_at: string
    updated_at: string
    name: string
}

export interface ProductOrder {
    id: number
    name: string
    category: number
    type: "set" | "product" | "service"
    product: number
    order: number
}

export interface ProductResult {
    id: number,
    name: string,
    unique_id: string,
    price: number,
    price_discount: number,
    images: string[],
    image: string,
    group_id: number | null,
    service_id: number | null,
    set_id: number | null,
    category: number,
    extra: {},
    amount: number,
}

export const ProductTypeName = () => {
    const {i18n, ready} = useTranslation()

    if (!ready) {
        return {}
    }

    return {
        "set": i18n.t('common.set'),
        "product": i18n.t('common.product'),
        "service": i18n.t('common.service'),
    }
}
