import "../header/styles.scss"
import "./styles.scss"

// function getWindowDimensions() {
//     const {innerWidth: width, innerHeight: height} = window
//     return {width, height}
// }

// function generateStyle(_element: HTMLElement) {
//     if (!_element) return {}
//     let el = _element
//     let offsetTop = 0
//     let offsetLeft = 0
//     while (el.offsetParent) {
//         offsetTop += el.offsetTop
//         offsetLeft += el.offsetLeft
//         el = el.offsetParent as HTMLElement
//     }
//     return {offsetTop: offsetTop - 8, offsetLeft: offsetLeft - 8}
// }

// function SpotlightSVG({spot, element}: {spot: Spot, element: HTMLElement}) {
//     const [size, setSize] = useState(getWindowDimensions())
//     const {offsetTop, offsetLeft} = generateStyle(element)
//     const [width, setWidth] = useState(element.clientWidth + 16)
//     const [height, setHeight] = useState(element.clientHeight + 16)

//     useEffect(() => {
//         const handleResize = () => setSize(getWindowDimensions())
//         window.addEventListener('resize', handleResize)

//         const observer = new ResizeObserver(entries => {
//             const e = entries[0]
//             setWidth(e.target.clientWidth + 16)
//             setHeight(e.target.clientHeight + 16)
//         })

//         observer.observe(element)

//         if (spot.type !== 'spotlight') element.classList.add("spotlight")

//         return () => {
//             window.removeEventListener('resize', handleResize)
//             observer.unobserve(element)
//             element.onclick = null
//             if (spot && spot.type !== 'spotlight') element.classList.remove("spotlight")
//         }
//     }, [spot, element])

//     return <>
//         {spot.component && createPortal(<div className="spotlight-portal" style={{left: spot.type === 'spotlight' ? offsetLeft : offsetLeft + 8, top: offsetTop + height, minWidth: width - 16}}>{spot.component}</div>, document.body, "spotlight-portal")}
//         <div className="spotlight-wrapper-main">
//             {spot.type === 'spotlight' ? (
//                 <svg width={size.width - 331} height={size.height} className="spotlight-svg">
//                     <defs>
//                         <mask id="mask__highlight">
//                             <rect x={0} y={0} width={size.width} height={size.height} fill="white"></rect>
//                             <rect x={offsetLeft} y={offsetTop} rx={8} width={width} height={height} fill="black"></rect>
//                         </mask>
//                     </defs>
//                     <rect x={0} y={0} width={size.width} height={size.height} fill="#000000ff" mask="url(#mask__highlight)"></rect>
//                     <rect className={spot.animate ? "spotlight-svg-pulse" : ""} x={offsetLeft} y={offsetTop} rx={8} width={width} height={height} fill="transparent"></rect>
//                 </svg>
//             ) : (
//                 <div className="spotlight-wrapper" style={{width: size.width - 331, height: size.height}} />
//             )}
//         </div>
//     </>
// }

export function Spotlight() {
    // const [element, setElement] = useState<HTMLElement>(undefined)
    // const [spot, setSpot] = useState<Spot>(undefined)

    // const location = useLocation()

    // useMemo(() => {
    //     if (!element || !spot) return

    //     const parent = element.offsetParent as HTMLElement
    //     const parentNode = element.parentNode as HTMLElement

    //     element.onclick = spot.onClick

    //     if (parent && parent.clientHeight < parent.scrollHeight) {
    //         parent.scrollTo({top: parent.offsetTop + element.offsetTop - 24, behavior: "smooth"})
    //     }

    //     if (parentNode && parentNode.clientHeight < parentNode.scrollHeight) {
    //         parentNode.scrollTo({top: parentNode.offsetTop + element.offsetTop - 24, behavior: "smooth"})
    //     }
    // }, [spot, element])

    // useEffect(() => {
    //     setElement(spot ? document.getElementById(spot.key) : undefined)
    //     const observer = new MutationObserver((mutationsList) => {
    //         mutationsList.forEach(mutation => {
    //             if (mutation.type === 'childList') setElement(spot ? document.getElementById(spot.key) : undefined)
    //         })
    //     })
    //     observer.observe(document.getElementById('root'), {subtree: true, childList: true, attributes: true})
    //     return () => observer.disconnect()
    // }, [location.pathname, spot])

    // useEffect(() => {
    //     const sub = spotlightService.spoted$.subscribe((sp) => {
    //         if (sp && sp.actionBefore) sp.actionBefore()
    //         setSpot(sp)
    //     })
    //     return () => sub.unsubscribe()
    // }, [])

    return <></>
    // return element && spot && <SpotlightSVG spot={spot} element={element} />
}