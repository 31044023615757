import {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Icon from "../shared/components/material-icon"
import {Link, useLocation} from "react-router-dom"
import clsx from "clsx"
import {ConfigContext} from "src"
import authService, {ProfileModel} from "src/services/auth.service"

export function MobileMenu() {
    const ref = useRef(null)
    const location = useLocation()
    const {t} = useTranslation()
    const {isMobile, mobileMenu} = useContext(ConfigContext)
    const [profile, setProfile] = useState<ProfileModel>()
    const has_perms = (key: string) => profile && (profile.perms.some(perm => perm.includes(key)) || profile.is_superuser)

    const menus = [
        {path: "/orders", icon: 'list_alt', label: t('aside.menu.orders'), active: has_perms('orderrequest')},
        {path: "/deliveries", icon: 'local_shipping', label: t('aside.menu.delivery'), active: has_perms('orderrequestdelivery')},
        {path: "/reports", icon: 'insert_chart', label: t('aside.menu.metrics'), active: has_perms('metrics')},
        {path: "/profile", icon: 'account_circle', label: t('profile.profile'), active: true}
    ]

    useEffect(() => {
        const sub = authService.profile$.subscribe(setProfile)
        return () => sub.unsubscribe()
    }, [])

    if (!isMobile) return <></>
    if (!mobileMenu) return <></>

    return (
        <div
            ref={ref}
            className="fixed flex items-center w-full px-2 shadow-menu bg-white max-h-[72px] bottom-0 left-0 right-0 z-[100]"
        >
            {menus.filter(menu => menu.active).map((menu) => (
                <Link
                    key={menu.path}
                    to={menu.path}
                    className={clsx(
                        'flex flex-col gap-[6px] justify-center items-center w-full pt-3 pb-2',
                        {"text-primary": location.pathname.startsWith(menu.path)}
                    )}
                >
                    <Icon icon={menu.icon} />
                    <span>{menu.label}</span>
                </Link>
            ))}
        </div>
    )
}
