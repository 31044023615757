import {FormProvider, useForm} from 'react-hook-form'
import modalService from "src/components/modal/global/modal.service"
import {EventModel} from "src/models/manager/website"
import {FormControl} from "src/components/shared/inputs/form-control"
import {ImageInputField} from "src/components/shared/components/image-field"
import {TabConfig, TabPanel, TabPanelConfig} from "src/components/shared/tabs/tabs"
import websiteService from "src/services/website.service"
import {useState} from "react"
import {FormControlCkeditor} from "src/components/shared/inputs/form-control-ckeditor"
import EventRequestList from "./requests"
import {FormControlDatetime} from "src/components/shared/inputs/form-control-datetime"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import {useTranslation} from "react-i18next"

function EventEditLanguageKk() {
    const {t} = useTranslation()
    return <>
        <FormControl
            name="extra.title_kk"
            rootclassname="col mb-3"
            className="form-control"
            params={{required: true}}
            required={true}
            label={t('website.articles.name_kk')}
            placeholder={t('website.articles.name_kk')}
        />
        <FormControl
            name="extra.location_kk"
            rootclassname="col mb-3"
            className="form-control"
            params={{required: true}}
            required={true}
            label={t('website.articles.location_name_kk')}
            placeholder={t('website.articles.location_name_kk')}
        />
        <FormControlCkeditor
            name="extra.body_kk"
            rootclassname="col mb-3"
            className="form-control"
            label={t('website.articles.description_kk')}
        />
    </>
}

function EventEditLanguageRu() {
    const {t} = useTranslation()
    return <>
        <FormControl
            name="extra.title_ru"
            rootclassname="col mb-3"
            className="form-control"
            params={{required: true}}
            required={true}
            label={t('website.articles.name_ru')}
            placeholder={t('website.articles.name_ru')}
        />
        <FormControl
            name="extra.location_ru"
            rootclassname="col mb-3"
            className="form-control"
            params={{required: true}}
            required={true}
            label={t('website.articles.location_name_ru')}
            placeholder={t('website.articles.location_name_ru')}
        />
        <FormControlCkeditor
            name="extra.body_ru"
            rootclassname="col mb-3"
            className="form-control"
            label={t('website.articles.description_ru')}
        />
    </>
}

export default function EventEdit({event, postSubmit}: {event?: EventModel, postSubmit: () => void}) {
    const [lang, setLang] = useState<'kk' | 'ru'>('ru')
    const {t} = useTranslation()
    const form = useForm<EventModel>({defaultValues: event ? event : {user_limit: 20}})
    const {handleSubmit, watch, formState} = form
    const values = watch()

    const onCancel = () => modalService.closeModal()

    const onDelete = () => {
        const onConfirm = () => websiteService.deleteEvent(event.id).then(() => {
            modalService.closeAllModal()
            postSubmit()
        })
        modalService.open({
            component: <ConfirmModal
                message={t('website.articles.delete_sure')}
                delete={true}
                confirm_text={t('common.delete')}
                onConfirm={onConfirm}
            />
        })
    }

    const savePhoto = async (eventId: number, image: string | File) => {
        if (!(image instanceof File)) return
        const formData = new FormData()
        formData.append('image', image)
        await websiteService.updateEvent(eventId, formData)
    }

    const onSubmit = handleSubmit(async payload => {
        const image = payload.image
        payload.title = payload.extra.title_ru
        delete payload.image
        if (event) {
            const _event = await websiteService.updateEvent(event.id, payload)
            await savePhoto(_event.id, image)
        } else {
            const _event = await websiteService.createEvent(payload)
            await savePhoto(_event.id, image)
        }
        modalService.closeAllModal()
        postSubmit()
    })

    const tabsConfig: TabConfig = {
        items: [
            {key: 'ru', label: t('website.articles.ru')},
            {key: 'kk', label: t('website.articles.kk')},
        ],
        onClick: (config: TabPanelConfig) => setLang(config.key as 'kk' | 'ru'),
        activeTab: lang,
        style: {marginBottom: 12}
    }

    return (
        <FormProvider {...form}>
            <div className="flex flex-col">
                <ImageInputField
                    name="image"
                    style={{objectFit: 'contain', backgroundColor: 'var(--color-bg-light)', width: '100%', maxHeight: 480, borderRadius: 12, marginBottom: 16}}
                    containerStyle={{width: '100%'}}
                />
                <FormControlDatetime
                    name="date"
                    rootclassname="col mb-3"
                    className="form-control"
                    label={t('website.events.date')}
                    params={{required: true}}
                    datetimeConfig={{
                        minuteStep: 1,
                        showTime: {format: 'HH:mm'},
                        allowClear: false,
                    }}
                />
                <FormControl
                    name="video"
                    rootclassname="col mb-3"
                    className="form-control"
                    label={t('website.events.video_link')}
                    placeholder="https://"
                />
                <FormControl
                    name="location_link"
                    rootclassname="col mb-3"
                    className="form-control"
                    label={t('website.events.location_link')}
                    placeholder="https://"
                />
                <FormControl
                    name="user_limit"
                    rootclassname="col mb-3"
                    type="number"
                    className="form-control"
                    label={t('website.events.slot_count')}
                    params={{required: true}}
                />

                <TabPanel {...tabsConfig} />

                {lang === 'kk' ? <EventEditLanguageKk /> : <EventEditLanguageRu />}

                <div className="flex items-center justify-end gap-2 mt-3">
                    {event && <button className="btn btn-red-10 btn-color-red font-medium gap-2" onClick={onDelete}>
                        {t('common.delete')}
                    </button>}
                    <button className="btn btn-gray-100 btn-color-black font-medium gap-2" onClick={onCancel}>
                        {t('common.canceling')}
                    </button>
                    <button
                        className="btn btn-primary btn-color-white font-medium gap-2"
                        disabled={!formState.isValid}
                        onClick={onSubmit}
                    >
                        <span>{values.id ? t('common.save') : t('common.create_and_save')}</span>
                    </button>
                </div>
            </div>
            {event && <EventRequestList id={event.id} />}
        </FormProvider>
    )
}
